import React, {useCallback, useEffect, useState} from 'react';
import dayjs from "dayjs";
import TelegramOrderBotOrderDataTable from "../../telegram-bot/orders/datatable/TelegramOrderBotOrderDataTable";
import {debounce} from "underscore";
import TelegramOrderBotService from "../../../../../../services/telegram-order-bot/telegramOrderBotService";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED,
    DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED
} from "../../../../../../app/eventbus/telegram-bot/telegramOrderBotEvents";

const ContractorTelegramOrders = ({contractorId}) => {
    const activeOrganization = useSelector(selectActiveOrganization);
    const [filter, setFilter] = useState({
        dateStart: null,
        dateEnd: null,
        contractorId: contractorId,
        isApproved: null,
        isSaleCreated: null,
        botId: null,
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [columnVisibility, setColumnVisibility] = useState({
        "selectColumn": true,
        "#": true,
        "user": true,
        "contractorName": true,
        "date": true,
        "contractorPhone": true,
        "total": true,
        "approved": true,
        "approved_at": true,
        "sale_info": true,
        "action": true,
    });
    const [orders, setOrders] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const onFilterChange = (filter) => {
        setFilter(filter);
        setPagination({...pagination, pageIndex: 0});
        load(filter, {...pagination, pageIndex: 0});
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        setFilter(filter);
        setPagination({...pagination, pageIndex: 0});
        load(filter, {...pagination, pageIndex: 0});
    }, 500), [pagination.pageSize]);
    const onPaginationChange = (updater) => {
        setPagination(updater);
        const nextState = updater(pagination);
        load(filter, nextState);
    };
    const onColumnVisibilityChange = (updater) => {
        setColumnVisibility(updater);
    };
    const load = async (filters, pagination) => {
        try {
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                if (filters.dateStart)
                    filterParams["date_start"] = dayjs(filters.dateStart).format("YYYY-MM-DD");

                if (filters.dateEnd)
                    filterParams["date_end"] = dayjs(filters.dateEnd).format("YYYY-MM-DD");

                if (filters.contractorId)
                    filterParams["contractor_id"] = filters.contractorId;

                if (filters.isApproved !== null)
                    filterParams["is_approved"] = filters.isApproved;

                if (filters.isSaleCreated !== null)
                    filterParams["is_sale_created"] = filters.isSaleCreated;

                if (filters.botId !== null)
                    filterParams["bot_id"] = filters.botId;
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize
            }

            setLoading(true);
            const getTelegramOrderBotOrdersPromise = TelegramOrderBotService.getTelegramOrderBotOrders({...filterParams, ...paginationParams});
            const getTelegramOrderBotOrdersCountPromise = TelegramOrderBotService.getTelegramOrderBotOrdersCount({...filterParams});
            const {data: orders} = await getTelegramOrderBotOrdersPromise;
            const {data: count} = await getTelegramOrderBotOrdersCountPromise;
            setOrders(orders);
            setCount(count);
            setLoading(false);
        } catch (error) {
            setOrders([]);
            setCount(0);
            setLoading(false);
        }
    };

    useEffect(() => {
        load(filter, pagination);
    }, [activeOrganization]);

    useEffect(() => {
        const onDeleteTelegramOrderBotOrderSucceedHandler = EventBus.on(DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED, () => {
            load(filter, pagination);
        });
        const onBulkDeleteTelegramOrderBotOrdersFinishedHandler = EventBus.on(BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED, () => {
            load(filter, pagination);
        });

        return () => {
            EventBus.remove(DELETE_TELEGRAM_ORDER_BOT_ORDER_SUCCEED, onDeleteTelegramOrderBotOrderSucceedHandler);
            EventBus.remove(BULK_DELETE_TELEGRAM_ORDER_BOT_ORDERS_FINISHED, onBulkDeleteTelegramOrderBotOrdersFinishedHandler);
        }
    }, [activeOrganization, filter, pagination]);

    return (
        <TelegramOrderBotOrderDataTable filters={filter}
                                        pagination={pagination}
                                        columnVisibility={columnVisibility}
                                        loading={loading}
                                        orders={orders}
                                        count={count}
                                        onFilterChange={onFilterChange}
                                        onDebouncedFilterChange={onDebouncedFilterChange}
                                        onPaginationChange={onPaginationChange}
                                        onColumnVisibilityChange={onColumnVisibilityChange}
                                        onReloadData={() => load(filter, pagination)}
        />
    );
};

export default ContractorTelegramOrders;