import {createSlice} from "@reduxjs/toolkit";
import roamingVerificationActService from "../../../../services/roaming/roamingVerificationActService";
import EventBus from "../../../eventbus/EventBus";
import {
    DOCUMENT_CANCEL_FAILED,
    DOCUMENT_CANCEL_STARTED,
    DOCUMENT_CANCEL_SUCCESS,
    DOCUMENT_DECLINE_FAILED,
    DOCUMENT_DECLINE_STARTED,
    DOCUMENT_DECLINE_SUCCESS,
    DOCUMENT_SIGN_FAILED,
    DOCUMENT_SIGN_STARTED,
    DOCUMENT_SIGN_SUCCESS
} from "../../../eventbus/signEvents";
import {signDocAndGetRoamingSignature} from "./roamingReducer";
import {
    ROAMING_VERIFICATION_ACT_SYNC_FAILED,
    ROAMING_VERIFICATION_ACT_SYNC_REQUESTED,
    ROAMING_VERIFICATION_ACT_SYNC_SUCCESS
} from "../../../eventbus/roaming/roamingVerificationActEvents";

export const roamingVerificationActSlice = createSlice({
    name: "roamingVerificationAct",
    initialState: {
        filters: {
            registryId: null,
            status: null,
            number: '',
            dateStart: null,
            dateEnd: null,
            createdAtStart: null,
            createdAtEnd: null,
            contractNumber: '',
            contractDate: null,
            contractor: null
        },
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        columnVisibility: {
            "selectColumn": true,
            "#": true,
            "number": true,
            "date": true,
            "total": true,
            "status": true,
            "created_at": true,
            "contractor": true,
            "contract_number": true,
            "contract_date": true
        }
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setColumnVisibility: (state, action) => {
            state.columnVisibility = action.payload;
        }
    }
})

export default roamingVerificationActSlice.reducer;

export const updateFilters = (filters) => (dispatch) => {
    dispatch(roamingVerificationActSlice.actions.setFilters(filters));
};
export const updatePagination = (pagination) => (dispatch) => {
    dispatch(roamingVerificationActSlice.actions.setPagination(pagination));
};
export const updateColumnVisibility = (columnVisibility) => (dispatch) => {
    dispatch(roamingVerificationActSlice.actions.setColumnVisibility(columnVisibility));
};
export const loadVerificationAct = id => {
    return roamingVerificationActService.get(id)
}
export const syncVerificationActAsync = (id) => {
    EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_REQUESTED, id)
    return new Promise((resolve, reject) => {
        roamingVerificationActService.sync(id)
            .then((response) => {
                EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_SUCCESS, id);
                resolve(response.data);
            })
            .catch((error) => {
                let errorMessage = {message: error.response.data}
                EventBus.dispatch(ROAMING_VERIFICATION_ACT_SYNC_FAILED, errorMessage);
                reject(error);
            })
    })
}
export const loadDraftVerificationAct = id => {
    return roamingVerificationActService.getDraft(id)
}
export const getVerificationActPdfAsync = (id) => {
    return new Promise((resolve, reject) => {
        roamingVerificationActService.getVerificationActPdf(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
};

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(roamingVerificationActSlice.actions.updateFilterOptions({...params}))
}
export const verificationActSignAndRegister = async ({verificationAct, certificate, lang}) => {
    try {
        EventBus.dispatch(DOCUMENT_SIGN_STARTED);
        let pkcs7_64 = await signDocAndGetRoamingSignature(verificationAct, certificate)
        const response = await roamingVerificationActService.registerRoamingVerificationAct({
            id: verificationAct.VerificationActId,
            signature: pkcs7_64,
            ownerInn: verificationAct.OwnerTin,
            lang: lang
        })
        EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
        return response
    } catch (error) {
        console.log(error);
        if (error.detected) {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
        } else {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
        }
    }
}
export const verificationActSaveDraft = async ({verificationAct}) => {
    try {
        EventBus.dispatch(DOCUMENT_SIGN_STARTED);
        const response = await roamingVerificationActService.saveDraftRoamingVerificationAct({
            content: JSON.stringify(verificationAct)
        })
        EventBus.dispatch(DOCUMENT_SIGN_SUCCESS);
        return response
    } catch (error) {
        if (error.detected) {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
        } else {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
        }
    }
}
export const cancelAsync = async (certificate, lang, contract, comment) => {
    try {
        EventBus.dispatch(DOCUMENT_CANCEL_STARTED, contract.id);
        const {data: hashCode} = await roamingVerificationActService.getCancelHashCode(contract.id, comment)
        let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
        const {data: canceledContract} = await roamingVerificationActService.cancel(contract.id, {
            signature: roamingSignature,
            lang: lang
        })
        EventBus.dispatch(DOCUMENT_CANCEL_SUCCESS, canceledContract)
    } catch (error) {
        if (error.detected) {
            EventBus.dispatch(DOCUMENT_CANCEL_FAILED, error.message);
        } else {
            EventBus.dispatch(DOCUMENT_CANCEL_FAILED, "edi.document.sign.notification.default");
        }
    }
}
export const customerAcceptAsync = async (certificate, lang, contract) => {
    try {
        EventBus.dispatch(DOCUMENT_SIGN_STARTED, contract.id);
        const {data: hashCode} = await roamingVerificationActService.getAcceptHashCode(contract.id)
        let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate, true)
        const {data: acceptedContract} = await roamingVerificationActService.customerAccept(contract.id, {
            signature: roamingSignature,
            lang: lang
        })
        EventBus.dispatch(DOCUMENT_SIGN_SUCCESS, acceptedContract)
    } catch (error) {
        if (error.detected) {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, error.message);
        } else {
            EventBus.dispatch(DOCUMENT_SIGN_FAILED, "edi.document.sign.notification.default");
        }
    }
}
export const customerDeclineAsync = async (certificate, lang, contract, comment) => {
    try {
        EventBus.dispatch(DOCUMENT_DECLINE_STARTED, contract.id);
        const {data: hashCode} = await roamingVerificationActService.getDeclineHashCode(contract.id, comment)
        let roamingSignature = await signDocAndGetRoamingSignature(hashCode, certificate)
        const {data: declineContract} = await roamingVerificationActService.customerDecline(contract.id, {
            signature: roamingSignature,
            comment: comment,
            lang: lang
        })
        EventBus.dispatch(DOCUMENT_DECLINE_SUCCESS, declineContract)
    } catch (error) {
        if (error.detected) {
            EventBus.dispatch(DOCUMENT_DECLINE_FAILED, error.message);
        } else {
            EventBus.dispatch(DOCUMENT_DECLINE_FAILED, "edi.document.sign.notification.default");
        }
    }
}

export const selectFilters = state => state.roamingVerificationAct.filters;
export const selectPagination = state => state.roamingVerificationAct.pagination;
export const selectColumnVisibility = state => state.roamingVerificationAct.columnVisibility;
