import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import RoamingInvoiceFormSingleSideTypeSelector from "./RoamingInvoiceFormSingleSideTypeSelector";
import RoamingInvoiceFormMember from "./RoamingInvoiceFormMember";
import RoamingInvoiceFormForeignCompanyInfo from "./RoamingInvoiceFormForeignCompanyInfo";
import RoamingInvoiceFormExchangeInfo from "./RoamingInvoiceFormExchangeInfo";
import RoamingInvoiceFormInvestmentInfo from "./RoamingInvoiceFormInvestmentInfo";
import {loadExchangeTypes} from "../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import roamingReferenceService from "../../../../../services/roamingReference/roamingReferenceService";
import {isNdsPayerCertificateActive, singleSidedInvoiceTypes} from "../../../../../enum/RoamingInvoiceWrapper";

const fieldName = 'buyer';
const RoamingInvoiceFormCardCustomer = ({isEditOrCopy, setCustomerPrivileges}) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const isSingleSided = useWatch({name: `isSingleSided`, exact: true});
    const hasInvestmentObject = useWatch({name: `hasInvestmentObject`, exact: true});
    const hasExchange = useWatch({name: `hasExchange`, exact: true});
    const [company, setCompany] = useState(null);
    const [eventCount, setEventCount] = useState(0);

    useEffect(() => {
        isSingleSided && setCompany(null);
    }, [isSingleSided])

    useEffect(() => {
        hasExchange && dispatch(loadExchangeTypes());
    }, [hasExchange])

    const onChangeCompany = async (company) => {
        // set state values
        {
            if (company) {
                const {data: privileges} = await roamingReferenceService.getCustomerCompanyPrivileges({inn: company.person_num || company.inn});
                setCustomerPrivileges(privileges || []);
                setCompany(company);
            } else {
                setCustomerPrivileges([]);
                setCompany(null);
            }
        }

        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (company) {
            form.setValue(`${fieldName}.name`, company.short_name || company.name);
            form.setValue(`${fieldName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''))
            form.setValue(`${fieldName}.bankId`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''))
            form.setValue(`${fieldName}.address`, company.address);
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, company.oked);
            form.setValue(`${fieldName}.districtId`, company.district_id);
            form.setValue(`${fieldName}.director`, company.director);
            form.setValue(`${fieldName}.accountant`, company.accountant);
            form.setValue(`${fieldName}.branch`, null);

            if (company.vat_reg_code && company.vat_rate_status && company.vat_rate_status === isNdsPayerCertificateActive) {
                form.setValue(`${fieldName}.vatRegCode`, company.vat_reg_code);
                form.setValue(`${fieldName}.vatRegStatus`, company.vat_rate_status);
            } else {
                form.setValue(`${fieldName}.vatRegCode`, '');
                form.setValue(`${fieldName}.vatRegStatus`, '');
            }
        } else {
            form.setValue(`${fieldName}.name`, '');
            form.setValue(`${fieldName}.account`, '')
            form.setValue(`${fieldName}.bankId`, '')
            form.setValue(`${fieldName}.address`, '');
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, '');
            form.setValue(`${fieldName}.districtId`, null);
            form.setValue(`${fieldName}.director`, '');
            form.setValue(`${fieldName}.accountant`, '');
            form.setValue(`${fieldName}.vatRegCode`, '');
            form.setValue(`${fieldName}.vatRegStatus`, '');
            form.setValue(`${fieldName}.branch`, null);
        }
    };

    return (
        <Card className="h-100">
            <Card.Body>
                <Row>
                    <Col xs={12} className="d-flex flex-wrap">
                        {!isSingleSided &&
                            <>
                                <Form.Switch label={t(lang, 'roaming.invoice.exchange')}
                                             disabled={!!hasInvestmentObject}
                                             className="me-2"
                                             {...form.register(`hasExchange`, {
                                                 shouldUnregister: true
                                             })}
                                />
                                {!!company?.is_investment_object_company &&
                                    <Form.Switch label={t(lang, 'roaming.invoice.investment_object')}
                                                 className="me-2"
                                                 {...form.register(`hasInvestmentObject`, {
                                                     shouldUnregister: true
                                                 })}
                                    />
                                }

                                <Form.Switch label={t(lang, 'roaming.invoice.form.rent.checkbox')}
                                             className="me-2"
                                             {...form.register(`hasRent`, {
                                                 shouldUnregister: true
                                             })}
                                />
                            </>
                        }
                        <Form.Switch label={t(lang, 'roaming.invoice.singleSided')}
                                     {...form.register(`isSingleSided`, {
                                         shouldUnregister: true
                                     })}
                        />
                    </Col>
                    <Col xs={12}>
                        {isSingleSided ? (
                            <>
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column sm="4" md="12" lg="4">
                                        {t(lang, 'roaming.invoice.single_member_invoice_type')}
                                    </Form.Label>
                                    <Col sm={8} md={12} lg={8}>
                                        <Controller
                                            name="singleSidedType"
                                            render={({field}) => (
                                                <RoamingInvoiceFormSingleSideTypeSelector defaultType={field.value}
                                                                                          onChange={field.onChange}
                                                                                          options={singleSidedInvoiceTypes}
                                                />
                                            )}
                                        />
                                    </Col>
                                </Form.Group>
                                <RoamingInvoiceFormForeignCompanyInfo/>
                            </>
                        ) : (
                            <>
                                <RoamingInvoiceFormMember
                                    fieldName={fieldName}
                                    isSearchable={true}
                                    onChangeCompany={onChangeCompany}
                                    company={company}
                                />
                                {hasInvestmentObject && <RoamingInvoiceFormInvestmentInfo/>}
                                {hasExchange && <RoamingInvoiceFormExchangeInfo/>}
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardCustomer;
