import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {selectActiveCurrencies} from "../../../../app/store/reducers/currency/currencyReducer";
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import numeral from "numeral";
import SoftBadge from "../../../common/SoftBadge";
import classNames from "classnames";
import {Card, Col, Form, Row} from "react-bootstrap";
import AdvanceTableCustomPagination2 from "../../item/datatable/AdvanceTableCustomPagination2";
import {transliterate} from "transliteration";
import {debounce} from "underscore";

const SaleItemWarehouseByReportDataTable = ({warehouseItems, showShortageColumn}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const activeCurrencies = useSelector(selectActiveCurrencies);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 1
    });
    const [filter, setFilter] = useState({
        name: ""
    });
    const columns = useMemo(() => {
        return [
            {
                accessor: 'index',
                Header: "#",
                id: "row",
                cellProps: {
                    className: "p-0 text-center",
                    style: {
                        width: 40,
                    },
                },
                headerProps: {
                    className: "p-0 text-center",
                    style: {
                        width: 40,
                    }
                },
                Cell: ({row}) => {
                    return `${row.index + 1}`;
                }
            },
            {
                accessor: 'warehouse_name',
                Header: t(lang, "warehouse.operation.report.sale_item.report.warehouse_name"),
                Cell: ({row: {original}}) => {
                    return `${original.warehouse.name}`
                }
            },
            {
                accessor: 'quantity',
                Header: t(lang, "warehouse.operation.report.sale_items.quantity"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '150px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '150px'
                    }
                },
                Cell: ({row: {original}}) => {
                    return (
                        <h6 className="text-end">{numeral.formats[numberFormat].format(original.quantity)}</h6>
                    );
                }
            },
            {
                accessor: 'purchase_price',
                Header: t(lang, "warehouse.operation.report.sale_item_report.purchase_price"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                Cell: ({row: {original}}) => {
                    if (original.purchase_price)
                        return <h6
                            className="text-end">{numeral.formats[numberFormat].format(original.purchase_price.amount)} {original.purchase_price.currency.name}</h6>

                    return <h6 className="text-end">0</h6>;
                }
            },
            {
                accessor: 'profit_by_purchase_price',
                Header: t(lang, "warehouse.operation.report.sale_items.profit_by_purchase_price"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                Cell: ({row: {original}}) => {
                    return (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {activeCurrencies.map((currency, index) => {
                                const price = original.profit_by_purchase_price.find((price) => price.currency.id === currency.id);
                                return (
                                    <SoftBadge bg='info'
                                               key={currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(price ? price.amount : 0)}
                                            <small className={'ms-2 fw-bold text-info'}>{currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                )
                            })}
                        </div>
                    )
                }
            },
            {
                accessor: 'net_price',
                Header: t(lang, "warehouse.operation.report.sale_items.net_price"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                Cell: ({row: {original}}) => {
                    return (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {activeCurrencies.map((currency, index) => {
                                const price = original.net_price.find((price) => price.currency.id === currency.id);
                                return (
                                    <SoftBadge bg='success'
                                               key={currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(price ? price.amount : 0)}
                                            <small className={'ms-2 fw-bold text-success'}>{currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                )
                            })}
                        </div>
                    )
                }
            },
            {
                accessor: 'purchase_net_price',
                Header: t(lang, "warehouse.operation.report.sale_items.purchase_net_price"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                Cell: ({row: {original}}) => {
                    if (!original.purchase_net_price || !original.purchase_net_price.length || !original.purchase_net_price.some((price) => price.amount > 0)) {
                        return (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {activeCurrencies.map((currency, index) => {
                                    return (
                                        <SoftBadge bg='warning'
                                                   key={currency.id}
                                                   className={classNames("w-100", {"mt-2": index !== 0})}
                                        >
                                            <h6 className="m-0 text-end">
                                                {numeral.formats[numberFormat].format(0)}
                                                <small className={'ms-2 fw-bold text-warning'}>{currency.name}</small>
                                            </h6>
                                        </SoftBadge>
                                    )
                                })}
                            </div>
                        )
                    }

                    return (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {activeCurrencies.map((currency, index) => {
                                const price = original.purchase_net_price.find((price) => price.currency.id === currency.id);
                                return (
                                    <SoftBadge bg='primary'
                                               key={currency.id}
                                               className={classNames("w-100", {"mt-2": index !== 0})}
                                    >
                                        <h6 className="m-0 text-end">
                                            {numeral.formats[numberFormat].format(price ? price.amount : 0)}
                                            <small className={'ms-2 fw-bold text-primary'}>{currency.name}</small>
                                        </h6>
                                    </SoftBadge>
                                )
                            })}
                        </div>
                    )
                }
            },
            showShortageColumn && {
                accessor: 'shortage',
                Header: t(lang, "warehouse.operation.item.common.filter.has_shortage_from_order"),
                headerProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                cellProps: {
                    className: "text-center",
                    style: {
                        width: '200px'
                    }
                },
                Cell: ({row: {original}}) => {
                    if (!original.sale_order_item_shortages_net_price || !original.sale_order_item_shortages_net_price.length) {
                        return (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {activeCurrencies.map((currency, index) => {
                                    return (
                                        <SoftBadge bg='primary'
                                                   key={currency.id}
                                                   className={classNames("w-100", {"mt-2": index !== 0})}
                                        >
                                            <h6 className="m-0 text-end">
                                                {numeral.formats[numberFormat].format(0)}
                                                <small className={'ms-2 fw-bold text-primary'}>{currency.name}</small>
                                            </h6>
                                        </SoftBadge>
                                    )
                                })}
                            </div>
                        )
                    }

                    return (
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {activeCurrencies.map((currency, index) => {
                                const price = original.sale_order_item_shortages_net_price.find((price) => price.currency.id === currency.id);
                                return (
                                    <Fragment>
                                        {price?.amount > 0 && <SoftBadge bg='danger'
                                                                         key={currency.id}
                                                                         className={classNames("w-100", {"mt-2": index !== 0})}
                                        >
                                            <h6 className="m-0 text-end">
                                                {numeral.formats[numberFormat].format(price ? price.amount : 0)}
                                                <small className={'ms-2 fw-bold text-danger'}>{currency.name}</small>
                                            </h6>
                                        </SoftBadge>}
                                    </Fragment>

                                )
                            })}
                        </div>
                    )
                }
            },
        ].filter(i => i);
    }, [lang, activeCurrencies, numberFormat]);
    const data = useMemo(() => {
        const filteredData = [];
        for (let i = 0; i < warehouseItems.length; i++) {
            const warehouseItem = warehouseItems[i];

            if (filter.name) {
                const searchTermLower = filter.name.trim().toLowerCase();
                const itemNameLower = warehouseItem.warehouse.name.trim().toLowerCase();
                const transliteratedQuery = transliterate(searchTermLower);
                const transliteratedItemName = transliterate(itemNameLower);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                if (!(filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length))
                    continue;
            }

            filteredData.push(warehouseItem);
        }

        return filteredData;
    }, [warehouseItems, filter]);
    const onDebouncedFilterChanged = useCallback(debounce(setFilter, 500), []);

    return (
        <AdvanceTableWrapper columns={columns}
                             data={data}
                             pagination
                             perPage={pagination.pageSize}
                             currentPage={pagination.pageIndex - 1}
        >
            <Card>
                <Card.Header>
                    <Row className="gy-3">
                        <Col xs={12}>
                            <Card.Title>{t(lang, "warehouse.operation.report.sale_items_report.warehouse_by_report")}</Card.Title>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
                            <Form.Group>
                                <Form.Label>{t(lang, 'warehouse.operation.report.sale_item.report.warehouse_name')}</Form.Label>
                                <Form.Control name="name"
                                              placeholder={t(lang, 'items.common.datatable.filter.deep_search.placeholder')}
                                              defaultValue={filter.name}
                                              onChange={(e) => onDebouncedFilterChanged({
                                                  ...filter,
                                                  name: e.target.value
                                              })}

                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <AdvanceTable table
                                  headerClassName="bg-200 text-900 text-wrap align-middle"
                                  rowClassName="align-middle white-space-nowrap"
                                  tableProps={{
                                      size: 'sm',
                                      striped: true,
                                      bordered: true,
                                      className: 'fs--1 mb-0 border overflow-hidden',
                                  }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination2 table
                                                   rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                                   onPageChange={(page) => setPagination({
                                                       ...pagination,
                                                       page: page
                                                   })}
                                                   onLimitChange={(limit) => setPagination({
                                                       ...pagination,
                                                       limit: limit
                                                   })}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default SaleItemWarehouseByReportDataTable;