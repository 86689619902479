import React, {useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import RoamingInvoiceFormMember from "./RoamingInvoiceFormMember";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import RoamingInvoiceFormIncomeTypeSelector from "./RoamingInvoiceFormIncomeTypeSelector";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import roamingReferenceService from "../../../../../services/roamingReference/roamingReferenceService";
import dayjs from "dayjs";
import {isNdsPayerCertificateActive} from "../../../../../enum/RoamingInvoiceWrapper";


const fieldName = 'seller';
const RoamingInvoiceFormCardExecutor = ({isEditOrCopy, setExecutorPrivileges, setTurnoverPrivileges}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const invoiceDate = useWatch({name: 'facturaDoc.facturaDate', exact: true});
    const [company, setCompany] = useState(null);
    const [eventCount, setEventCount] = useState(0);

    const onChangeCompany = async (company) => {
        // set state values
        {
            if (company) {
                if (!company.vat_rate_status && !company.vat_reg_code) {
                    const {data: turnoverPrivileges} = await roamingReferenceService.getTurnoverPrivileges({inn: company.person_num || company.inn, date: dayjs(invoiceDate || new Date()).format("YYYY-MM-DD")});
                    setTurnoverPrivileges(turnoverPrivileges || []);
                }

                const {data: executorPrivileges} = await roamingReferenceService.getExecutorCompanyPrivileges({inn: company.person_num || company.inn});
                setExecutorPrivileges(executorPrivileges || []);
                setCompany(company);
            } else {
                setTurnoverPrivileges([]);
                setExecutorPrivileges([]);
                setCompany(null);
            }
        }

        // if is copy or edit, prevent from conflict of values
        {
            if (isEditOrCopy && eventCount === 0) {
                setEventCount(eventCount + 1);
                return;
            }
        }

        if (company) {
            form.setValue(`${fieldName}.name`, company.name);
            form.setValue(`${fieldName}.account`, company.account || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].account : ''))
            form.setValue(`${fieldName}.bankId`, company.mfo || (company.bank_accounts?.length > 0 ? company.bank_accounts[0].bankId : ''))
            form.setValue(`${fieldName}.address`, company.address);
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, company.oked);
            form.setValue(`${fieldName}.districtId`, company.district_id);
            form.setValue(`${fieldName}.director`, company.director);
            form.setValue(`${fieldName}.accountant`, company.accountant);
            form.setValue(`${fieldName}.branch`, null);

            if (company.vat_reg_code && company.vat_rate_status && company.vat_rate_status === isNdsPayerCertificateActive) {
                form.setValue(`${fieldName}.vatRegCode`, company.vat_reg_code);
                form.setValue(`${fieldName}.vatRegStatus`, company.vat_rate_status);
            } else {
                form.setValue(`${fieldName}.vatRegCode`, '');
                form.setValue(`${fieldName}.vatRegStatus`, '');
            }
        } else {
            form.setValue(`${fieldName}.name`, '');
            form.setValue(`${fieldName}.account`, '')
            form.setValue(`${fieldName}.bankId`, '')
            form.setValue(`${fieldName}.address`, '');
            form.setValue(`${fieldName}.mobile`, '');
            form.setValue(`${fieldName}.workPhone`, '');
            form.setValue(`${fieldName}.oked`, '');
            form.setValue(`${fieldName}.districtId`, null);
            form.setValue(`${fieldName}.director`, '');
            form.setValue(`${fieldName}.accountant`, '');
            form.setValue(`${fieldName}.vatRegCode`, '');
            form.setValue(`${fieldName}.vatRegStatus`, '');
            form.setValue(`${fieldName}.branch`, null);
        }
    };

    return (
        <Card className="h-100">
            <Card.Body>
                <RoamingInvoiceFormMember fieldName={fieldName}
                                          isSearchable={false}
                                          onChangeCompany={onChangeCompany}
                                          company={company}
                />
                {(company && !company.vat_rate_status && !company.vat_reg_code) &&
                    <Form.Group as={Row}>
                        <Form.Label column xs="12" sm="4" md="12" lg="4">{t(lang, 'roaming.invoice.incomeType')}</Form.Label>
                        <Col xs={12} sm={8} md={12} lg={8}>
                            <Controller name={`incomeType`}
                                        render={({field}) => (
                                            <RoamingInvoiceFormIncomeTypeSelector defaultIncomeType={field.value}
                                                                                  onChange={field.onChange}
                                            />
                                        )}
                            />
                        </Col>
                    </Form.Group>
                }
            </Card.Body>
        </Card>
    );
};

export default RoamingInvoiceFormCardExecutor;