import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    loadContractsAsync,
    changeContractId,
    selectContractId,
    selectContracts
} from "../../../app/store/reducers/contract/contractReducer";
import Select from 'react-select';
import PropTypes from "prop-types";
import {selectDateDayJSFormat, selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import dayjs from "dayjs";
import EventBus from "../../../app/eventbus/EventBus";
import {REGISTER_CONTRACT_SUCCEED} from "../../../app/eventbus/contractEvents";

const SelectContract = ({onChange, defaultContractNumber, defaultContractDate, ...props}) => {
    const contracts = useSelector(selectContracts);
    const [contract, setContract] = useState(null);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const contractId = useSelector(selectContractId);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    useEffect(() => {
        if (contracts && defaultContractNumber && defaultContractDate) {
            const defaultContract = contracts.find(c => c?.info?.number === defaultContractNumber && c?.info?.date === defaultContractDate);
            setContract(defaultContract);
            onChange(defaultContract);
        } else if (contracts && contractId) {
            const lastSelectedContract = contracts.find(c => c.id === contractId);
            setContract(lastSelectedContract);
            onChange(lastSelectedContract);
        }
    }, [contracts, defaultContractNumber, defaultContractDate, contractId])

    useEffect(() => {
        const registerContractSuccessConsumer = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            dispatch(loadContractsAsync())
        });

        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, registerContractSuccessConsumer)
        }
    }, [])

    const onOptionChange = option => {
        setContract(option);
        onChange(option);
        dispatch(changeContractId(option?.id));
    }

    const getOptionValue = (option) => option.id;
    const getOptionLabel = (option) => {
        const date = dayjs(option.info.date, 'YYYY-MM-DD');
        return `${option.info.number} от ${date.format(dateFormat)}`
    };

    return (
        <Select
            {...props}
            isClearable
            value={contract}
            options={contracts}
            onChange={onOptionChange}
            hideSelectedOptions={true}
            getOptionLabel={getOptionLabel}
            classNamePrefix="react-select"
            getOptionValue={getOptionValue}
            placeholder={t(lang, 'edi.common.form.select.placeholder')}
        />
    )
}
SelectContract.propTypes = {
    onChange: PropTypes.func
}
SelectContract.defaultProps = {
    onChange: () => {
    }
}
export default SelectContract;
