import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import ContractFormationInfo from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationInfo";
import ContractFormationExecutorInfo from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationExecutorInfo";
import ContractFormationCustomerInfo from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationCustomerInfo";
import ContractFormationProducts from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationProducts";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ContractFormationParts from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationParts";
import ContractFormationFile from "../../../../components/hippo/contract-formation/contract-formation-form/ContractFormationFile";
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer";
import {sendContractFormationAsync} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";
import {useHistory} from "react-router-dom";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    REGISTER_CONTRACT_FORMATION_FAILED,
    REGISTER_CONTRACT_FORMATION_REQUESTED,
    REGISTER_CONTRACT_FORMATION_SUCCESS,
    SEND_CONTRACT_FORMATION_FAILED,
    SEND_CONTRACT_FORMATION_REQUESTED,
    SEND_CONTRACT_FORMATION_SUCCESS,
    UPDATE_CONTRACT_FORMATION_FAILED,
    UPDATE_CONTRACT_FORMATION_REQUESTED,
    UPDATE_CONTRACT_FORMATION_SUCCESS
} from "../../../../app/eventbus/contractFormationEvents";
import {toast} from "react-toastify";
import {
    DocumentReceived,
    GetAllowedStatus
} from "../../../../components/hippo/contract-formation/contract-formation-view/ContractFormationStatus";

const ContractFormationForm = ({document, onSubmit}) => {
    const [show, setShow] = useState(false);
    const acceptSave = GetAllowedStatus(document?.status)
    const acceptCreate = document?.status === DocumentReceived
    const {control} = useFormContext()
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(selectLang);
    const t = Translate


    const handleClose = () => {
        setShow(false);
        history.push(`../view/${document?.id}`)
    }

    const {fields: fieldsProduct, append: appendProduct, insert: insertProduct, remove: removeProduct, update: updateProduct, replace: replaceProducts} = useFieldArray({control: control, name: 'products', focusAppend: false})
    const {fields: fieldsPart, update} = useFieldArray({control: control, name: 'parts', focusAppend: false})
    const {fields: fieldsFile, append: appendFile, remove: removeFile} = useFieldArray({control: control, name: 'attachments', focusAppend: false, keyName: 'index'})
    const watchFieldArrayItems = useWatch({name: 'products', control: control});
    const controlledFieldsItems = fieldsProduct.map((field, index) => {
        return {
            ...field,
            ...watchFieldArrayItems[index]
        };
    });

    const watchFieldArray = useWatch({name: 'parts', control: control});
    const controlledFieldsPart = fieldsPart.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    const watchFieldArrayProduct = useWatch({name: 'attachments', control: control});
    const controlledFieldsProduct = fieldsFile.map((field, index) => {
        return {
            ...field,
            ...watchFieldArrayProduct[index]
        };
    });

    const sendContractFormationHandler = async () => {
        await sendContractFormationAsync({id: document?.id, contractdata: document})
    }

    const addFieldProduct = () => {
        insertProduct(0,{
            catalogCode: null,
            catalogName: null,
            certificate: null,
            barCode: "",
            name: "",
            measureId: "",
            measureName: "",
            packageCode: null,
            packageName: null,
            packages: null,
            catalog: null,
            measurement: null,
            count: null,
            summa: null,
            deliverySum: null,
            exciseRate: '',
            exciseSum: 0,
            vatRate: '',
            vatSum: null,
            deliverySumWithVat: null,
        })
    }

    const removeFieldProduct = index => {
        removeProduct(index)
    }

    const addFieldFile = () => {
        appendFile({
            file: null,
            date: null,
            filename: "Файл не выбран",
            attachment_id: new Date().getTime(),
        })
    }

    const removeFieldFile = index => {
        removeFile(index)
    }

    useEffect(() => {
        const onContractFormationRegisterRequestedHandler = EventBus.on(REGISTER_CONTRACT_FORMATION_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onContractFormationRegisterSuccessHandler = EventBus.on(REGISTER_CONTRACT_FORMATION_SUCCESS, () => {
            toast.success(t(lang, "edi.common.toast.success"))
            dispatch(updateShowInProgressAsync(false))
            setShow(true);
        })

        const onContractFormationRegisterFailedHandler = EventBus.on(REGISTER_CONTRACT_FORMATION_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })


        const onContractFormationUpdateRequestedHandler = EventBus.on(UPDATE_CONTRACT_FORMATION_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onContractFormationUpdateSuccessHandler = EventBus.on(UPDATE_CONTRACT_FORMATION_SUCCESS, (document) => {
            toast.success(t(lang, "edi.common.toast.success"))
            history.push(`../view/${document.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onContractFormationUpdateFailedHandler = EventBus.on(UPDATE_CONTRACT_FORMATION_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })


        const onContractFormationSendRequestedHandler = EventBus.on(SEND_CONTRACT_FORMATION_REQUESTED, () => {
            dispatch(updateShowInProgressAsync(true))
        })

        const onContractFormationSendSuccessHandler = EventBus.on(SEND_CONTRACT_FORMATION_SUCCESS, (document) => {
            toast.success(t(lang, "edi.common.toast.success"))
            setShow(false)
            history.push(`../view/${document.id}`)
            dispatch(updateShowInProgressAsync(false))
        })

        const onContractFormationSendFailedHandler = EventBus.on(SEND_CONTRACT_FORMATION_FAILED, () => {
            toast.error(t(lang, "edi.common.toast.error"))
            dispatch(updateShowInProgressAsync(false))
        })


        return () => {
            EventBus.remove(REGISTER_CONTRACT_FORMATION_REQUESTED, onContractFormationRegisterRequestedHandler);
            EventBus.remove(REGISTER_CONTRACT_FORMATION_SUCCESS, onContractFormationRegisterSuccessHandler);
            EventBus.remove(REGISTER_CONTRACT_FORMATION_FAILED, onContractFormationRegisterFailedHandler);
            EventBus.remove(UPDATE_CONTRACT_FORMATION_REQUESTED, onContractFormationUpdateRequestedHandler);
            EventBus.remove(UPDATE_CONTRACT_FORMATION_SUCCESS, onContractFormationUpdateSuccessHandler);
            EventBus.remove(UPDATE_CONTRACT_FORMATION_FAILED, onContractFormationUpdateFailedHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_REQUESTED, onContractFormationSendRequestedHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_SUCCESS, onContractFormationSendSuccessHandler);
            EventBus.remove(SEND_CONTRACT_FORMATION_FAILED, onContractFormationSendFailedHandler);
        }
    }, [])

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, "edi.contract_formation.modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, "edi.contract_formation.modal.description")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={sendContractFormationHandler} variant="falcon-success" size="lg" className="mx-2">
                        {t(lang, "roaming.common.send")}
                    </Button>
                    <Button onClick={handleClose} variant="falcon-info" size="lg" className="mx-2">
                        {t(lang, "edi.contract_formation.modal.view_button")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="mb-4">
                <Col className="mb-4 col-12">
                    <ContractFormationInfo document={document}/>
                </Col>
                <Col className="mb-4 col-12 col-lg-6">
                    <ContractFormationExecutorInfo editDocumentStatus={document?.status}/>
                </Col>
                <Col className="mb-4 col-12 col-lg-6">
                    <ContractFormationCustomerInfo editDocumentStatus={document?.status}/>
                </Col>
                <Col className="mb-4">
                    <ContractFormationProducts
                        updateProduct={updateProduct}
                        controlledFieldsProduct={controlledFieldsProduct}
                        fieldsProduct={controlledFieldsItems}
                        addFieldProduct={addFieldProduct}
                        removeFieldProduct={removeFieldProduct}
                        replaceProducts={replaceProducts}
                        appendProduct={appendProduct}
                        removeProduct={removeProduct}
                    />
                </Col>
                <Col xs={12} className="mb-4">
                    <ContractFormationParts
                        fieldsPart={controlledFieldsPart}
                        document={document}
                        update={update}
                    />
                </Col>
                <Col xs={12}>
                    <ContractFormationFile
                        fieldsFile={fieldsFile}
                        addFieldFile={addFieldFile}
                        removeFieldFile={removeFieldFile}
                        documentContract={document}
                    />
                </Col>
            </Row>

            <Card>
                <Col className="d-flex justify-content-end m-3">
                    {acceptCreate &&
                        <Button variant="falcon-warning" size="lg" onClick={onSubmit}>
                            {t(lang, "edi.common.button.create")}
                        </Button>
                    }
                    {acceptSave &&
                        <Button variant="falcon-info" size="lg" className="mx-2" onClick={onSubmit}>
                            {t(lang, "edi.common.button.save")}
                        </Button>
                    }
                </Col>
            </Card>
        </>
    )
}


export default ContractFormationForm;
