import React, {Fragment, useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import IconAlert from "../common/IconAlert";

const AlertInfoRoaming = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(false);

    useEffect(() => {
        const lastClosedTime = localStorage.getItem('alert-roaming-closed-time');
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

        if (!lastClosedTime || (Date.now() - Number(lastClosedTime)) > oneDayInMilliseconds) {
            setShow(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('alert-roaming-closed-time', Date.now().toString());
        setShow(false);
    };

    const translatedText1 = t(lang, 'roaming.alert.text', {
        strong: '{strong}',
    });

    return (
        <Fragment>
            {
                show && <IconAlert variant={'info'} dismissible onClose={handleClose}>
                    <p className={'mb-0'}>
                        {translatedText1.split(/({strong})/g).map((part, index) =>
                            part === '{strong}' ?
                                <strong key={index} className={'fs-0'}>{t(lang, 'roaming.alert.text.strong')}</strong> : part)
                        }
                    </p>
                </IconAlert>
            }
        </Fragment>
    );
};

export default AlertInfoRoaming;