import React from 'react';
import {Col, Form, Row, Spinner} from "react-bootstrap";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import IconButton from "../../../common/IconButton";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import SelectYesOrNo from "../../SelectYesOrNo";

const OwnerOrganizationSalesDataTableHeader = ({load, loading, filters, onFilterChange}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const dateFormat = useSelector(selectDateFormat);

    return (
        <Row className="g-2">
            <Col xs={12}>
                <Row className="g-2">
                    <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.sales.date_start')}</Form.Label>
                            <DatePicker className='form-control'
                                        dateFormat={`${dateFormat} HH:mm:ss`}
                                        selected={filters?.dateStart ? new Date(filters.dateStart) : null}
                                        onChange={(date) => onFilterChange({
                                            ...filters,
                                            dateStart: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null
                                        })}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                        <Form.Group>
                            <Form.Label>{t(lang, 'partners.common.sales.date_end')}</Form.Label>
                            <DatePicker className='form-control'
                                        dateFormat={`${dateFormat} HH:mm:ss`}
                                        selected={filters?.dateEnd ? new Date(filters.dateEnd) : null}
                                        onChange={(date) => onFilterChange({
                                            ...filters,
                                            dateEnd: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null
                                        })}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3} xxl={2}>
                        <Form.Group>
                            <Form.Label>{t(lang, "partners.common.sales.has_shortage_order")}</Form.Label>
                            <SelectYesOrNo
                                defaultValue={null}
                                onChange={(option) => onFilterChange({
                                        ...filters,
                                        hasShortageFromOrder: option.value
                                })}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} lg={4} xl={3} xxl={2} className="mt-2 mt-lg-auto">
                        <IconButton icon={faRefresh}
                                    variant={'falcon-warning'}
                                    onClick={load}
                                    disabled={loading}
                        >
                            {t(lang, 'partners.common.reload_sales')}
                            {loading &&
                                <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
                        </IconButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default OwnerOrganizationSalesDataTableHeader;