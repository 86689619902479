import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCompanyCatalogs} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import EventBus from "../../../../app/eventbus/EventBus";
import {SEND_WAYBILL_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillEvents";
import {getOrganizationBillingInfoAsync} from "../../../../app/store/reducers/billing/billingReducer";
import {SEND_WAYBILL_V2_SUCCEED} from "../../../../app/eventbus/roaming/roamingWaybillV2Events";
import {loadDraftWaybillAsync} from "../../../../app/store/reducers/roaming/roamingWaybillReducer";
import {loadDraftWaybillV2Async} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import WaybillV2 from "../../../../components/roaming/waybill/view/WaybillV2";
import SpinnerComponent from "../../../../components/hippo/spinner/SpinnerComponent";

const ViewDraftWaybillV2 = ({modalId}) => {

    const routerHistory = useHistory();
    const {params: {id}} = useRouteMatch();
    const [content, setContent] = useState(null);
    const [draftWaybill, setDraftWaybill] = useState(null);
    const activeOrganization = useSelector(selectActiveOrganization);

    useEffect(() => {
        loadDraftWaybill();
    }, [modalId, id, activeOrganization])

    const loadDraftWaybill = () => {
        loadDraftWaybillV2Async(modalId ? modalId : id)
            .then(loadedDraftWaybill => {
                const parsedContent = JSON.parse(loadedDraftWaybill.content);
                setContent(loadedDraftWaybill.content);
                customizeKeys(parsedContent, loadedDraftWaybill.created_at)
            })
            .catch(error => console.log(error))
    }

    const customizeKeys = (waybill, created_at) => {

        const transportType = waybill?.Roadway ? waybill.Roadway : waybill?.Railway ? waybill.Railway : null

        return setDraftWaybill({
            id: modalId ? modalId : id,
            created_at: created_at,
            executor: {
                inn_or_pinfl: waybill.Consignor.TinOrPinfl,
                name: waybill.Consignor.Name,
                branch: (waybill?.Consignor?.BranchCode && waybill?.Consignor?.BranchName) ? {
                    branch_code: waybill.Consignor.BranchCode,
                    branch_name: waybill.Consignor.BranchName
                } : null
            },
            customer: waybill?.Consignee ? {
                inn_or_pinfl: waybill?.Consignee?.TinOrPinfl || null,
                name: waybill?.Consignee?.Name || null,
                branch: (waybill?.Consignee?.BranchCode && waybill?.Consignee?.BranchName) ? {
                    branch_code: waybill.Consignee.BranchCode,
                    branch_name: waybill.Consignee.BranchName
                } : null
            } : null,
            freight_forwarder: {
                inn_or_pinfl: waybill.FreightForwarder.TinOrPinfl,
                name: waybill.FreightForwarder.Name,
            },
            carrier: {
                inn_or_pinfl: waybill.Carrier.TinOrPinfl,
                name: waybill.Carrier.Name,
            },
            responsible_person: {
                inn_or_pinfl: waybill.ResponsiblePerson.Pinfl,
                name: waybill.ResponsiblePerson.FullName,
            },
            info: {
                number: waybill.WaybillDoc.WaybillNo,
                date: waybill.WaybillDoc.WaybillDate
            },
            contract: {
                number: waybill.ContractDoc?.ContractNo || null,
                date: waybill.ContractDoc?.ContractDate || null
            },
            client_contract_info: {
                inn_or_pinfl: waybill.Client?.TinOrPinfl || null,
                name: waybill.Client?.Name || null,
                number: waybill.Client?.ContractNo || null,
                date: waybill.Client?.ContractDate || null
            },
            payer_contract_info: {
                inn_or_pinfl: waybill.Payer?.TinOrPinfl || null,
                name: waybill.Payer?.Name || null,
                number: waybill.Payer?.ContractNo || null,
                date: waybill.Payer?.ContractDate ||  null
            },
            transport_type: waybill.TransportType,
            delivery_type: waybill.DeliveryType,
            single_sided_type: waybill.SingleSidedType,
            roadway: {
                other_car_owners: waybill?.Roadway?.OtherCarOwners?.map(x => {
                    return {
                        inn_or_pinfl: x.TinOrPinfl,
                        name: x.Name
                    }
                }) || null,
                driver: {
                    inn_or_pinfl: transportType?.Driver?.Pinfl,
                    name: transportType?.Driver?.FullName,
                },
                truck: {
                    model: transportType?.Truck?.Model || null,
                    registration_number: transportType?.Truck?.RegNo || null
                } || null,
                trainCarriageNumber: transportType?.TrainCarriageNumber || null,
                trainDirection: transportType?.TrainDirection || null,
                product_groups: [
                    {
                        loading_point: {
                            region_code: transportType?.ProductGroups[0].LoadingPoint.RegionId,
                            region_name: transportType?.ProductGroups[0].LoadingPoint.RegionName,
                            district_code: transportType?.ProductGroups[0].LoadingPoint.DistrictCode,
                            district_name: transportType?.ProductGroups[0].LoadingPoint.DistrictName,
                            village_code: transportType?.ProductGroups[0].LoadingPoint.MahallaId,
                            village_name: transportType?.ProductGroups[0].LoadingPoint.MahallaName,
                            street: transportType?.ProductGroups[0].LoadingPoint.Address,
                            longitude: transportType?.ProductGroups[0].LoadingPoint.Latitude,
                            latitude: transportType?.ProductGroups[0].LoadingPoint.Longitude,
                        },
                        unloading_point: {
                            region_code: transportType?.ProductGroups[0].UnloadingPoint.RegionId,
                            region_name: transportType?.ProductGroups[0].UnloadingPoint.RegionName,
                            district_code: transportType?.ProductGroups[0].UnloadingPoint.DistrictCode,
                            district_name: transportType?.ProductGroups[0].UnloadingPoint.DistrictName,
                            village_code: transportType?.ProductGroups[0].UnloadingPoint.MahallaId,
                            village_name: transportType?.ProductGroups[0].UnloadingPoint.MahallaName,
                            street: transportType?.ProductGroups[0].UnloadingPoint.Address,
                            longitude: transportType?.ProductGroups[0].UnloadingPoint.Latitude,
                            latitude: transportType?.ProductGroups[0].UnloadingPoint.Longitude,
                        },
                        products: transportType?.ProductGroups[0].ProductInfo.Products.map(item => {
                            return {
                                name: item.ProductName,
                                catalog_code: item.CatalogCode,
                                catalog_name: item.CatalogName,
                                package_code: item.PackageCode,
                                measurement: item.PackageName,
                                quantity: item.Amount,
                                price: item.Price,
                                total: item.DeliverySum,
                            }
                        })
                    }
                ]
            },
        });
    }

    return (
        <Fragment>
            {!draftWaybill && <SpinnerComponent />}
            {draftWaybill && <WaybillV2 waybill={draftWaybill} content={content} loadWaybill={loadDraftWaybill}/>}
        </Fragment>
    );
};

export default ViewDraftWaybillV2;
