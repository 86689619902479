export const dataTableTranslateColumns = column => {
    switch (column) {
        case "index":
            return "common.datatable.setting.index"
        case "number":
            return "common.datatable.setting.number"
        case "date":
            return "common.datatable.setting.date"
        case "unloading_point":
            return "common.datatable.setting.unloading_point"
        case "expire_date":
            return "common.datatable.setting.expire_date"
        case "created_date":
            return "common.datatable.setting.created_date"
        case "status":
            return "common.datatable.setting.status"
        case "contractor":
            return "common.datatable.setting.contractor"
        case "contract_num":
            return "common.datatable.setting.contract_num"
        case "invoice":
            return "common.datatable.setting.invoice"
        case "branch":
            return "common.datatable.setting.branch"
        case "total_sum":
            return "common.datatable.setting.total_sum"
        case "employees_inn":
            return "common.datatable.setting.employess_inn"
        case "full_name":
            return "common.datatable.setting.full_name"
        case "position":
            return "common.datatable.setting.position"
        case "button_action":
            return "common.datatable.setting.button_action"
        case "branch_code":
            return "common.datatable.setting.branch_code"
        case "branch_name":
            return "common.datatable.setting.branch_name"
        case "customer_employee_code":
            return "edi.order.datatable.header.row.customer_employee_code"
        case "code":
            return "common.datatable.setting.code"
        case "name":
            return "common.datatable.setting.name"
        case "uid":
            return "common.datatable.setting.uid"
        case "sales_profit_by_purchase_price":
            return "warehouse.operation.report.sales_profit_by_purchase_price"
        case "sales_net_price":
            return "warehouse.operation.report.sales"
        case "payments_net_price":
            return "warehouse.operation.report.payments"
        case "payouts_net_price":
            return "warehouse.operation.report.payouts"
        case "refunds_net_price":
            return "warehouse.operation.report.refunds"
        case "purchases_net_price":
            return "warehouse.operation.report.purchases"
        case "returns_net_price":
            return "warehouse.operation.report.returns"
        case "shortage_price":
            return "warehouse.operation.item.common.filter.has_shortage_from_order"
        case "telegram_order_price":
            return "edi.contractor.card.telegram_orders.tab.title"
        case "debts_net_price":
            return "warehouse.operation.item.common.real_credit"
        case "before_debts":
            return "warehouse.operation.report.before_debts"
        default:
            return "common.datatable.setting.default"
    }
}