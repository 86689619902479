import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    selectFilters,
    selectPagination,
    selectReport,
    setFilters,
    setPagination,
    setReport
} from "../../../../app/store/reducers/warehouse-operation/operationItemsReportReducer";
import {debounce} from "underscore";
import {Card} from "react-bootstrap";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import SoftBadge from "../../../common/SoftBadge";
import {
    FindOperationType,
    GetOperationLink,
    Purchase,
    Revision,
    Sale,
    TransferIn,
    TransferOut,
    WriteOff
} from "../../../../enum/OperationTypes";
import numeral from "numeral";
import dayjs from "dayjs";
import DataTableColumnSettings from "../../../common/table/DataTableColumnSettings";
import warehouseOperationService from "../../../../services/warehouse-operation/warehouseOperationService";
import classNames from "classnames";
import OperationItemsReportDataTableHeader from "./OperationItemsReportDataTableHeader";
import {Link} from "react-router-dom";
import {selectItemsDictionary} from "../../../../app/store/reducers/item/itemReducer";
import itemWrapper from "../../item/enum/itemWrapper";
import {selectCurrency, selectNationalCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {
    loadDefaultSettings,
    selectOperationItemReportSettings,
    updateOperationItemReportSettingsAsync
} from "../../../../app/store/reducers/settings/settingsReducer";

const itemReportDatatableColumnOperationNumber = "operation_number"
const itemReportDatatableColumnOperationItemName = "operation_item_name"
const itemReportDatatableColumnOperationItemMeasurement = "operation_item_measurement"
const itemReportDatatableColumnOperationContractorName = "operation_contractor_name"
const itemReportDatatableColumnOperationItemBeforeQuantity = "operation_item_before_quantity"
const itemReportDatatableColumnOperationItemQuantity = "operation_item_quantity"
const itemReportDatatableColumnOperationItemAfterQuantity = "operation_item_after_quantity"
const itemReportDatatableColumnOperationItemPrice = "operation_item_price"
const itemReportDatatableColumnOperationItemDiscountNetPrice = "operation_item_discount_net_price"
const itemReportDatatableColumnOperationItemNetPrice = "operation_item_net_price"
const itemReportDatatableColumnOperationItemNdsRate = "operation_item_nds_rate"
const itemReportDatatableColumnOperationItemNdsSum = "operation_item_nds_sum"
const itemReportDatatableColumnOperationItemTotalWithNds = "operation_item_total_with_nds"
const itemReportDatatableColumnOperationItemWarehouseName = "operation_item_warehouse_name"
const itemReportDatatableColumnOperationDate = "operation_date"

const OperationItemsReportDataTable = () => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const activeOrganization = useSelector(selectActiveOrganization);
    const itemReportSettings = useSelector(selectOperationItemReportSettings);
    const itemReportDatatableSettings = itemReportSettings?.item_report_datatable_column || [];
    const itemsDictionary = useSelector(selectItemsDictionary);
    const filters = useSelector(selectFilters);
    const pagination = useSelector(selectPagination);
    const columnVisibility = Object.fromEntries(itemReportDatatableSettings.map((column) => [column.column, column.active]));
    const report = useSelector(selectReport);
    const currencies = useSelector(selectCurrency);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const [loading, setLoading] = useState(false);

    const onFilterChange = (filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        dispatch(setFilters(filterParams));
        dispatch(setPagination(paginationParams))
        load(filterParams, paginationParams)
    };
    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        dispatch(setFilters(filterParams));
        dispatch(setPagination(paginationParams));
        load(filterParams, paginationParams)
    }, 500), [filters, pagination]);
    const onActiveOrganizationChange = () => {
        load(filters, pagination);
    };
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        const paginationParams = {pageSize: nextState.pageSize, pageIndex: nextState.pageIndex};
        dispatch(setPagination(paginationParams));
        load(filters, paginationParams)
    };
    const onColumnVisibilityChange = async (updater) => {
        const nextState = updater(columnVisibility);

        const newDatatableColumnSettings = [];
        for (let columnKey in nextState) {
            newDatatableColumnSettings.push({column: columnKey, active: nextState[columnKey]});
        }

        let newItemReportSettings = {...itemReportSettings}
        newItemReportSettings.item_report_datatable_column = newDatatableColumnSettings;
        await updateOperationItemReportSettingsAsync(newItemReportSettings)
        dispatch(loadDefaultSettings())
    };

    const load = async (filter, pagination) => {
        try {
            const payload = {};

            // build filters
            {
                if (filter.dateStart)
                    payload["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD HH:mm:ss");

                if (filter.dateEnd)
                    payload["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD HH:mm:ss");

                if (!!filter.types.length)
                    payload["types"] = filter.types;

                if (filter.categoryId && !!filter.itemIds.length)
                    payload["item_ids"] = filter.itemIds;

                if (filter.itemId) {
                    if (payload["item_ids"]) {
                        const item = payload["item_ids"].find(id => id === filter.itemId) || null;
                        if (item)
                            payload["item_ids"] = [filter.itemId];
                        else
                            delete payload.item_ids;
                    } else {
                        payload["item_ids"] = [filter.itemId];
                    }
                }

                if (filter.contractorId)
                    payload["contractor_id"] = filter.contractorId;

                if (filter.employeeId)
                    payload["employee_id"] = filter.employeeId;

                if (filter.operationAccountId)
                    payload["operation_account_id"] = filter.operationAccountId;

                if (filter.warehouseId)
                    payload["warehouse_id"] = filter.warehouseId;
            }

            // build pagination
            {
                payload["limit"] = pagination.pageSize;
                payload["skip"] = pagination.pageIndex * pagination.pageSize;
            }

            setLoading(true);
            const {data: report} = await warehouseOperationService.getOperationItemsReport(payload);
            dispatch(setReport(report));
            setLoading(false);
        } catch (error) {
            console.log(error);
            const report = {
                reports: [],
                operations_total_count: 0,
                purchases_count: 0,
                purchases_net_price: [],
                refunds_count: 0,
                refunds_net_price: [],
                returns_count: 0,
                returns_net_price: [],
                sales_count: 0,
                sales_net_price: [],
                revisions_count: 0,
                transfer_ins_count: 0,
                transfer_outs_count: 0,
                write_offs_count: 0
            }
            dispatch(setReport(report));
            setLoading(false);
        }
    };

    const columns = useMemo(() => {
        return [
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationNumber,
                accessorKey: t(lang, "warehouse_operation.operation_items_report_summary.operation_number"),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, "warehouse_operation.operation_items_report_summary.operation_number")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >
                            <Link to={GetOperationLink(operation.type, operation.id)}>
                                <SoftBadge bg={FindOperationType(operation.type)?.variant}
                                           className="w-100 text-decoration-underline"
                                >
                                    {`${t(lang, FindOperationType(operation.type)?.label)} №: ${operation.number}`}
                                </SoftBadge>
                            </Link>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemName,
                accessorKey: t(lang, "crm.common.items.product"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "400px"
                            }}
                        >
                            {t(lang, "crm.common.items.product")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark fw-semi-bold font-sans-serif"
                            style={{
                                minWidth: "400px"
                            }}
                        >
                            {item.name}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "400px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemMeasurement,
                accessorKey: t(lang, "crm.common.items.product.measurement"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            {t(lang, "crm.common.items.product.measurement")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    const itemInfo = itemsDictionary[item.id];
                    return (
                        <td className="p-1 align-middle text-center text-dark fw-semi-bold font-sans-serif"
                            style={{
                                minWidth: "130px"
                            }}
                        >
                            {itemInfo?.item && t(lang, itemWrapper.getMeasurement(itemInfo?.item?.measurement))}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "130px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationContractorName,
                accessorKey: t(lang, "crm.common.datatable.contractor"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "300px"
                            }}
                        >
                            {t(lang, "crm.common.datatable.contractor")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation: {contractor}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "300px"
                            }}
                        >
                            {contractor?.name || ""}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "300px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemBeforeQuantity,
                accessorKey: t(lang, "warehouse.items.history.datatable.column.before_quantity"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "warehouse.items.history.datatable.column.before_quantity")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <h6 className="text-end">{numeral.formats[numberFormat].format(item.before_quantity)}</h6>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemQuantity,
                accessorKey: t(lang, "warehouse.items.history.datatable.column.quantity"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "warehouse.items.history.datatable.column.quantity")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <h6 className="text-end">{numeral.formats[numberFormat].format(item.quantity)}</h6>
                        </td>
                    );
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalQuantitiesPerOperation = rows.reduce((acc, {
                        original: {
                            operation,
                            operation_item: {item}
                        }
                    }) => {
                        if (acc[operation.type])
                            acc[operation.type] += item.quantity;
                        else
                            acc[operation.type] = item.quantity;
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(totalQuantitiesPerOperation).map((key) => (
                                    <SoftBadge key={key}
                                               bg={FindOperationType(parseInt(key))?.variant}
                                               className="w-100 text-wrap"
                                    >
                                        <span
                                            className="me-2">{t(lang, FindOperationType(parseInt(key))?.label)}:</span>
                                        <span>{numeral.formats[numberFormat].format(totalQuantitiesPerOperation[key])}</span>
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemAfterQuantity,
                accessorKey: t(lang, "warehouse.items.history.datatable.column.after_quantity"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "warehouse.items.history.datatable.column.after_quantity")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <h6 className="text-end">{numeral.formats[numberFormat].format(item.after_quantity)}</h6>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemPrice,
                accessorKey: t(lang, "crm.common.items.product.price"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "crm.common.items.product.price")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {!!item.price && <SoftBadge bg='info'
                                                        key={item.price.currency.id}
                                                        className={classNames("w-100")}
                            >
                                <h6 className="m-0 text-end">
                                    {numeral.formats[numberFormat].format(item.price.amount)}
                                    <small className={'ms-2 fw-bold text-info'}>{item.price.currency.name}</small>
                                </h6>
                            </SoftBadge>}
                        </td>
                    );
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalPricePerOperation = rows.reduce((acc, {
                        original: {
                            operation,
                            operation_item: {item}
                        }
                    }) => {
                        if (operation.type === Revision || operation.type === WriteOff || operation.type === TransferIn || operation.type === TransferOut)
                            return acc;

                        if (acc[operation.type]) {
                            if (acc[operation.type][item.price.currency.name])
                                acc[operation.type][item.price.currency.name] += item.price.amount;
                            else
                                acc[operation.type][item.price.currency.name] = item.price.amount;
                        } else {
                            acc[operation.type] = {};
                            acc[operation.type][item.price.currency.name] = item.price.amount;
                        }

                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(totalPricePerOperation).map((key) => (
                                    <SoftBadge key={key}
                                               bg={FindOperationType(parseInt(key))?.variant}
                                               className="w-100 text-wrap d-flex flex-column gap-2"
                                    >
                                        <div>{t(lang, FindOperationType(parseInt(key))?.label)}:</div>
                                        {Object.keys(totalPricePerOperation[key]).map((currencyName) => (
                                            <div key={currencyName}>
                                                {`${numeral.formats[numberFormat].format(totalPricePerOperation[key][currencyName])} ${currencyName}`}
                                            </div>
                                        ))}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemDiscountNetPrice,
                accessorKey: t(lang, "warehouse.operation.item.common.discount"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "warehouse.operation.item.common.discount")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation, operation_item: {item}}}}) => {
                    if (operation.type === Sale || operation.type === Purchase) {
                        return (
                            <td className="p-1 align-middle text-center text-dark"
                                style={{
                                    width: "180px",
                                    maxWidth: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {!!item.discount && <SoftBadge bg='info'
                                                               key={item.discount.net_price.currency.id}
                                                               className={classNames("w-100")}
                                >
                                    <h6 className="m-0 text-end">
                                        {numeral.formats[numberFormat].format(item.discount.net_price.amount)}
                                        <small
                                            className={'ms-2 fw-bold text-info'}>{item.discount.net_price.currency.name}</small>
                                    </h6>
                                </SoftBadge>}
                            </td>
                        );
                    }

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                        </td>
                    )
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalDiscountPricePerOperation = rows.reduce((acc, {
                        original: {
                            operation,
                            operation_item: {item}
                        }
                    }) => {
                        if (operation.type === Sale || operation.type === Purchase) {
                            if (!!item.discount) {
                                if (acc[operation.type]) {
                                    if (acc[operation.type][item.discount.net_price.currency.name])
                                        acc[operation.type][item.discount.net_price.currency.name] += item.discount.net_price.amount;
                                    else
                                        acc[operation.type][item.discount.net_price.currency.name] = item.discount.net_price.amount;
                                } else {
                                    acc[operation.type] = {};
                                    acc[operation.type][item.discount.net_price.currency.name] = item.discount.net_price.amount;
                                }
                            }
                        }
                        return acc;
                    }, {});
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(totalDiscountPricePerOperation).map((key) => (
                                    <SoftBadge key={key}
                                               bg={FindOperationType(parseInt(key))?.variant}
                                               className="w-100 text-wrap d-flex flex-column gap-2"
                                    >
                                        <div>{t(lang, FindOperationType(parseInt(key))?.label)}:</div>
                                        {Object.keys(totalDiscountPricePerOperation[key]).map((currencyName) => (
                                            <div key={currencyName}>
                                                {`${numeral.formats[numberFormat].format(totalDiscountPricePerOperation[key][currencyName])} ${currencyName}`}
                                            </div>
                                        ))}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemNetPrice,
                accessorKey: t(lang, "crm.common.datatable.total_amount"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "crm.common.datatable.total_amount")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {!!item.net_price && <SoftBadge bg='info'
                                                            key={item.net_price.currency.id}
                                                            className={classNames("w-100")}
                            >
                                <h6 className="m-0 text-end">
                                    {numeral.formats[numberFormat].format(item.net_price.amount)}
                                    <small className={'ms-2 fw-bold text-info'}>{item.net_price.currency.name}</small>
                                </h6>
                            </SoftBadge>}
                        </td>
                    );
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const totalPricePerOperation = rows.reduce((acc, {
                        original: {
                            operation,
                            operation_item: {item}
                        }
                    }) => {
                        if (operation.type === Revision || operation.type === WriteOff || operation.type === TransferIn || operation.type === TransferOut)
                            return acc;

                        if (acc[operation.type]) {
                            if (acc[operation.type][item.net_price.currency.name])
                                acc[operation.type][item.net_price.currency.name] += item.net_price.amount;
                            else
                                acc[operation.type][item.net_price.currency.name] = item.net_price.amount;
                        } else {
                            acc[operation.type] = {};
                            acc[operation.type][item.net_price.currency.name] = item.net_price.amount;
                        }

                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                {Object.keys(totalPricePerOperation).map((key) => (
                                    <SoftBadge key={key}
                                               bg={FindOperationType(parseInt(key))?.variant}
                                               className="w-100 text-wrap d-flex flex-column gap-2"
                                    >
                                        <div>{t(lang, FindOperationType(parseInt(key))?.label)}:</div>
                                        {Object.keys(totalPricePerOperation[key]).map((currencyName) => (
                                            <div key={currencyName}>
                                                {`${numeral.formats[numberFormat].format(totalPricePerOperation[key][currencyName])} ${currencyName}`}
                                            </div>
                                        ))}
                                    </SoftBadge>
                                ))}
                            </div>
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemNdsRate,
                accessorKey: t(lang, "crm.common.datatable.nds_rate"),
                enableSorting: false,
                enableHiding: true,
                header: ({}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "140px",
                                maxWidth: "140px",
                                minWidth: "140px"
                            }}
                        >
                            {t(lang, "crm.common.datatable.nds_rate")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    const itemInfo = itemsDictionary[item.id];
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "140px",
                                maxWidth: "140px",
                                minWidth: "140px"
                            }}
                        >
                            {!itemInfo
                                ? <SoftBadge bg='danger' className={classNames("w-100")}><h6
                                    className="m-0">{t(lang, 'crm.common.status.not_found')}</h6></SoftBadge>
                                : itemInfo?.item?.tax?.tax_rate === null
                                    ? <SoftBadge bg='warning' className={classNames("w-100")}><h6
                                        className="m-0">{t(lang, 'crm.common.status.not_specified')}</h6></SoftBadge>
                                    : <h6 className="m-0">{itemInfo?.item?.tax?.tax_rate}%</h6>
                            }
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "140px"
                            }}
                        >
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemNdsSum,
                accessorKey: t(lang, "crm.common.datatable.nds_sum"),
                enableSorting: false,
                enableHiding: true,
                header: ({}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "crm.common.datatable.nds_sum")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    const itemInfo = itemsDictionary[item.id];
                    const ndsRate = itemInfo?.item?.tax?.tax_rate || 0;
                    const totalInNationalCurrency = item.net_price ? itemWrapper.getCurrencyAmountInNationalCurrency(currencies, item.net_price.amount, item.net_price.currency.id) : 0;
                    const ndsSum = totalInNationalCurrency * ndsRate / 100;
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge bg='info' className={classNames("w-100")}>
                                <h6 className="m-0 text-end">
                                    {numeral['formats'][numberFormat].format(ndsSum)}
                                    <small className={'ms-2 fw-bold text-info'}>{nationalCurrency?.name}</small>
                                </h6>
                            </SoftBadge>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "180px"
                            }}
                        >
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemTotalWithNds,
                accessorKey: t(lang, "crm.common.datatable.total_with_nds"),
                enableSorting: false,
                enableHiding: true,
                header: ({}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            {t(lang, "crm.common.datatable.total_with_nds")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    const itemInfo = itemsDictionary[item.id];
                    const ndsRate = itemInfo?.item?.tax?.tax_rate || 0;
                    const totalInNationalCurrency = item.net_price ? itemWrapper.getCurrencyAmountInNationalCurrency(currencies, item.net_price.amount, item.net_price.currency.id) : 0;
                    const ndsSum = totalInNationalCurrency * ndsRate / 100;
                    const totalWithNds = ndsSum + totalInNationalCurrency;
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "180px",
                                maxWidth: "180px",
                                minWidth: "180px"
                            }}
                        >
                            <SoftBadge bg='info' className={classNames("w-100")}>
                                <h6 className="m-0 text-end">
                                    {numeral['formats'][numberFormat].format(totalWithNds)}
                                    <small className={'ms-2 fw-bold text-info'}>{nationalCurrency?.name}</small>
                                </h6>
                            </SoftBadge>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "180px"
                            }}
                        >
                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationItemWarehouseName,
                accessorKey: t(lang, "warehouse.items.history.datatable.column.warehouse"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, "warehouse.items.history.datatable.column.warehouse")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation_item: {item}}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            {item.warehouse.name}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: itemReportDatatableColumnOperationDate,
                accessorKey: t(lang, "warehouse.items.history.datatable.column.operation_date_time"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, "warehouse.items.history.datatable.column.operation_date_time")}
                        </th>
                    );
                },
                cell: ({row: {original: {operation}}}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {dayjs(operation.date).format(`${dateFormat} HH:mm:ss`)}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                maxWidth: "200px",
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'action',
                accessorKey: t(lang, "notification.table.actions"),
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >
                            <DataTableColumnSettings table={table}/>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center">

                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >

                        </td>
                    )
                },
            },
        ];
    }, [lang, itemsDictionary, pagination]);
    const data = useMemo(() => {
        return report.reports;
    }, [report])
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(report.operations_total_count / pagination.pageSize),
        meta: {
            getHeaderRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getFooterRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            }
        },
        state: {
            pagination: pagination,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        onActiveOrganizationChange();
    }, [activeOrganization]);

    return (
        <Card>
            <Card.Header>
                <OperationItemsReportDataTableHeader report={report}
                                                     loading={loading}
                                                     pagination={pagination}
                                                     filters={filters}
                                                     onFilterChange={onFilterChange}
                                                     onDebouncedFilterChange={onDebouncedFilterChange}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               style: {
                                   borderWidth: "2px 0",
                                   borderColor: "lightGrey"
                               }
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={report.operations_total_count}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default OperationItemsReportDataTable;