import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import IconButton from "../../../common/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import ContractFormationProduct from "./ContractFormationProduct";
import {useFormContext, useWatch} from "react-hook-form";
import React, {useEffect, useState} from "react";
import ConvertQuantityToWord from "../../attorney/ConvertQuantityToWord";
import ContractFormationItemExcelImport from "./ContractFormationItemExcelImport";
import {selectCompanyCatalogs, selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {
    selectLoadContractFormationExcel
} from "../../../../app/store/reducers/contract-formation/contractFormationReducer";


const ContractFormationProducts = ({
                                       controlledFieldsProduct,
                                       updateProduct,
                                       fieldsProduct,
                                       addFieldProduct,
                                       removeFieldProduct,
                                       replaceProducts,
                                       appendProduct,
                                       removeProduct,
                                   }) => {
    const measurements = useSelector(selectMeasurements);
    const [reversedCalculation, setReversedCalculation] = useState(false);
    const catalogs = useSelector(selectCompanyCatalogs);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [excelProducts, setExcelProducts] = useState([]);
    const [limit, setLimit] = useState(20);

    const {setValue} = useFormContext();
    const watchProducts = useWatch({name: 'products'});
    const totalDeliverySum = watchProducts.reduce((total, product) => total + +product.deliverySum, 0)
    const totalVatSum = watchProducts.reduce((total, product) => total + +product.vatSum, 0)
    const totalDeliverySumWithVat = watchProducts.reduce((total, product) => total + +product.deliverySumWithVat, 0)

    useEffect(() => {
        setValue('contractInfo.total_words', ConvertQuantityToWord.getText(totalDeliverySumWithVat))
    }, [totalDeliverySumWithVat])

    useEffect(() => {
        dispatch(selectLoadContractFormationExcel())
    }, [])

    useEffect(() => {
        if (excelProducts.length > 0) {
            const item = excelProducts[0];
            appendProduct(item);

            const leftExcelProducts = [];
            for (let i = 1; i < excelProducts.length; i++) {
                leftExcelProducts.push(excelProducts[i])
            }
            setTimeout(() => {
                setExcelProducts(leftExcelProducts)
            }, 100)
        }
    }, [excelProducts])

    const onSuccessFilled = (products) => {
        replaceProducts([])

        const items = []
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const catalog = product?.catalogCode ? catalogs?.find(c => c?.class_code === product?.catalogCode) : undefined
            let measurementName = product?.measurementOrPackages ? measurements?.find(measure => measure?.name === product?.measurementOrPackages) || null : null
            let packages
            let item = {
                catalogCode: null,
                catalogName: null,
                certificate: null,
                barCode: product?.barcode || "",
                name: product?.name || "",
                measureId: "",
                measureName: "",
                packageCode: null,
                packageName: null,
                catalog: null,
                packages: null,
                measurement: measurementName,
                count: product?.quantity || null,
                summa: product?.price || null,
                deliverySum: null,
                vatRate: "",
                vatSum: null,
                deliverySumWithVat: null,
            }

            if (catalog) {
                packages = catalog?.package_names.find(p => p?.name_ru === product?.measurementOrPackages)
                const packageName = lang === "ru" ? packages?.name_ru : packages?.name_uz

                item.catalog = catalog
                item.catalogCode = catalog?.class_code
                item.catalogName = catalog?.name

                item.packages = packages
                item.packageCode = packages?.code
                item.packageName = packageName
            }
            if (!catalog) {
                item.measureId = measurementName?.measureId
                item.measureName = measurementName?.name
            }
            switch (product?.ndsRate) {
                case "0":
                    item.vatRate = "0";
                    break;
                case "12":
                    item.vatRate = "12";
                    break;
                case "15":
                    item.vatRate = "15";
                    break;
                default:
                    item.vatRate = "";
            }

            items.push(item)
        }

        setExcelProducts(items)
    }

    return (
        <Card>
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div>
                        <h5 className="mb-0">{t(lang, "roaming.contracts.add.products.title")}</h5>
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-2">
                        <Form.Switch id={"reversedCalculation"}
                                     label={t(lang, 'roaming.invoice.reversed_calculation')}
                                     defaultChecked={reversedCalculation}
                                     onChange={(e) => setReversedCalculation(e.target.checked)}
                        />
                        <ContractFormationItemExcelImport onSuccessFilled={onSuccessFilled}/>
                        <IconButton
                            onClick={addFieldProduct}
                            variant="falcon-primary"
                            icon="plus"
                            size="sm"
                            transform="shrink-3"
                            className="my-3"
                        >{t(lang, "roaming.contracts.add.products.add_product")}
                        </IconButton>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Table responsive striped bordered className="border-300">
                            <thead className="bg-200 text-dark">
                            <tr>
                                <th className="p-0 text-center">#</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.product.name")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.catalog_class_code")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.measurement")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.barcode")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.quantity")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.price")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.excise")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.excise_value")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.subtotal")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.nds")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.nds_value")}</th>
                                <th className='p-0 align-middle text-center'>{t(lang, "edi.common.items.product.sum_with_vat_sum")}</th>
                                <th className='p-0 align-middle text-center'></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {fieldsProduct.slice(0, limit).map((product, index) => (
                                <ContractFormationProduct reversedCalculation={reversedCalculation}
                                                          controlledFieldsProduct={controlledFieldsProduct}
                                                          key={product.id}
                                                          product={product}
                                                          index={index}
                                                          removeFieldProduct={removeFieldProduct}
                                />
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <div className="w-100 text-center my-3">
                            <Button disabled={watchProducts.length < limit}
                                    onClick={() => setLimit(limit + 20)}>
                                {t(lang, 'roaming.invoice.mass_add.show_more_btn.title')}
                            </Button>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <Card>
                            <Row className="g-0 justify-content-end">
                                <Col>
                                    <Table borderless size="sm" className="fs--1 text-end">
                                        <tbody>
                                        <tr>
                                            <th className="text-900 w-100">{t(lang, "edi.common.items.product.subtotal")}:</th>
                                            <td className="fw-semi-bold">{totalDeliverySum.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-900 w-100">{t(lang, "edi.common.nds_value")}:</th>
                                            <td className="fw-semi-bold">{totalVatSum.toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-900 w-100">{t(lang, "edi.common.items.product.sum_with_vat_sum")}:</th>
                                            <td className="fw-semi-bold">{totalDeliverySumWithVat.toFixed(2)}</td>
                                        </tr>
                                        <tr className="d-flex justify-content-start w-100">
                                            <th className="text-900">{t(lang, "roaming.contract.items_datatable.amount_in_words_1")}:</th>
                                            <td className="fw-semi-bold">
                                                {ConvertQuantityToWord.getText(totalDeliverySumWithVat)}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ContractFormationProducts
