import numeral from "numeral";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import {useSelector} from "react-redux";
import Flex from "../../../../common/Flex";
import {Card, Form, Table} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import itemWrapper from "../../../item/enum/itemWrapper";
import CommonDeleteModal from "../../../../common/CommonDeleteModal";
import itemDictionaryWrapper from "../../../item/enum/itemDictionary";
import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import InputGroupWithDiscount from "../../components/InputGroupWithDiscount";
import {withDiscount, withoutDiscount} from "../../enum/warehouseOperationWrapper";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import InputGroupWithCurrency from "../../../price/components/InputGroupWithCurrency";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import {selectItems, selectItemsDictionary} from "../../../../../app/store/reducers/item/itemReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectActiveCurrencies, selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";

const CreateTelegramOrderSaleItemsForm = ({totalNetPrice}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const settings = useSelector(selectDefaultSetting);
    const warehouses = useSelector(selectWarehouses);
    const currencies = useSelector(selectCurrency);
    const defaultWarehouse = warehouses[0];
    const lang = useSelector(selectLang);
    const t = Translate;
    const [filter, setFilter] = useState('')

    const {fields: fieldItems, append: appendItem, prepend: prependItem, remove: removeItem} = useFieldArray({name: 'items'});

    const addNewItem = () => {
        const newItem = {
            itemId: null,
            quantity: "",
            name: "",
            price: {
                amount: "",
                currencyId: itemWrapper.getDefaultCurrency(currencies)?.id,
            },
            discount: {
                type: withoutDiscount,
                value: 0,
            },
            total: 0,
            warehouseId: settings?.warehouse?.default_id || defaultWarehouse?.id,
        };
        prependItem(newItem);
    };

    const deleteItem = (itemIndex) => {
        removeItem(itemIndex);
    };

    const filteredItems = useMemo(() => {
        return fieldItems.filter(i => {
            if (filter) {
                const searchLower = filter.trim().toLowerCase();
                const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                const itemLowerName = i?.name?.toLowerCase();
                return filterNameParts.every(fnp => itemLowerName.includes(fnp));
            } else {
                return true;
            }
        });
    }, [fieldItems, filter]);

    return (
        <Card className={'mt-3'}>
            <Card.Header className={'pb-0 d-flex justify-content-between'}>
                <div>
                    <Form.Control
                        name="searchNameAndBarcode"
                        placeholder={t(lang, "warehouse.operation.item.common.filter.search")}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
                <IconButton icon={'plus'} variant={'primary'} size={'sm'}
                            onClick={addNewItem}>{t(lang, 'warehouse.operation.item.common.button.add')}</IconButton>
            </Card.Header>
            <Card.Body className={'px-0'}>
                <Table responsive={true}>
                    <thead className={'bg-soft-primary text-black'}>
                    <tr>
                        <th className={'px-1 text-center'} style={{minWidth: '30px', maxWidth: '30px'}}>#</th>
                        <th className={'px-1 w-100'} style={{minWidth: '250px'}}>{t(lang, "warehouse.operation.item.common.name")}</th>
                        <th className={'px-1'} style={{minWidth: '200px', maxWidth: '200px'}}>{t(lang, "warehouse.operation.item.common.warehouse")}</th>
                        <th className={'px-1'} style={{minWidth: '140px', maxWidth: '140px'}}>{t(lang, "warehouse.operation.item.common.quantity")}</th>
                        <th className={'px-1'} style={{minWidth: '220px', maxWidth: '220px'}}>{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                        <th className={'px-1'} style={{minWidth: '180px', maxWidth: '180px'}}>{t(lang, "warehouse.operation.item.common.discount")}</th>
                        <th className={'px-1'} style={{minWidth: '135px', maxWidth: '135px'}}>{t(lang, "warehouse.operation.item.common.discount_price")}</th>
                        <th className={'px-1'} style={{minWidth: '135px', maxWidth: '135px'}}>{t(lang, "warehouse.operation.item.common.total")}</th>
                        <th className={'px-1 text-end'} style={{minWidth: '60px', maxWidth: '60px'}}/>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredItems.map((fieldItem, index) => (
                        <ItemRow
                            index={fieldItems.findIndex(item => item.id === fieldItem.id)}
                            key={fieldItem.id}
                            fieldItem={fieldItem}
                            onDeleteItem={deleteItem}
                            number={fieldItems.length - index}
                        />
                    ))}
                    </tbody>
                </Table>
                <div className={'text-end pe-3'}>
                    <Flex justifyContent={'end'} alignItems={'center'}>
                        <h5 className={'me-3'}>{t(lang, 'warehouse.operation.item.common.total_sum')}:</h5>
                        <div>
                            {totalNetPrice.map((netPrice, index) => (
                                <h4 key={index} className={'m-1 fw-semi-bold text-dark'}>{numeral['formats'][numberFormat].format(netPrice.amount)} {itemWrapper.getCurrency(currencies, netPrice.currencyId)?.name}</h4>
                            ))}
                        </div>
                    </Flex>
                </div>
            </Card.Body>
        </Card>
    );
};

const ItemRow = memo(({fieldItem, index, onDeleteItem, number, ...props}) => {
    const [discountPrice, setDiscountPrice] = useState(0);
    const [constantPrice, setConstantPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const itemsDictionary = useSelector(selectItemsDictionary);
    const currencies = useSelector(selectActiveCurrencies);
    const items = useSelector(selectItems);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {setValue, formState: {errors}, control, setFocus, clearErrors} = useFormContext();
    const quantityInput = useRef(null);

    const item = useWatch({name: `items.${index}`});
    const itemDiscountPrice = useMemo(() => {
        const discountValue = Number(item?.discount ? item?.discount?.value : 0);
        if (item?.discount?.type === withDiscount) {
            const itemDiscount = (Number(item?.price?.amount) / 100) * discountValue;
            return Number(item?.price?.amount) - itemDiscount;
        } else {
            return Number(item?.price?.amount) - discountValue
        }
    }, [item]);

    useEffect(() => {
        setTotalAmount(itemDiscountPrice * Number(item?.quantity));
        setDiscountPrice(itemDiscountPrice);
    }, [item])

    useEffect(() => {
        setConstantPrice(itemDictionaryWrapper.getItemCommonPriceAmount(itemsDictionary, item.itemId));
    }, [item.itemId])

    const deletePosition = (positionIndex) => {
        onDeleteItem(positionIndex);
    };

    const onChangeItem = (selectedItem, onChangeCallback) => {
        onChangeCallback(selectedItem ? selectedItem.item.id : null);
        setValue(`items.${index}.price`, {
            amount: selectedItem ? selectedItem.price.common_price.amount : 0,
            currencyId: selectedItem ? selectedItem.price.common_price.currency.global_currency_id : itemWrapper.getDefaultCurrency(currencies)?.id,
        });
        setValue(`items.${index}.quantity`, 0);
        setValue(`items.${index}.name`, selectedItem?.item.name);
        setFocus(`items.${index}.quantity`);
        clearErrors(`items.${index}.quantity`);
        // quantityInput?.current?.focus();
    };

    return (
        <tr {...props}>
            <td className={'px-1 text-center'} style={{minWidth: '30px', maxWidth: '30px'}}>{number}</td>
            <td className={'px-1 w-100'} style={{minWidth: '250px'}}>
                <Controller
                    control={control}
                    name={`items.${index}.itemId`}
                    rules={{required: t(lang, 'warehouse.operation.item.common.validation.is_required')}}
                    render={({field, fieldState}) => (
                        <WarehouseOperationItemSelect
                            options={items}
                            isClearable={true}
                            defaultValue={field.value}
                            menuPortalTarget={document.body}
                            warehouseId={fieldItem?.warehouseId}
                            onChange={option => onChangeItem(option, field.onChange)}
                            className={classNames( {'is-invalid border border-danger rounded-2': fieldState.error})}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid" className={'d-block'}>
                    {errors?.items?.[index]?.itemId?.message}
                </Form.Control.Feedback>
            </td>
            <td className={'px-1'} style={{minWidth: '200px', maxWidth: '200px'}}>
                <Controller
                    name={`items.${index}.warehouseId`}
                    rules={{required: t(lang, "warehouse.operation.item.common.validation.is_required")}}
                    render={({field, fieldState}) => {
                        return (
                            <WarehouseOperationWarehouseSelect
                                defaultValue={field?.value}
                                classNamePrefix={'react-select'}
                                menuPortalTarget={document.body}
                                className={classNames({'is-invalid border border-danger rounded-2': fieldState.error})}
                                onChange={(option) => field.onChange(option ? option?.id : null)}
                            />
                        )
                    }}
                />
                <Form.Control.Feedback type="invalid" className={'d-block'}>
                    {errors?.items?.[index]?.warehouseId?.message}
                </Form.Control.Feedback>
            </td>
            <td className={'px-1'} style={{minWidth: '140px', maxWidth: '140px'}}>
                <Controller
                    control={control}
                    name={`items.${index}.quantity`}
                    rules={{
                        required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                        min: {value: 0, message: t(lang, 'warehouse.operation.item.common.validation.min')},
                    }}
                    render={({field, fieldState}) => (
                        <CustomCleave
                            {...field}
                            ref={field.ref}
                            onFocus={event => event.target.select()}
                            htmlRef={(i) => (quantityInput.current = i)}
                            options={{numeral: true, delimiter: ' ', numeralDecimalScale: 4}}
                            onChange={event => {
                                field.onChange(event.target.rawValue.replace('-', ''));
                                clearErrors(`items.${index}.quantity`);
                            }}
                            className={classNames('form-control text-end', {'is-invalid': fieldState.error})}
                        />
                    )}
                />
                <Form.Control.Feedback type="invalid">
                    {errors?.items?.[index]?.quantity?.message}
                </Form.Control.Feedback>
            </td>
            <td className={'px-1'} style={{minWidth: '220px', maxWidth: '220px'}}>
                <Controller
                    control={control}
                    name={`items.${index}.price`}
                    render={({field, fieldState}) => {
                        const {amount, currencyId} = field.value;
                        return (
                            <InputGroupWithCurrency
                                defaultPrice={amount}
                                allowBulkPrice={false}
                                defaultCurrencyId={currencyId}
                                menuPortalTarget={document.body}
                                constantDefaultPrice={constantPrice}
                                className={classNames({'is-invalid': fieldState?.error})}
                                onChangePrice={(priceAmount) => setValue(`items.${index}.price.amount`, priceAmount?.replace('-', ''))}
                                onChangeCurrency={(currency) => setValue(`items.${index}.price.currencyId`, currency ? currency?.id : null)}
                                inputProps={{
                                    options: {numeral: true, delimiter: ' ', numeralDecimalScale: 4},
                                    className: classNames('form-control text-end', {'is-invalid': fieldState?.error}),
                                }}
                            />
                        );
                    }}
                />
            </td>
            <td className={'px-1'} style={{minWidth: '180px', maxWidth: '180px'}}>
                <Controller
                    control={control}
                    name={`items.${index}.discount`}
                    rules={{min: {value: 0, message: t(lang, 'warehouse.operation.item.common.validation.min')}}}
                    render={({field}) => {
                        const {value: discountAmount, type: discountType} = field.value;
                        return (
                            <InputGroupWithDiscount
                                defaultType={discountType}
                                defaultAmount={discountAmount}
                                onChangeDiscount={newValue => field.onChange({...field.value, type: newValue})}
                                onChangeAmount={newValue => field.onChange({...field.value, value: newValue?.toString()?.replace('-', '')})}
                                inputProps={{
                                    className: 'form-control text-end',
                                    onFocus: event => event.target.select(),
                                    placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                    options: {numeral: true, delimiter: ' ', numeralDecimalScale: 4}
                                }}
                            />
                        );
                    }}
                />
            </td>
            <td className={'px-1'} style={{minWidth: '135px', maxWidth: '135px'}}>
                <Cleave disabled={true} value={discountPrice} options={{numeral: true, delimiter: ' ', numeralDecimalScale: 4}} className={'form-control text-end'}/>
            </td>
            <td className={'px-1'} style={{minWidth: '135px', maxWidth: '135px'}}>
                <Cleave disabled={true} value={totalAmount} options={{numeral: true, delimiter: ' ', numeralDecimalScale: 4}} className={'form-control text-end'}/>
            </td>
            <td className={'px-1'} style={{minWidth: '60px', maxWidth: '60px'}}>
                <CommonDeleteModal onDelete={() => deletePosition(index)}>
                    <IconButton icon={'trash'} size={'sm'} variant={'falcon-danger'}/>
                </CommonDeleteModal>
            </td>
        </tr>
    );
});

const CustomCleave = React.forwardRef((props, ref) => (
    <Cleave {...props} htmlRef={ref} />
));

export default CreateTelegramOrderSaleItemsForm;
