import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import ReactSelect from "react-select";
import {
    getCompanyAddressAsync, getWaybillDistrictAsync,
    getWaybillRegionAsync, getWaybillVillageAsync, selectRegion
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../../../app/store/reducers/auth/authReducer";
import {Controller, useFormContext} from "react-hook-form";
import {selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import SelectRegion from "./SelectRegion";
import SelectDistrict from "./SelectDistrict";
import SelectVillage from "./SelectVillage";
import BranchModal from "./BranchModal";
import branchService from "../../../../../../services/branchService";
import SelectBranch from "../../../../../common/SelectBranch";
import useQuery from "../../../invoice/form/useQuery";
import {
    loadSystemOrganizationAddressesAsync, selectSystemOrganizationAddresses
} from "../../../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {UNLOADING_POINT} from "./AdditionalInformationV2";

const AdditionalInformationV2Point = ({pointName, inn, title, customer, isEditing, content, brachCode, ediShipment, organizationAddresses}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const regions = useSelector(selectRegion);
    const dispatch = useDispatch();
    const {register, setValue, getValues, watch, formState: {errors}, control} = useFormContext();

    const [companyAddresses, setCompanyAddresses] = useState([])
    const [companyOrganizationAddresses, setCompanyOrganizationAddresses] = useState(null)
    const [companyAddressesValue, setCompanyAddressesValue] = useState(false)
    const [roamingAddress, setRoamingAddress] = useState(null)
    const [villages, setVillages] = useState([])
    // const [regions, setRegions] = useState([])
    const [districts, setDistricts] = useState([])
    const [roamingBranches, setRoamingBranches] = useState([])

    const values = getValues()
    const getDistrictsAsync = useCallback((reg_code, dist_code_or_name) => {
        getWaybillDistrictAsync(reg_code).then((res) => {
            if (res) {
                setDistricts(res);
                const selectedDistrict = res.find(i => i.code === dist_code_or_name || i.name === dist_code_or_name);
                if (selectedDistrict) {
                    setValue(`${pointName}.district_code`, selectedDistrict.code);
                    setValue(`${pointName}.district_name`, selectedDistrict.name);
                    // if (isEditing) {
                        getVillage(selectedDistrict.soato_code);
                    // }
                }
            }
        });
    }, [pointName, setValue, setDistricts, isEditing]);

    const getVillage = (soato_code) => {
        soato_code && getWaybillVillageAsync(soato_code)
            .then((res) => {
                setVillages(res)
            })
    }

    useEffect(() => {
        if (inn) {
            getCompanyAddressAsync(inn)
                .then((res) => setCompanyAddresses(res))
                .catch(() => setCompanyAddresses([]))
        }

        if (customer) {
            branchService.getContractorBranches(inn)
                .then((res) => {
                    setRoamingBranches(res.data)
                    if (!isEditing) {
                        resetFormPoint()
                    }
                })
                .catch(() => setRoamingBranches([]))
        } else if (!isEditing) {
            resetFormPoint()
        }
    }, [inn]);

    useEffect(() => {
        if (isEditing || content) {
            getDistrictsAsync(values[pointName]?.region_code, values[pointName]?.district_code, values[`${pointName}`]?.village_code)
        }
    }, []);

    useEffect(() => {
        if (roamingAddress) {
            setValue(`${pointName}`, {
                branch_id: roamingAddress.id,
                branch_code: roamingAddress.roaming_branch?.branch_number,
                region_code: roamingAddress.roaming_address?.region_code,
                district_code: roamingAddress.roaming_address?.district_code,
                district_name: roamingAddress.roaming_address?.district_name,
                region_name: roamingAddress.roaming_address?.region_name,
                street: roamingAddress.roaming_address?.street,
                village_code: roamingAddress.roaming_address?.village_code,
                village_name: roamingAddress.roaming_address?.village_name,
                other: roamingAddress.roaming_address?.other,
                longitude: roamingAddress.roaming_address?.longitude,
                latitude: roamingAddress.roaming_address?.latitude,
            })
        }
    }, [roamingAddress]);

    useEffect(() => {
        if (brachCode) {
            setRoamingAddress(roamingBranches.find(i => i.code === brachCode))
        }
    }, [brachCode, roamingBranches]);

    useEffect(() => {
        if  (!isEditing) {
            if (pointName !== UNLOADING_POINT) {
                if (organizationAddresses.length === 1) {
                    if (!companyOrganizationAddresses || companyOrganizationAddresses.id !== organizationAddresses[0].id) {
                        handeChangeCompanyAddress(organizationAddresses[0]);
                    }
                }
            }
        }
    }, [organizationAddresses]);

    const handeChangeCompanyAddress = (option) => {
        if (option) {
            setCompanyOrganizationAddresses(option)
            setCompanyAddressesValue(true)
            getDistrictsAsync(option.region_code, option.district_code)
        } else {
            setCompanyOrganizationAddresses(null)
            setCompanyAddressesValue(false)
            setValue(`${pointName}.district_code`, null)
            setValue(`${pointName}.district_name`, null)
        }
        setValue(`${pointName}`, {
            branch_code: option?.roaming_branch_number || null,
            company_address_id: option?.id || null,
            region_code: option?.region_code || null,
            region_name: option?.region_name || null,
            street: option?.street || null,
            village_code: option?.village_code || null,
            village_name: option?.village_name || null,
            district_code: option ? option.district_code : null,
            district_name: option ? option.district_name : null,
            other: option?.other || null,
            longitude: option?.longitude || null,
            latitude: option?.latitude || null,
        });
    }

    const handleChangeRegion = useCallback((option) => {
        if (option) {
            getDistrictsAsync(option.code, option.name)
        } else {
            setDistricts([])
            setVillages([])
        }
        setValue(`${pointName}`, {
            region_code: option?.code || null,
            region_name: option?.name || null,
            district_code: null,
            district_name: null,
            village: null,
        })
    },[getDistrictsAsync, setValue, pointName])

    const handleChangeDistrict = (option) => {
        if (option) {
            getVillage(option.soato_code)
        } else {
            setVillages([])
        }
        setValue(`${pointName}.district_code`, option?.code || null)
        setValue(`${pointName}.district_name`, option?.name || null)
    }

    const handleChangeVillage = (option) => {
        setValue(`${pointName}.village_code`, option?.code || null)
        setValue(`${pointName}.village_name`, option?.name || null)
    }

    const resetFormPoint = () => {
        setRoamingAddress(null)
        setValue(`${pointName}.branch_id`, null)
        setValue(`${pointName}.branch_code`, null)
        setValue(`${pointName}.region_code`, null)
        setValue(`${pointName}.district_code`, null)
        setValue(`${pointName}.district_name`, null)
        setValue(`${pointName}.region_name`, null)
        setValue(`${pointName}.street`, null)
        setValue(`${pointName}.village_code`, null)
        setValue(`${pointName}.village_name`, null)
        setValue(`${pointName}.other`, null)
        setValue(`${pointName}.longitude`, null)
        setValue(`${pointName}.latitude`, null)
    }

    return (
        <Fragment>
            <Card className={'position-relative'}>
                <Card.Header className={'bg-200'}>
                    <Card.Title className={'text-center fs-0'}>{t(lang, title)}</Card.Title>
                    <Row>
                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.send.search.company.id")}</Form.Label>
                            <ReactSelect
                                isDisabled={!!roamingAddress}
                                isClearable
                                options={organizationAddresses?.length > 0 ? organizationAddresses : companyAddresses}
                                classNamePrefix={!!!roamingAddress && 'react-select'}
                                value={companyOrganizationAddresses}
                                placeholder={t(lang, "roaming.common.select.placeholder")}
                                onChange={(option) => handeChangeCompanyAddress(option)}
                                getOptionLabel={(option) => `${option.region_name} ${option.district_name} ${option.street} ( ${option.contractor_inn} )`}
                                getOptionValue={(option) => option.id}
                                hideSelectedOptions
                            />
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.send.search.branch.id")}</Form.Label>
                            <SelectBranch
                                isDisabled={!!companyAddressesValue}
                                options={customer && roamingBranches}
                                defaultValue={brachCode || roamingAddress?.id || null}
                                onChange={(option) => {
                                    !option ? resetFormPoint() : setRoamingAddress(option)
                                }}
                                roaming_address={true}
                                classNamePrefix={!!!companyAddressesValue && 'react-select'}
                            />
                        </Form.Group>
                    </Row>
                </Card.Header>
                <Card.Body className={'pt-0'}>
                    <Row>
                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>
                                {t(lang, "roaming.waybill.v2.send.region")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            {roamingAddress ?
                                <Form.Control readOnly={true} {...register(`${pointName}.region_name`)} /> :
                                <Controller
                                    name={`${pointName}.region_code`}
                                    rules={{
                                        required: !ediShipment ? t(lang, "roaming.common.forms.validations.is_required") : false
                                    }}
                                    render={({field}) => (
                                        <SelectRegion
                                            isClearable
                                            isDisabled={companyAddressesValue}
                                            options={regions}
                                            defaultRegionId={field.value}
                                            onChange={handleChangeRegion}
                                        />
                                    )}
                                />}
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors[pointName]?.region_code?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>
                                {t(lang, "roaming.waybill.v2.send.district")}
                                <span className="text-danger">*</span>
                            </Form.Label>
                            {roamingAddress ?
                                <Form.Control disabled={true} {...register(`${pointName}.district_name`)} /> :
                                <Controller
                                    name={`${pointName}.district_code`}
                                    rules={{
                                        required: !ediShipment ? t(lang, "roaming.common.forms.validations.is_required") : false
                                    }}
                                    render={({field}) => (
                                        <SelectDistrict
                                            isClearable
                                            isDisabled={companyAddressesValue}
                                            options={districts}
                                            defaultDistrictId={field.value}
                                            onChange={handleChangeDistrict}
                                        />
                                    )}
                                />}
                            <Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                {errors[pointName]?.district_code?.message}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>
                                {t(lang, "roaming.waybill.v2.send.village")}
                                {/*<span className="text-danger">*</span>*/}
                            </Form.Label>
                            {roamingAddress ?
                                <Form.Control readOnly={true} {...register(`${pointName}.village_name`)} /> :
                                <Controller
                                    name={`${pointName}.village_code`}
                                    /*rules={{
                                        required: t(lang, "roaming.common.forms.validations.is_required")
                                    }}*/
                                    render={({field}) => (
                                        <SelectVillage
                                            isClearable
                                            isDisabled={companyAddressesValue}
                                            options={villages}
                                            defaultVillageCode={field.value}
                                            onChange={handleChangeVillage}
                                        />
                                    )}/>}
                                {/*<Form.Control.Feedback type={'invalid'} className={'d-block'}>
                                    {errors[pointName]?.village_code?.message}
                                </Form.Control.Feedback>*/}
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.send.street")}</Form.Label>
                            <Form.Control
                                readOnly={!!roamingAddress || companyAddressesValue}
                                placeholder={t(lang, 'roaming.waybill.v2.send.street')}
                                {...register(`${pointName}.street`)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.send.lan")}</Form.Label>
                            <Form.Control
                                defaultValue={values[pointName]?.longitude}
                                readOnly={!!roamingAddress || companyAddressesValue}
                                placeholder={t(lang, 'roaming.waybill.v2.send.lan')}
                                {...register(`${pointName}.longitude`)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} sm={12} md={6}>
                            <Form.Label>{t(lang, "roaming.waybill.v2.send.lat")}</Form.Label>
                            <Form.Control
                                defaultValue={values[pointName]?.latitude}
                                readOnly={!!roamingAddress || companyAddressesValue}
                                placeholder={t(lang, 'roaming.waybill.v2.send.lat')}
                                {...register(`${pointName}.latitude`)}

                            />
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default React.memo(AdditionalInformationV2Point);
