import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectLang, Translate} from '../../../../app/store/reducers/main/mainReducer';
import ContractForm from '../../../../components/hippo/roaming/contract/ContractForm';
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingContractCreate} from "../../../../enum/Permission/RoamingPermission";
import {useHistory} from "react-router-dom";
import useQuery from "../../../../components/hippo/roaming/invoice/form/useQuery";
import {
    loadCatalogsByCode,
    selectCompany
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";
import {loadContract, loadDraftContract} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import {uniq} from "underscore";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";

const SendContract = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const lang = useSelector(selectLang)
    const t = Translate
    const permission = useSelector(selectAccountRolePermissions)
    const company = useSelector(selectCompany);
    const {contract_id, draft_contract_id, is_edit} = useQuery();
    const activeOrganization = useSelector(selectActiveOrganization);

    const [contract, setContract] = useState(null);

    useEffect(() => {
        if (permission.length && !dispatch(checkPermission(PermissionRoamingContractCreate))) {
            if (history.length > 1) {
                history.push('/roaming/contracts/inbox')
            } else {
                history.push('/')
            }
        }
    }, [permission])

    useEffect(() => {
        if (!contract_id && !draft_contract_id && company) {
            setContract({
                contractId: "",
                hasVat: false,
                owner: {
                    tin: "",
                    name: "",
                    address: "",
                    workPhone: "",
                    mobile: "",
                    fax: "",
                    oked: "",
                    account: "",
                    bankId: "",
                    fizTin: "",
                    fio: "",
                    branchCode: "",
                    branchName: ""
                },
                clients: [
                    {
                        tin: "",
                        name: "",
                        address: "",
                        workPhone: "",
                        mobile: "",
                        fax: "",
                        oked: "",
                        account: "",
                        bankId: "",
                        fizTin: "",
                        fio: "",
                        branchCode: "",
                        branchName: ""
                    }
                ],
                contractDoc: {
                    contractName: "",
                    contractNo: "",
                    contractDate: "",
                    contractExpireDate: "",
                    contractPlace: ""
                },
                products: [
                    {
                        name: "",
                        catalog: null,
                        package: null,
                        barcode: "",
                        measurement: "",
                        quantity: null,
                        price: null,
                        total: null,
                        vat_rate: 0,
                        vat_sum: null,
                        total_with_vat_sum: null,
                        withoutVat: false
                    }
                ],
                parts: []
            })
        }
    }, [contract_id, draft_contract_id, company])

    useEffect(() => {
        if (contract_id) {
            loadContractAsync(contract_id)
        }
        if (draft_contract_id) {
            loadDraftContractAsync(draft_contract_id)
        }
    }, [contract_id, draft_contract_id, is_edit, activeOrganization])

    const loadContractAsync = async (contractId) => {
        const {data: contract} = await loadContract(contractId);
        const unknownCatalogCodes = uniq(contract?.items?.map((item) => item?.catalog?.code));
        const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});

        setContract({
            contractId: is_edit ? contract.id : '',
            hasVat: contract.has_vat,
            owner: {
                tin: contract.executor.inn,
                name: contract.executor.name,
                address: contract.executor.address,
                workPhone: contract.executor.phone,
                mobile: contract.executor.mobile,
                fax: contract.executor.fax,
                oked: contract.executor.oked,
                account: contract.executor.account,
                bankId: contract.executor.bank_id,
                fizTin: contract.executor.fiz_inn,
                fio: contract.executor.fio,
                branchCode: contract.executor.branch?.branch_code,
                branchName: contract.executor.branch?.branch_name
            },
            clients: contract.contractors.map((client) => ({
                tin: client.inn,
                name: client.name,
                address: client.address,
                workPhone: client.phone,
                mobile: client.mobile,
                fax: client.fax,
                oked: client.oked,
                account: client.account,
                bankId: client.bank_id,
                fizTin: client.fiz_inn,
                fio: client.fio,
                branchCode: client.branch?.branch_code,
                branchName: client.branch?.branch_name
            })),
            contractDoc: {
                contractName: contract.info?.name,
                contractNo: contract.info.number,
                contractDate:  contract.info.date !== 'Invalid Date' ? contract.info.date : null,
                contractExpireDate: contract.info.expire_date !== 'Invalid Date' ? contract.info.expire_date : null,
                contractPlace: contract.info.place
            },
            products: contract.items.map((item) => {
                let catalog = null;
                let catalogPackage = null;
                let measurement = null;

                if (item.catalog)
                    catalog = catalogs.find(c => c.class_code === item.catalog?.code);

                if (item.package_code && catalog && catalog.use_package)
                    catalogPackage = catalog.package_names.find(p => p.code === parseInt(item.package_code));

                if (item?.measure_id)
                    measurement = item?.measure_id;


                return {
                    name: item.name,
                    catalog: catalog,
                    package: catalogPackage,
                    barcode: item.barcode,
                    measurement: measurement,
                    quantity: item.quantity,
                    price: item.price,
                    total: item.total,
                    vat_rate: item.vat_rate,
                    vat_sum: item.vat_sum,
                    total_with_vat_sum: item.total_with_vat_sum,
                    withoutVat: item.without_vat
                }
            }),
            parts: contract.parts.map((part) => ({
                ordNo: part.ord_no,
                title: part.title,
                body: part.body
            }))
        })
    }

    const loadDraftContractAsync = async (draftContractId) => {
        const {data: draftContract} = await loadDraftContract(draftContractId);
        const roamingContractJSON = JSON.parse(draftContract.content);
        const unknownCatalogCodes = uniq(roamingContractJSON?.Products?.map((item) => item?.CatalogCode));
        const catalogs = await loadCatalogsByCode({codes: unknownCatalogCodes});

        setContract({
            contractId: is_edit ? roamingContractJSON.ContractId : '',
            hasVat: roamingContractJSON.HasVat,
            owner: {
                tin: roamingContractJSON.Owner.Tin,
                name: roamingContractJSON.Owner.Name,
                address: roamingContractJSON.Owner.Address,
                workPhone: roamingContractJSON.Owner.phone,
                mobile: roamingContractJSON.Owner.Mobile,
                fax: roamingContractJSON.Owner.Fax,
                oked: roamingContractJSON.Owner.Oked,
                account: roamingContractJSON.Owner.Account,
                bankId: roamingContractJSON.Owner.BankId,
                fizTin: roamingContractJSON.Owner.FizTin,
                fio: roamingContractJSON.Owner.Fio,
                branchCode: roamingContractJSON.Owner.BranchCode,
                branchName: roamingContractJSON.Owner.BranchName
            },
            clients: roamingContractJSON.Clients.map((client) => ({
                tin: client.Tin,
                name: client.Name,
                address: client.Address,
                workPhone: client.phone,
                mobile: client.Mobile,
                fax: client.Fax,
                oked: client.Oked,
                account: client.Account,
                bankId: client.BankId,
                fizTin: client.FizTin,
                fio: client.Fio,
                branchCode: client.BranchCode,
                branchName: client.BranchName
            })),
            contractDoc: {
                contractName: roamingContractJSON?.ContractDoc.ContractName,
                contractNo: roamingContractJSON?.ContractDoc.ContractNo,
                contractDate: roamingContractJSON?.ContractDoc.ContractDate !== 'Invalid Date' ? roamingContractJSON?.ContractDoc.ContractDate : null,
                contractExpireDate: roamingContractJSON?.ContractDoc.ContractExpireDate !== 'Invalid Date' ? roamingContractJSON?.ContractDoc.ContractExpireDate : null,
                contractPlace: roamingContractJSON?.ContractDoc.ContractPlace
            },
            products: roamingContractJSON.Products.map((item) => {
                let catalog = null;
                let catalogPackage = null;
                let measurement = null;

                if (item.CatalogCode)
                    catalog = catalogs.find(c => c.class_code === item.CatalogCode);

                if (item.PackageCode && catalog && catalog.use_package)
                    catalogPackage = catalog.package_names.find(p => p.code === parseInt(item.PackageCode));

                if (item?.MeasureId)
                    measurement = item?.MeasureId;

                return {
                    name: item.Name,
                    catalog: catalog,
                    package: catalogPackage,
                    barcode: item.BarCode,
                    measurement: measurement,
                    quantity: item.Count,
                    price: item.Summa,
                    total: item.DeliverySum,
                    vat_rate: item.VatRate,
                    vat_sum: item.VatSum,
                    total_with_vat_sum: item.DeliverySumWithVat
                }
            }),
            parts: roamingContractJSON.Parts.map((part) => ({
                ordNo: part.OrdNo,
                title: part.Title,
                body: part.Body
            }))
        })
    }

    if (!contract) return null;

    return (
        <ContractForm contract={contract} isEdit={is_edit} />
    );
};

export default SendContract;
