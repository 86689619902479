import React, {useEffect} from 'react';
import WaybillV2DataTable from "../../../../components/hippo/roaming/waybill/datatable/WaybillV2DataTable";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {
    changeWaybillsV2FilterOptions,
    loadRoamingWaybillV2Async,
    loadRoamingWaybillV2CountAsync,
    selectWaybillsV2,
    selectWaybillsV2FilterOptions
} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {
    loadSystemOrganizationAddressesAsync
} from "../../../../app/store/reducers/roamingReference/roamingReferenceReducer";

const WaybillsV2 = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const {viewer} = useParams();
    const dispatch = useDispatch();
    const filter = useSelector(selectWaybillsV2FilterOptions);
    const activeOrganization = (useSelector(selectActiveOrganization));
    const viewer_inn_or_pinfl = activeOrganization.pinfl || activeOrganization.inn;
    const waybills = useSelector(selectWaybillsV2);


    useEffect(() => {
        dispatch(loadSystemOrganizationAddressesAsync());
    }, [activeOrganization]);

    const loadWaybills = () => {
        dispatch(loadRoamingWaybillV2Async(viewer, {...filter}));
        dispatch(loadRoamingWaybillV2CountAsync(viewer, {...filter}));
    }

    const onLimitChange = async (limit) => {
        dispatch(changeWaybillsV2FilterOptions({...filter, limit: limit, page: 1}))
    }

    const onPageChange = async (page) => {
        dispatch(changeWaybillsV2FilterOptions({...filter, page: page}))
    }


    return (
        <WaybillV2DataTable
            onLimitChange={onLimitChange}
            onPageChange={onPageChange}
            changeFilterOptionsAsync={changeWaybillsV2FilterOptions}
            waybills={waybills}
            viewer={viewer}
            filter={filter}
            loadWaybills={loadWaybills}
        />
    );
};

export default WaybillsV2;
