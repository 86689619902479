import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import {
    loadCompanyProductCatalogsAsync, loadMeasurementsAsync,
    selectCompanyCatalogs
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
    RoamingWaybillV2TransportTypeRailway,
    RoamingWaybillV2TransportTypeRoadway,
    saveDraftWaybillV2Async,
    sendWaybillV2Async, setShowModal
} from "../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import ErrorMessageAlert from "../../../common/ErrorMessageAlert";
import {Form} from "react-bootstrap";
import WaybillFormV2 from "../../../hippo/roaming/waybill/forms/WaybillFormV2";
import dayjs from "dayjs";
import axios from "axios";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {
    getDate,
    getFirstValidValue,
    RoamingWaybillV2SingleSidedTypeToPhysicalPerson,
    TypeAdditional,
    TypeCorrective,
    TypeDefault
} from "../../../../enum/roaming/WaybillV2Wrapper";

const EditWaybillV2Form = ({waybill}) => {
    const {id:paramId} = useParams()
    const history = useHistory()
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const catalogs = useSelector(selectCompanyCatalogs)
    const alertRef = useRef(null);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const waybillTransportType = waybill?.Roadway ? waybill?.Roadway : waybill?.Railway ? waybill?.Railway : null

    const methods = useForm({
        defaultValues: {
            id: waybill.id,
            executor: {
                inn_or_pinfl: waybill?.Consignor?.TinOrPinfl,
                name: waybill?.Consignor?.Name,
            },
            customer: {
                inn_or_pinfl: waybill?.Consignee?.TinOrPinfl,
                name: waybill?.Consignee?.Name,
                branch: getFirstValidValue(waybill?.Consignee?.BranchCode),
            },
            freight_forwarder: {
                inn_or_pinfl: waybill?.FreightForwarder?.TinOrPinfl,
                name: waybill?.FreightForwarder?.Name,
            },
            carrier: {
                inn_or_pinfl: waybill?.Carrier?.TinOrPinfl,
                name: waybill?.Carrier?.Name,
            },
            responsible_person: {
                inn_or_pinfl: waybill?.ResponsiblePerson?.Pinfl,
                name: waybill?.ResponsiblePerson?.FullName,
            },
            driver: {
                inn_or_pinfl: waybillTransportType?.Driver?.Pinfl,
                name: waybillTransportType?.Driver?.FullName,
            },
            other_car_owners: waybillTransportType?.OtherCarOwners?.length > 0 ? waybillTransportType?.OtherCarOwners?.map(x => {
                return {
                    inn_or_pinfl: x.TinOrPinfl,
                    name: x.Name
                }
            }) : [
                {
                    inn_or_pinfl: '',
                    name: ''
                }
            ],
            other_car_owner: getFirstValidValue(!!waybillTransportType?.OtherCarOwners?.length, false),
            executor_is_freight_forwarder: false,
            customer_is_freight_forwarder: false,
            freight_forwarder_is_carrier: false,
            responsible_person_is_driver: false,
            executor_is_client_employer: false,
            customer_is_client_employer: false,
            waybill_type: waybill?.WaybillLocalType || TypeDefault,
            oldWaybillDoc: {
                oldWaybillId: getFirstValidValue(waybill?.OldWaybillDoc?.OldWaybillLocalId, ''),
                oldWaybillNo: getFirstValidValue(waybill?.OldWaybillDoc?.OldWaybillNo),
                oldWaybillDate: getDate(
                    getFirstValidValue(waybill?.OldWaybillDoc?.OldWaybillDate)
                )
            },
            client_inn_or_pinfl: getFirstValidValue(waybill?.Client?.TinOrPinfl),
            delivery_type: getFirstValidValue(waybill.DeliveryType),
            transport_type: getFirstValidValue(waybill.TransportType),
            total_distance: getFirstValidValue(waybill?.TotalDistance?.toString()),
            delivery_cost: getFirstValidValue(waybill?.DeliveryCost?.toString()),
            total_delivery_cost: getFirstValidValue(waybill?.TotalDistance?.toString()),
            is_single_sided: waybill?.SingleSidedType || false,
            single_sided_type: getFirstValidValue(waybill?.SingleSidedType),
            waybill_info: {
                number: getFirstValidValue(waybill.WaybillDoc.WaybillNo),
                date: getDate(waybill.WaybillDoc.WaybillDate)
            },
            contract_info: {
                number: getFirstValidValue(waybill?.ContractDoc?.ContractNo),
                date: getDate(
                    getFirstValidValue(waybill.ContractDoc?.ContractDate)
                )
            },
            client_contract_info: {
                number: getFirstValidValue(waybill.Client?.ContractNo),
                date: getDate(
                    getFirstValidValue(waybill.Client?.ContractDate)
                )
            },
            payer_contract_info: {
                number: getFirstValidValue(waybill.Payer?.ContractNo),
                date: getDate(
                    getFirstValidValue(waybill.Payer?.ContractDate)
                )
            },
            transport: {
                registration_number: getFirstValidValue(waybillTransportType?.Truck?.RegNo)
            },
            railway: {
                train_carriage_number: getFirstValidValue(waybillTransportType?.TrainCarriageNumber),
                train_direction: getFirstValidValue( waybillTransportType?.TrainDirection)
            },
            loading_point: {
                region_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.RegionId),
                region_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.RegionName),
                district_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.DistrictCode),
                district_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.DistrictName),
                village_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.MahallaId),
                village_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.MahallaName),
                street: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.Address),
                longitude: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.Longitude),
                latitude: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.LoadingPoint.Latitude),
            },
            unloading_point: {
                region_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.RegionId),
                region_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.RegionName),
                district_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.DistrictCode),
                district_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.DistrictName),
                village_code: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.MahallaId),
                village_name: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.MahallaName),
                street: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Address),
                longitude: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Longitude),
                latitude: getFirstValidValue(waybillTransportType?.ProductGroups[0]?.UnloadingPoint.Latitude),
            },
            items: waybillTransportType?.ProductGroups[0].ProductInfo.Products.map(item => {
                return {
                    name: item.ProductName,
                    catalog_code: item.CatalogCode,
                    catalog_name: item.CatalogName,
                    package_code: item.PackageCode,
                    measurement: item.PackageName,
                    quantity: item.Amount || 0,
                    price: item.Price || 0,
                    total: item.DeliverySum || 0,
                }
            })
        }
    })

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const sendWaybill = ({id, hashCode}) => {

        sendWaybillV2Async(currentCertificate, lang, {id, hashCode})
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
                history.push(`/roaming/v2-waybills/outbox`)
                dispatch(setShowModal(false))
            })
            .catch(error => {
                setIsSending(false);
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const saveDraftWaybill = (data) => {
        let res
        if  (data.hasOwnProperty("content")) {
            res = {
                id: data.id,
                content: data.content
            }
        } else {
            res = {
                id: data.id,
                hashCode: data.hashCode
            }
        }

        saveDraftWaybillV2Async(res)
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.save.toast.saved_success'))
                history.push(`/roaming/waybill-v2/draft/${paramId}`)
                dispatch(setShowModal(false))
            })
            .catch(error => {
                setIsSaving(false);
                setErrorMessage(error?.message || null);
                alertRef.current.scrollIntoView();
            })
    }

    const onSend = async (formData, event) => {
        const actionType = event.nativeEvent.submitter.name;

        if (actionType === 'save') {
            setIsSaving(true);
        } else if (actionType === 'send') {
            setIsSending(true);
        }

        const total_distance = typeof formData.total_distance === 'string' ? formData.total_distance.replace(/ /g, '') : formData.total_distance
        const delivery_cost = typeof formData.delivery_cost === 'string' ? formData.delivery_cost.replace(/ /g, '') : formData.delivery_cost
        const total_delivery_cost = typeof formData.total_delivery_cost === 'string' ? formData.total_delivery_cost.replace(/ /g, '') : formData.total_delivery_cost

        const data = {
            id: waybill.id,
            delivery_type: formData.delivery_type,
            transport_type: formData.transport_type,
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            waybill_info: formData.waybill_info.number ? {
                number: formData.waybill_info.number,
                date: dayjs(formData.waybill_info.date).format('YYYY-MM-DD')
            } : null,
            contract_info: formData.contract_info.number ? {
                number: formData.contract_info.number || null,
                date: formData.contract_info?.date ? dayjs(formData.contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            customer_is_freight_forwarder: formData.customer_is_freight_forwarder,
            executor_is_freight_forwarder: formData.executor_is_freight_forwarder,
            freight_forwarder_is_carrier: formData.freight_forwarder_is_carrier,
            type: +formData.waybill_type,
            responsible_person: {
                inn_or_pinfl: formData.responsible_person.inn_or_pinfl,
                name:formData.responsible_person.name
            },
            responsible_person_is_driver: formData.responsible_person_is_driver,
            other_car_owner: formData.other_car_owner,
            loading_point: {
                company_address_id: formData.loading_point.company_address_id,
                branch_id: formData.loading_point.branch_id,
                branch_code: formData.loading_point.branch_code,
                region_code: formData.loading_point.region_code,
                region_name: formData.loading_point.region_name,
                district_code: formData.loading_point.district_code,
                district_name: formData.loading_point.district_name,
                village_code: formData.loading_point.village_code,
                village_name: formData.loading_point.village_name,
                street: formData.loading_point.street,
                longitude: +formData.loading_point.longitude,
                latitude: +formData.loading_point.latitude,
            },
            unloading_point: {
                company_address_id: formData.unloading_point.company_address_id,
                branch_id: formData.unloading_point.branch_id,
                branch_code: formData.unloading_point.branch_code,
                region_code: formData.unloading_point.region_code,
                region_name: formData.unloading_point.region_name,
                district_code: formData.unloading_point.district_code,
                district_name: formData.unloading_point.district_name,
                village_code: formData.unloading_point.village_code,
                village_name: formData.unloading_point.village_name,
                street: formData.unloading_point.street,
                longitude: +formData.unloading_point.longitude,
                latitude: +formData.unloading_point.latitude,
            },
            executor_is_client_employer: formData.client_inn_or_pinfl === formData.executor.inn_or_pinfl,
            customer_is_client_employer: formData.client_inn_or_pinfl === formData.customer.inn_or_pinfl,
            client_contract_info: formData?.client_contract_info?.number ? {
                number: formData.client_contract_info?.number || null,
                date: formData.client_contract_info?.date ? dayjs(formData.client_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            payer_contract_info: formData?.payer_contract_info?.number ? {
                number: formData.payer_contract_info?.number || null,
                date: formData.payer_contract_info?.date ? dayjs(formData.payer_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            items: formData.items.map(x => {return {
                name: x.name,
                catalog_code: x.catalog_code,
                package_code: x.package_code?.code,
                measurement: x.package_code?.code?.toString() || x.package_code?.toString() || x.measurement,  // package code/package name,
                quantity: +x.quantity,
                price: +x.price,
                total: +x.total
            }})
        }

        if (data.delivery_type === 2) {
            data['contract_info'] = {
                number: formData.contract_info.number,
                date: dayjs(formData.contract_info.date).format('YYYY-MM-DD')
            }
            data['customer'] = {
                inn_or_pinfl: formData.customer.inn_or_pinfl,
                name: formData.customer.name,
                branch_code: formData.customer?.branch?.code || null,
                branch_name: formData.customer?.branch?.name || null
            }
        }

        if (!data.customer_is_freight_forwarder && !data.executor_is_freight_forwarder) {
            data['freight_forwarder'] = {
                inn_or_pinfl: formData.freight_forwarder.inn_or_pinfl,
                name:formData.freight_forwarder.name
            }
        }

        if (!data.freight_forwarder_is_carrier) {
            data['carrier'] = {
                inn_or_pinfl: formData.carrier.inn_or_pinfl,
                name:formData.carrier.name
            }
        }

        if (!data.responsible_person_is_driver) {
            data['driver'] = {
                inn_or_pinfl: formData.driver.inn_or_pinfl,
                name:formData.driver.name
            }
        }

        if (data.type === TypeAdditional || data.type === TypeCorrective) {
            data['old_waybill'] = {
                id: formData.oldWaybillDoc?.oldWaybillId,
                number: formData.oldWaybillDoc?.oldWaybillNo,
                date: dayjs(formData.oldWaybillDoc?.oldWaybillDate).format("YYYY-MM-DD")
            }
        }

        if (data.transport_type === RoamingWaybillV2TransportTypeRoadway) {
            data['transport'] = {
                registration_number: formData.transport?.registration_number?.registration_number || formData.transport?.registration_number,
            }
        }

        if (data.transport_type === RoamingWaybillV2TransportTypeRailway) {
            data['train_carriage_number'] = formData.railway?.train_carriage_number
            data['train_direction'] = formData.railway?.train_direction
        }

        if (formData.is_single_sided) {
            delete data['customer']
            data.single_sided_type = formData.single_sided_type
        }

        if (data.other_car_owner) {
            data['other_car_owners'] = formData.other_car_owners.map(x => {
                return {
                    inn_or_pinfl: x.inn_or_pinfl,
                    name: x.name
                }
            })
        }

        const response = await axios.post('/document-generator/roaming/waybill-v2/generate/send-hash-code', data)

        if (actionType === 'save') {
            await saveDraftWaybill({
                id: response.data?.id,
                content: response.data?.hashcode
            })
        } else if (actionType === 'send') {
            await sendWaybill({
                id: response.data?.id,
                hashCode: response.data?.hashcode,
            });
        }
    }

    return (
        <FormProvider {...methods}>
            {errorMessage && <ErrorMessageAlert ref={alertRef} errorMessage={errorMessage}/>}
            <Form onSubmit={methods.handleSubmit(onSend)}>
                <WaybillFormV2 isEditing={true} isSending={isSending} isSaving={isSaving} />
            </Form>
        </FormProvider>
    );
};

export default EditWaybillV2Form;
