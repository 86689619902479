import Select from "react-select";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import SoftBadge from "../../../common/SoftBadge";
import {useEffect, useState} from "react";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import {measurements} from "../../item/enum/itemWrapper";


const PackageTypeItem = ({itemId, onChange, defaultValue, options, ...selectProps}) => {
    const [packageMeasurement, setPackageMeasurement] = useState(null)
    const [value, setValue] = useState(null)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate


    useEffect(() => {
        if (itemId && items) {
            const pieceMeasurement = measurements?.find(m => +m?.value === items?.find(item => item?.item?.id === itemId)?.item?.measurement)

            setPackageMeasurement(items?.find(item => item?.item?.id === itemId)?.item?.package_measurements.concat({name: pieceMeasurement?.label, quantity: 1}))
        } else {
            setPackageMeasurement(null)
        }
    },[itemId, items])

    useEffect(() => {
        if (packageMeasurement?.length > 0) {
            onChange(packageMeasurement[packageMeasurement?.length - 1])
            setValue(packageMeasurement[packageMeasurement?.length - 1]?.name)
        } else {
            onChange({name: "piece", quantity: 1})
        }
    },[itemId, packageMeasurement])



    const onChangeHandler = (option) => {
        onChange(option)
        setValue(option?.name)
    }

    const formatOptionLabel = (option) => {
        return(
            <div className="d-flex fw-bold flex-nowrap">
                {t(lang, option?.name)}
                <SoftBadge bg="info" className="d-flex justify-content-center align-items-center ms-2" pill>
                    {option?.quantity}
                </SoftBadge>
            </div>
        )
    }


    return(
        <>
            {packageMeasurement?.length > 1 &&
                <Select
                    value={packageMeasurement?.find(o => o?.name === value)}
                    // defaultValue={packageMeasurement[packageMeasurement?.length - 1]}
                    options={packageMeasurement}
                    onChange={(option) => onChangeHandler(option)}
                    isSearchable={false}
                    hideSelectedOptions
                    menuPlacement="auto"
                    getOptionLabel={option => t(lang, option?.name)}
                    getOptionValue={option => option?.name}
                    formatOptionLabel={formatOptionLabel}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            border: 'none',
                            boxShadow: 'none',
                            borderRadius: '0',
                            backgroundColor: '#0d6efd',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: '#FCFFFFFE',
                            minWidth: "120px",
                            maxWidth: "150px"
                        }),
                        menu: (provided) => ({
                            ...provided,
                            marginTop: 0,
                            boxShadow: 'none',
                            borderRadius: '0',
                        }),
                    }}
                    {...selectProps}
                />
            }
            {packageMeasurement?.length === 1 &&
                <span className="input-group-text">
                    {t(lang, measurements?.find(m => m?.label === packageMeasurement[packageMeasurement?.length - 1]?.name)?.label)}
                </span>
            }
        </>
    )
}

export default PackageTypeItem