import React, {useEffect} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {CertificatePfx} from "../../../../app/plugins/eimzo";
import PropTypes from "prop-types";
import UsernameInputForm from "../../UsernameInputForm";
import {getInnExistsAsync} from "../../../../app/store/reducers/auth/authReducer";
import SelectCertificate from "../../eimzo/SelectCertificate";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import Cleave from "cleave.js/react";

const RegistrationFromStepOne = ({hasLabel,withoutInn}) => {
    const {register, setValue, watch, formState: {errors}, control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;

    const onCertificateChanged = certificate => {
        if (certificate) {
            const pfxCertificate = new CertificatePfx(certificate)

            setValue('company.certificate', certificate);
            setValue('company.name', `${pfxCertificate.companyName || pfxCertificate.issuedPerson}`.toUpperCase());
            setValue('company.inn', pfxCertificate.innNumber || pfxCertificate.pinfl);
            setValue('account.name', `${pfxCertificate.issuedPerson}`.toUpperCase())
        }
    };

    const validateInnExist = async inn => {
        return await getInnExistsAsync(inn);
    }

    useEffect(() => {
       if (!withoutInn) {
           register('company.certificate', {
               required: t(lang, 'edi.common.forms.validations.is_required')
           })
       }
    }, []);

    return (
        <>
            {
                !withoutInn && <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Certificate</Form.Label>}
                    <SelectCertificate onChange={onCertificateChanged}
                                       placeholder={!hasLabel ? t(lang, 'authorization.select_certificate.placeholder') : ''}
                                       className={classNames({'is-invalid': errors.company?.certificate})}
                    />

                    <Form.Control.Feedback type='invalid'>{errors.company?.certificate?.message}</Form.Control.Feedback>
                </Form.Group>
            }

            <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Company Name</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t(lang, 'authorization.form.enter_company_name.placeholder') : ''}
                    isInvalid={errors.company?.name}
                    name="name"
                    type="text"
                    {...register('company.name', {
                        required: t(lang, 'edi.common.forms.validations.is_required')
                    })}
                />
                <Form.Control.Feedback type='invalid'>{errors.company?.name && errors.company.name.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Control
                    type="text"
                    placeholder={t(lang,"authorization.register.full_name")}
                    isInvalid={errors.account?.name}
                    {...register('account.name', {
                        required: t(lang, 'edi.common.forms.validations.is_required')
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.account?.name && errors.account?.name.message}
                </Form.Control.Feedback>
            </Form.Group>

            {!withoutInn && <Form.Group className="my-3">
                {hasLabel && <Form.Label>Company Inn</Form.Label>}
                <Form.Control
                    placeholder={!hasLabel ? t(lang, 'authorization.form.enter_company_inn.placeholder') : ''}
                    isInvalid={errors.company?.inn}
                    readOnly={true}
                    type="text"
                    {...register('company.inn', {
                        required: t(lang, 'edi.common.forms.validations.is_required'),
                        pattern: /^(\d{9})|(\d{14})$/,
                        validate: {
                            exist: async value => {
                                if (await validateInnExist(value))
                                    return t(lang, 'authorization.form.inn_exists');

                                return true;
                            }
                        }
                    })}
                />
                <Form.Control.Feedback
                    type='invalid'>{errors.company?.inn && errors.company.inn.message}</Form.Control.Feedback>
            </Form.Group>}

            <Form.Group className={`${withoutInn ? "my-3" : "mb-3"}`}>
                <div className="input-group">
                    <div className="input-group-prepend"><span className="input-group-text">+998</span></div>
                    <Controller control={control} name={'account.username'} rules={{
                        required: t(lang, "edi.common.forms.validations.is_required")
                    }} render={({field}) => (
                        <Cleave
                            placeholder={"-- --- -- --"}
                            options={{
                                delimiters: [' ', ' ', ' ', ' '],
                                blocks: [2, 3, 2, 2],
                                numericOnly: true
                            }}
                            onChange={(username) => field.onChange(username)}
                            className={classNames('form-control', {
                                'is-invalid': errors.account?.username,
                            })}
                        />
                    )}/>
                    <Form.Control.Feedback type="invalid">{errors.account?.username?.message}</Form.Control.Feedback>
                </div>
            </Form.Group>

            <Row className="g-2 mb-3">
                <Form.Group as={Col} sm={6}>
                    {hasLabel && <Form.Label>Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? t(lang, 'authorization.form.password.placeholder') : ''}
                        isInvalid={errors.account?.password}
                        type="password"
                        {...register('account.password', {
                            required: t(lang, 'edi.common.forms.validations.is_required')
                        })}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.account?.password && errors.account.password.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6}>
                    {hasLabel && <Form.Label>Confirm Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? t(lang, 'authorization.form.repeat_password.placeholder') : ''}
                        isInvalid={errors.account?.confirmPassword}
                        type="password"
                        {...register('account.confirmPassword', {
                            validate: value => value === watch('account.password') || 'Enter exact the same password'
                        })}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.account?.confirmPassword && errors.account.confirmPassword.message}</Form.Control.Feedback>
                </Form.Group>
            </Row>

            {withoutInn && (
                <Form.Group as={Col} sm={12}>
                    <Form.Control
                        type={'text'}
                        isInvalid={errors?.referralAgentCode?.message}
                        placeholder={!hasLabel ? t(lang, 'authentication.register.agent.code') : ''}
                        {...register('referralAgentCode', {
                            required: t(lang, 'edi.common.forms.validations.is_required')
                        })}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.referralAgentCode?.message}</Form.Control.Feedback>
                </Form.Group>
            )}

            <Form.Group className="my-3">
                <Form.Check type="checkbox" className="form-check">
                    <Form.Check.Input
                        type="checkbox"
                        isInvalid={errors.terms}
                        value={true}
                        feedback="Terms must be agreed to continue"
                        {...register('terms', {
                            required: t(lang, 'authorization.form.terms_must_be_agreed')
                        })}
                    />
                    <Form.Check.Label className="form-label">
                        Я соглашаюсь с <a href="/free-form-document/system-offer/pdf" target="_blank">условием</a> оферты .
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>
        </>
    )
};

RegistrationFromStepOne.propTypes = {
    hasLabel: PropTypes.bool
};

RegistrationFromStepOne.defaultValue = {
    hasLabel: false
};

export default RegistrationFromStepOne;
