import {useParams} from "react-router"
import RoamingVerificationActDataTable from "../../../../components/hippo/roaming/verification-act/RoamingVerificationActDataTable"
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingVerificationActView} from "../../../../enum/Permission/RoamingPermission";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import {selectAccountRolePermissions} from "../../../../app/store/reducers/role/roleReducer";
import {GetViewerIcon, TypeViewers, VIEW_PERSON} from "../../../../enum/TypeViewers";
import {Link} from "react-router-dom";
import classNames from "classnames";
import Flex from "../../../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const VerificationActs = () => {
    const {viewer} = useParams()
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const viewers = TypeViewers.filter(v => v.id !== VIEW_PERSON);
    //important
    useSelector(selectAccountRolePermissions)


    if (!dispatch(checkPermission(PermissionRoamingVerificationActView))) {
        return <Card className="text-center p-3">
            <h4>{t(lang, 'system.permission.roaming.verification-act.datatable.no_access.title')}</h4>
        </Card>
    }

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <Row>
                    {viewers.map((v, index) => (
                        <Col key={index} xs={4}>
                            <Link to={`/roaming/verification-acts/${v.id}`} className="text-decoration-none">
                                <Card
                                    className={classNames("p-3 cursor-pointer", {'bg-primary text-white': v.id === viewer})}>
                                    <Flex justifyContent="center" alignItems="center">
                                        <FontAwesomeIcon color="primary"
                                                         icon={GetViewerIcon(v.id)}
                                                         className={classNames("fs-2 me-lg-2")}
                                        />
                                        <Card.Title className={classNames("d-none d-lg-block mb-0", {
                                            'text-white': v.id === viewer,
                                            'text-primary': v.id !== viewer
                                        })}
                                        >{t(lang, v.name)}
                                        </Card.Title>
                                    </Flex>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col xs={12}>
                <RoamingVerificationActDataTable viewer={viewer}/>
            </Col>
        </Row>
    )
}

export default VerificationActs
