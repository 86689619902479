import React, {useState} from 'react'
import {deleteContractorPaymentAsync} from '../../../../../app/store/reducers/contractor/contractorPaymentReducer';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../../common/FalconCloseButton";
import {deleteSalePaymentAsync} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";

const DeleteContractorPaymentModal = ({id, saleId, onClose}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);
    const onConfirm = () => {
        setLoading(true);
        if (saleId) {
            deleteSalePaymentAsync(saleId)
                .then(() => onClose())
                .catch(error => console.log(error))
                .finally(()=>{setLoading(false);})
        } else {
            deleteContractorPaymentAsync({id: id})
                .then(() => onClose())
                .catch(error => console.log(error))
                .finally(()=>{setLoading(false);})
        }
    }
    return (
        <Modal
            show={true}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>{t(lang, 'edi.common.delete_button')}</Modal.Title>
                <FalconCloseButton onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>{t(lang, 'edi.common.delete_button')}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t(lang, 'edi.common.button.close')}
                </Button>
                <Button onClick={onConfirm} disabled={loading}>
                    {loading &&
                    <Spinner className="align-middle me-2" animation="border" role="switch"
                             size="sm"/>}
                    {t(lang, 'edi.common.delete_button')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteContractorPaymentModal