import React, {Fragment, useEffect, useRef, useState} from "react"
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux"
import {selectLang, Translate, updateShowInProgressAsync} from "../../../../app/store/reducers/main/mainReducer"
import {
    loadCompanyProductCatalogsAsync,
    selectMeasurements
} from "../../../../app/store/reducers/roaming/roamingReducer";
import {
    generateRoamingContractSendHashCode,
    save,
    send
} from "../../../../app/store/reducers/roaming/roamingContractReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer"
import {selectCurrentCertificate} from "../../../../app/store/reducers/eimzo/eimzoReducer";
import dayjs from "dayjs";
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import InProgress from "../../../common/InProgress";
import SendContractForm from "./SendContractForm";
import WarningAlert from "../components/WarningAlert";


const ContractForm = ({contract, isEdit}) => {

    const methods = useForm({
        defaultValues: {
            contractId: isEdit ? contract.contractId : null,
            hasVat: false,
            owner: {
                tin: contract.tin,
                name: contract.name,
                address: contract.address,
                workPhone: contract.workPhone,
                mobile: contract.mobile,
                fax: contract.fax,
                oked: contract.oked,
                account: contract.account,
                bankId: contract.bankId,
                fizTin: contract.fizTin,
                fio: contract.fio,
                branchCode: contract.branchCode,
                branchName: contract.branchName
            },
            clients: contract?.clients.map(client => ({
                tin: client.tin,
                name: client.name,
                address: client.address,
                workPhone: client.workPhone,
                mobile: client.mobile,
                fax: client.fax,
                oked: client.oked,
                account: client.account,
                bankId: client.bankId,
                fizTin: client.fizTin,
                fio: client.fio,
                branchCode: client.branchCode,
                branchName: client.branchName
            })) || [
                {
                    tin: "",
                    name: "",
                    address: "",
                    workPhone: "",
                    mobile: "",
                    fax: "",
                    oked: "",
                    account: "",
                    bankId: "",
                    fizTin: "",
                    fio: "",
                    branchCode: "",
                    branchName: ""
                }
            ],
            contractDoc: {
                contractName: contract?.contractDoc?.contractName || null,
                contractNo: contract?.contractDoc?.contractNo || null,
                contractDate: contract?.contractDoc?.contractDate ? dayjs(contract?.contractDoc?.contractDate).toDate() : null,
                contractExpireDate: contract?.contractDoc?.contractExpireDate ? dayjs(contract?.contractDoc?.contractExpireDate).toDate() : null,
                contractPlace: contract?.contractDoc?.contractPlace || null
            },
            products: contract.products.map(item => ({
                name: item.name,
                catalog: item.catalog,
                package: item.package,
                barcode: item.barcode,
                measurement: item.measurement,
                quantity: item.quantity,
                price: item.price,
                total: item.total,
                vat_rate: item.vat_rate,
                vat_sum: item.vat_sum,
                total_with_vat_sum: item.total_with_vat_sum,
                withoutVat: item.withoutVat
            })) || [
                {
                    name: "",
                    catalog: null,
                    package: null,
                    barcode: "",
                    measurement: "",
                    quantity: null,
                    price: null,
                    total: null,
                    vat_rate: 0,
                    vat_sum: null,
                    total_with_vat_sum: null,
                    withoutVat: false
                }
            ],
            parts: contract?.parts.map(part => ({
                ordNo: part.ordNo,
                title: part.title,
                body: part.body
            })) || []
        }
    });

    const activeOrganization = useSelector(selectActiveOrganization)
    const currentCertificate = useSelector(selectCurrentCertificate);
    const lang = useSelector(selectLang)
    const history = useHistory()
    const dispatch = useDispatch()
    const t = Translate

    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onSend = async (formData) => {
        try {
            setErrorMessage(null);
            setIsSending(true);

            dispatch(updateShowInProgressAsync(true));
            const roamingContractSendHashCode = await getDocumentGeneratorHashcode(formData);
            const {data: contract} = await send(currentCertificate, lang, roamingContractSendHashCode)
            setIsSending(false);
            dispatch(updateShowInProgressAsync(false));
            toast.success(t(lang, "common.toast.success"));
            history.push(`/roaming/contract/${contract.id}`)
        } catch (error) {
            setIsSending(false);
            dispatch(updateShowInProgressAsync(false));
            setErrorMessage({
                variant: 'danger',
                title: t(lang, 'roaming.contract.alert.failed_event_title'),
                text: error.message
            });
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }
    }

    const onSave = async (formData) => {
        try {
            setErrorMessage(null);
            setIsSaving(true);

            dispatch(updateShowInProgressAsync(true));
            const {hashcode} = await getDocumentGeneratorHashcode(formData);
            const draftContract = await save({content: hashcode});
            setIsSaving(false);
            dispatch(updateShowInProgressAsync(false));
            toast.success(t(lang, "common.toast.success"));
            history.push(`/roaming/contract/draft/${draftContract.id}`)
        } catch (error) {
            setIsSaving(false);
            dispatch(updateShowInProgressAsync(false));
            setErrorMessage({
                variant: 'danger',
                title: t(lang, 'roaming.contract.alert.failed_event_title'),
                text: error.message
            });
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }
    }

    const getDocumentGeneratorHashcode = async (data) => {
        try {
            const documentGeneratorJson = {
                contract: {
                    name: data.contractDoc.contractName,
                    number: data.contractDoc.contractNo,
                    date: data?.contractDoc?.contractDate ? dayjs(data?.contractDoc?.contractDate).format('YYYY-MM-DD') : null,
                    expire_date: data?.contractDoc?.contractExpireDate ? dayjs(data?.contractDoc?.contractExpireDate).format('YYYY-MM-DD') : null,
                    place: data.contractDoc.contractPlace
                },
                owner: {
                    inn: data.owner.tin,
                    name: data.owner.name,
                    address: data.owner.address,
                    work_phone: data.owner.workPhone,
                    mobile: data.owner.mobile,
                    fax: data.owner.fax,
                    oked: data.owner.oked,
                    bank_account: data.owner.account,
                    bank_mfo: data.owner.bankId,
                    person_inn: data.owner.fizTin,
                    person_name: data.owner.fio,
                    branch_code: data.owner.branchCode,
                    branch_name: data.owner.branchName
                },
                clients: data?.clients?.map(client => ({
                    inn: client.tin,
                    name: client.name,
                    address: client.address,
                    work_phone: client.workPhone,
                    mobile: client.mobile,
                    fax: client.fax,
                    oked: client.oked,
                    bank_account: client.account,
                    bank_mfo: client.bankId,
                    person_inn: client.fizTin,
                    person_name: client.fio,
                    branch_code: client.branchCode,
                    branch_name: client.branchName
                })),
                parts: data.parts.map(part => ({
                    title: part.title,
                    body: part.body
                })),
                items: data.products.map(product => ({
                    name: product.name,
                    catalog_code: product.catalog?.class_code,
                    catalog_name: product.catalog?.catalog_name,
                    measurement: product.package?.code?.toString() || product.measurement?.measureId || product.measurement,
                    barcode: product.barcode,
                    quantity: +product.quantity,
                    price: +product.price,
                    nds_rate: +product.vat_rate,
                    nds_value: +product.vat_sum,
                    total_with_nds: +product?.total_with_delivery_sum,
                    total: +product.total
                }))
            }

            if (isEdit) {
                documentGeneratorJson.contract_id = data.contractId
            }

            return await generateRoamingContractSendHashCode(documentGeneratorJson);
        } catch (error) {
            throw error;
        }
    }


    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization.inn, lang))
    }, [activeOrganization.inn]);

    return (
        <Fragment>
            <InProgress/>
            {errorMessage && <WarningAlert text={errorMessage.text} title={errorMessage.title} variant={errorMessage.variant}/>}
            <FormProvider {...methods}>
                    <SendContractForm
                        onSend={methods.handleSubmit(onSend)}
                        onSave={methods.handleSubmit(onSave)}
                        isSending={isSending}
                        isSaving={isSaving}
                    />
            </FormProvider>
        </Fragment>
    )
}

export default ContractForm
