import axios from "axios";

class ReportService {
    getPeriodReport(params) {
        return axios.get(`/report/report/get-period-report`, {
            params: params
        })
    }

    getBusinessIndicatorReport(params) {
        return axios.get(`/report/report/get-business-indicator-report`, {
            params: params
        })
    }
}

const reportService = new ReportService()

export default reportService;