import dayjs from "dayjs";
import numeral from "numeral";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Form} from "react-bootstrap";
import itemWrapper from "../../../item/enum/itemWrapper";
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import WOWIthDiscountPayment from "../../components/WOWIthDiscountPayment";
import {OrderTypeTelegramOrder} from "../../../../../enum/order/order-types";
import CreateTelegramOrderSaleItemsForm from "./CreateTelegramOrderSaleItemsForm";
import {withDiscount, withoutDiscount} from "../../enum/warehouseOperationWrapper";
import CreateTelegramOrderSaleDetailsForm from "./CreateTelegramOrderSaleDetailsForm";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../app/store/reducers/settings/settingsReducer";
import WarehouseOperationWithoutPayment from "../../components/WarehouseOperationWithoutPayment";
import {
    saleRegisterAsync,
    selectSaleDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";
import {
    selectLang,
    selectNumberFormat,
    Translate,
    updateShowInProgressAsync
} from "../../../../../app/store/reducers/main/mainReducer";
import {selectCurrency, selectNationalCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectItemsDictionary} from "../../../../../app/store/reducers/item/itemReducer";
import InProgress from "../../../../common/InProgress";

const CreateTelegramOrderSaleForm = ({order, botConfiguration}) => {
    const [isLoading, setIsLoading] = useState(false);
    const nationalCurrency = useSelector(selectNationalCurrency);
    const numberFormat = useSelector(selectNumberFormat);
    const settings = useSelector(selectDefaultSetting);
    const warehouses = useSelector(selectWarehouses);
    const currencies = useSelector(selectCurrency);
    const saleDefaultsSettings = useSelector(selectSaleDefaultsSettings)

    const defaultWarehouse = warehouses[0];
    const lang = useSelector(selectLang);
    const t = Translate;
    const itemsDictionary = useSelector(selectItemsDictionary);
    const dispatch = useDispatch();

    const methods = useForm({
        defaultValues: {
            approved: false,
            contractorId: order.contractor ? order.contractor.id : null,
            employeeId: null,
            cashBoxId: settings?.cash_box?.default_id || null,
            date: dayjs(order.date).toDate(),
            note: order.comment,
            items: order.items.filter(orderItem => !orderItem.is_deleted).reverse().map(orderItem => {
                return {
                    itemId: orderItem.item.id,
                    name: orderItem.item.name,
                    quantity: orderItem.quantity,
                    price: {
                        amount: orderItem.price.amount,
                        currencyId: orderItem.price.currency.id,
                    },
                    discount: {
                        type: withoutDiscount,
                        value: 0,
                    },
                    warehouseId: settings?.warehouse?.default_id || defaultWarehouse?.id,
                };
            }),
            payment: null,
            exactDiscounts: [],
            percentDiscount: 0,
        },
    });

    useEffect(() => {
        methods.setValue('cashBoxId', settings?.cash_box?.default_id || null);

        const formItems = methods.getValues('items');
        formItems.map(i => {
            return {...i, warehouseId: settings?.warehouse?.default_id || null};
        });
        methods.setValue('items', formItems);
    }, [settings])

    const calculateItemDiscountPrice = (item) => {
        const discountValue = Number(item?.discount ? item?.discount?.value : 0);
        if (item?.discount?.type === withDiscount) {
            const itemDiscount = (Number(item?.price?.amount) / 100) * discountValue;
            return Number(item?.price?.amount) - itemDiscount;
        } else {
            return Number(item?.price?.amount) - discountValue
        }
    }

    const items = useWatch({name: 'items', control: methods.control});
    const totalNetPrice = useMemo(() => {
        const netPrice = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const itemNetPrice = calculateItemDiscountPrice(item) * Number(item.quantity);

            let currencyAmountFound = false;
            for (let j = 0; j < netPrice.length; j++) {
                if (netPrice[j]?.currencyId === item.price.currencyId) {
                    netPrice[j].amount += itemNetPrice;
                    currencyAmountFound = true;
                }
            }

            if (!currencyAmountFound) {
                netPrice.push({amount: itemNetPrice, currencyId: item.price.currencyId});
            }
        }
        return netPrice;
    }, [items]);

    const contractorId = useWatch({name: 'contractorId', control: methods.control});
    const payment = useWatch({name: 'payment', control: methods.control});
    const percentDiscount = useWatch({name: 'percentDiscount', control: methods.control});
    const exactDiscounts = useWatch({name: 'exactDiscounts', control: methods.control});
    const totalDiscountAmount = useMemo(() => {
        const nationalCurrencyTotalAmount = totalNetPrice.find(total => total.currencyId === nationalCurrency?.id)?.amount || 0;
        const percentDiscountAmount = (nationalCurrencyTotalAmount / 100) * percentDiscount;

        const totalAmount = [];
        for (let i = 0; i < exactDiscounts?.length; i++) {
            const exactDiscount = exactDiscounts[i];
            if (exactDiscount.currencyId === nationalCurrency?.id) {
                exactDiscount.amount += percentDiscountAmount;
            }
            totalAmount.push(exactDiscount);
        }

        return totalAmount;
    }, [percentDiscount, exactDiscounts]);

    const onSubmitPayment = async paymentData => {
        methods.setValue('payment', {
            cashBoxStates: paymentData?.payment?.cash_box_states?.map(cashBoxState => {
                return {amount: cashBoxState.amount, currencyId: cashBoxState.currency_id, type: cashBoxState.type};
            }),
            debtStates: paymentData?.payment?.debt_states?.map(debtState => {
                return {amount: debtState.amount, currencyId: debtState.currency_id};
            }),
            notes: paymentData?.payment?.note,
        });

        methods.setValue('exactDiscounts', paymentData?.exactDiscounts?.map(exactDiscount => {
            return {amount: exactDiscount?.amount, currencyId: exactDiscount?.currency_id};
        }));

        methods.setValue('percentDiscount', paymentData?.percentDiscount);
    };

    const registerForDebt = event => {
        methods.setValue('approved', true);
        return methods.handleSubmit(onSubmit)(event);
    };

    const saveWithoutApprove = event => {
        methods.setValue('approved', false);
        return methods.handleSubmit(onSubmit)(event);
    };

    const saveWithApprove = event => {
        methods.setValue('approved', true);
        return methods.handleSubmit(onSubmit)(event);
    };

    const validateItems = async (items) => {

        dispatch(updateShowInProgressAsync(true));

        const batchSize = 5;
        let hasError = false;
        let lastErrorIndex = null;

        for (let i = 0; i < items.length; i += batchSize) {
            const batch = items.slice(i, i + batchSize);

            const validations = batch.map(async (item, index) => {
                const operationItem = itemsDictionary[item.itemId];
                const warehouse_id = operationItem?.warehouse_states.warehouse_items.find(
                    (warehouseItem) => warehouseItem.id === item.warehouseId
                );

                if (warehouse_id?.state <= item.quantity) {
                    hasError = true;
                    const currentIndex = i + index;

                    lastErrorIndex = currentIndex;

                    methods.setError(`items.${currentIndex}.quantity`, {
                        type: "manual",
                        message: t(lang, 'warehouse.operation.item.common.validation.remaining.stock.is_insufficient', {
                                state: warehouse_id.state
                        }),
                    });
                }
            });

            await Promise.all(validations);

            await new Promise((resolve) => setTimeout(resolve, 0));
        }

        if (lastErrorIndex !== null) {
            methods.setFocus(`items.${lastErrorIndex}.quantity`);
        }

        dispatch(updateShowInProgressAsync(false));

        return hasError;
    };

    const onSubmit = async (formData) => {

        setIsLoading(true)

        if (saleDefaultsSettings?.enableSaleWithAvailableItems) {
            const hasError=  await validateItems(formData.items);

            if (hasError) {
                setIsLoading(false);
                return
            }
        }

        const payload = {
            contractor_id: formData.contractorId,
            employee_id: formData.employeeId,
            cashbox_id: formData.cashBoxId,
            approve: formData.approved,
            number: null,
            date: dayjs(formData.date).format("YYYY-MM-DD HH:mm:ss"),
            note: formData.note,
            items: formData.items.map(saleItem => {
                return {
                    item_id: saleItem.itemId,
                    warehouse_id: saleItem.warehouseId,
                    quantity: Number(saleItem.quantity),
                    price: {
                        amount: Number(saleItem.price.amount),
                        currency_id: saleItem.price.currencyId,
                    },
                    marks: [],
                    discount: {
                        value: Number(saleItem.discount.value),
                        type: Number(saleItem.discount.type),
                    },
                };
            }),
            payment: null,
            exact_discounts: formData.exactDiscounts.map(exactDiscount => {
                return {amount: exactDiscount.amount, currency_id: exactDiscount.currencyId};
            }),
            percent_discount: formData.percentDiscount,
            location: null,
            order_info: {
                type: OrderTypeTelegramOrder,
                id: order.id,
            },
        };

        if (formData.payment) {
            payload.payment = {
                debt_states: formData.payment.debtStates.map(debtState => {
                    return {amount: debtState.amount, currency_id: debtState.currencyId};
                }),
                cash_box_states: formData.payment.cashBoxStates.map(cashBoxState => {
                    return {amount: cashBoxState.amount, currency_id: cashBoxState.currencyId, type: cashBoxState.type};
                }),
                notes: formData.payment.notes,
            };
        }

        if (order.location) {
            payload.location = {
                name: order.location.name,
                latitude: order.location.latitude,
                longitude: order.location.longitude,
            };
        }

        saleRegisterAsync(payload)
            .then(() => toast.success(t(lang, "warehouse.operation.item.common.toast.success")))
            .catch(() => toast.error(t(lang, "warehouse.operation.item.common.toast.error")))
            .finally(() => setIsLoading(false))
    };

    return (
        <Fragment>
            <InProgress/>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Card className={'my-3'}>
                        <Card.Body className={'d-flex justify-content-between'}>
                            <div>
                                {totalNetPrice && <h4 className={'fw-normal'}>{t(lang, 'warehouse.operation.item.common.total_sum')}: {totalNetPrice.map((netPrice, index) => (
                                    <span className={'mx-1 float-end fw-semi-bold'} key={index}>{numeral['formats'][numberFormat].format(netPrice.amount)} {itemWrapper.getCurrency(currencies, netPrice.currencyId)?.name}</span>
                                ))}</h4>}

                                {!!totalDiscountAmount?.length && <h4 className={'fw-normal'}>{t(lang, 'warehouse.operation.item.common.discount')}: {totalDiscountAmount.map((discount, index) => (
                                    <span className={'mx-1 float-end fw-semi-bold'} key={index}>{numeral['formats'][numberFormat].format(discount.amount)} {itemWrapper.getCurrency(currencies, discount.currencyId)?.name}</span>
                                ))}</h4>}

                                {payment && <h4 className={'fw-normal'}>{t(lang, 'warehouse.operation.item.common.paid')}: {payment.cashBoxStates?.map((cashBoxState, index) => (
                                    <span className={'mx-1 float-end fw-semi-bold'} key={index}>{numeral['formats'][numberFormat].format(cashBoxState.amount)} {itemWrapper.getCurrency(currencies, cashBoxState.currencyId)?.name}</span>
                                ))}</h4>}
                            </div>

                            <div>
                                <WOWIthDiscountPayment
                                    size={'lg'}
                                    payment={payment}
                                    className={'me-3'}
                                    isEditing={!!payment}
                                    contractorId={contractorId}
                                    totalAmounts={totalNetPrice}
                                    onSubmitPay={onSubmitPayment}
                                    exactDiscounts={exactDiscounts}
                                    percentDiscount={percentDiscount}
                                    variant={payment ? 'warning' : 'primary'}
                                />

                                <WarehouseOperationWithoutPayment
                                    size={'lg'}
                                    variant={'dark'}
                                    className={'ms-1'}
                                    disabled={isLoading}
                                    onClick={registerForDebt}
                                />

                                {payment ? (
                                    <Button size={'lg'} variant={'primary'} type={'button'} disabled={isLoading} onClick={saveWithApprove} className={'ms-1'}>
                                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                        {t(lang, 'warehouse.operation.common.save')}
                                    </Button>
                                ) : (
                                    <Button size={'lg'} variant={'secondary'} type={'button'} disabled={isLoading} onClick={saveWithoutApprove} className={'ms-1'}>
                                        {isLoading && <FontAwesomeIcon pulse={true} className="me-2" icon="spinner"/>}
                                        {t(lang, 'warehouse.operation.common.save_without_approve')}
                                    </Button>
                                )}
                            </div>
                        </Card.Body>
                    </Card>

                    <CreateTelegramOrderSaleDetailsForm/>
                    <CreateTelegramOrderSaleItemsForm totalNetPrice={totalNetPrice}/>
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default CreateTelegramOrderSaleForm;
