import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DatePicker from "react-datepicker";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import classNames from "classnames";
import SelectContract from "../../contract/SelectContract";
import {DefineByExecutor} from "../../../../app/order/OrderShipmentDateMethodStrategyType";
import ReturnInvoiceMember from "./form-blocks/ReturnInvoiceMember";
import ReturnInvoiceItems from "./form-blocks/ReturnInvoiceItems";
import PropTypes from "prop-types";
import {
    createSigner,
    deleteSigner,
    loadSigners
} from "../../../../app/store/reducers/return-invoice/returnInvoiceReducer";
import dayjs from "dayjs";
import EditContract from "../../../../pages/main/edi/contract/EditEdiContract";
import AddEdiContractForm from "../../contract/forms/edi/AddEdiContractForm";
import IconButton from "../../../common/IconButton";
import SelectDirector from "../../signers/SelectDirector";
import SelectAccountant from "../../signers/SelectAccountant";
import SelectReleaser from "../../signers/SelectReleaser";
import SelectInvoiceModal from "./form-blocks/SelectInvoiceModal";

const ReturnInvoiceForm = ({loading, productMapNames, existingNumber}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const {register, formState: {errors}, getValues, setValue} = useFormContext();
    const contractDefineStrategy = getValues('strategies.contract_define_method_strategy');
    const allowEditContract = contractDefineStrategy === DefineByExecutor.id;

    const contract = useWatch({name: 'contractInfo'});

    useEffect(() => {
        dispatch(loadSigners());
    }, []);


    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const addModalClose = () => setShowAddModal(false);
    const addModalShow = () => setShowAddModal(true);
    const editModalClose = () => setShowEditModal(false);
    const editModalShow = () => setShowEditModal(true);

    const onContractSelected = (contract) => {
        if (contract) {
            setValue('contractInfo.date', dayjs(contract.info.date).toDate());
            setValue('contractInfo.number', contract.info.number);
            setValue('contractInfo.id', contract.id);
        } else {
            setValue('contractInfo.date', null);
            setValue('contractInfo.number', null);
            setValue('contractInfo.id', null);
        }
    };

    return (
        <>
            <AddEdiContractForm show={showAddModal} onClose={addModalClose}/>
            {showEditModal && <EditContract id={contract.id} show={showEditModal} onClose={editModalClose}/>}
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col>
                                    <h5>{t(lang, "edi.common.invoice")}</h5>
                                </Col>
                                <Col xs={"auto"} className="text-center">
                                    <Button type="submit" disabled={loading}>
                                        {loading &&
                                            <Spinner className="align-middle me-2" animation="border" role="switch"
                                                     size="sm"/>}
                                        {t(lang, "edi.common.save_button")}
                                    </Button>
                                </Col>
                            </Row>


                        </Card.Header>
                        <Card.Body>
                            <Row className="d-flex flex-column flex-lg-row">
                                <Row className="d-flex flex-wrap col-12 col-lg-6">
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.invoice.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="returnInvoiceInfo.number"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.invoice.number.placeholder")}
                                                      isInvalid={errors?.returnInvoiceInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.returnInvoiceInfo?.number}
                                                      {...register('returnInvoiceInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required"),
                                                          validate: (value) => {
                                                              if (existingNumber) {
                                                                  if (value === existingNumber)
                                                                      return t(lang, "edi.common.forms.validations.is_exists", {name: t(lang, "edi.common.invoice.number")});
                                                              }
                                                              return true;
                                                          }
                                                      })}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.returnInvoiceInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.invoice.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'returnInvoiceInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.returnInvoiceInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.returnInvoiceInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.returnInvoiceInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.returnInvoiceInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipment.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="returnShipmentInfo.number"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.shipment.number.placeholder")}
                                                      disabled
                                                      isInvalid={errors?.returnShipmentInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.returnShipmentInfo?.number}
                                                      {...register('returnShipmentInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.returnShipmentInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={6} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipment.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'returnShipmentInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            disabled
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.returnShipmentInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.returnShipmentInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.returnShipmentInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.returnShipmentInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    {!allowEditContract &&
                                        <>
                                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                                <Form.Label>
                                                    {t(lang, "edi.common.contract.number")}
                                                    <span className={'text-danger'}>*</span>
                                                </Form.Label>
                                                <Form.Control name="contractInfo.number"
                                                              placeholder={t(lang, "edi.common.contract.number.placeholder")}
                                                              type="text"
                                                              isInvalid={errors?.contractInfo?.number}
                                                              isValid={Object.keys(errors).length > 0 && !errors?.contractInfo?.number}
                                                              {...register('contractInfo.number', {
                                                                  required: t(lang, "edi.common.forms.validations.is_required")
                                                              })}
                                                              disabled
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                            </Col>
                                            <Col xs={12} lg={6} sm={6} className="mb-2">
                                                <Form.Label>
                                                    {t(lang, "edi.common.contract.date")}
                                                    <span className={'text-danger'}>*</span>
                                                </Form.Label>
                                                <Controller name={'contractInfo.date'}
                                                            rules={{
                                                                required: t(lang, "edi.common.forms.validations.is_required")
                                                            }}
                                                            render={({field}) => (
                                                                <DatePicker
                                                                    placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                                    selected={field.value}
                                                                    onChange={field.onChange}
                                                                    dateFormat={dateFormat}
                                                                    wrapperClassName={classNames({
                                                                        'is-invalid': errors?.contractInfo?.date,
                                                                    })}
                                                                    className={classNames('form-control', {
                                                                        'is-invalid': errors?.contractInfo?.date,
                                                                        'is-valid': Object.keys(errors).length > 0 && !errors?.contractInfo?.date
                                                                    })}
                                                                    disabled
                                                                />
                                                            )}
                                                />
                                                <Form.Control.Feedback
                                                    type="invalid">{errors?.contractInfo?.date?.message}</Form.Control.Feedback>
                                            </Col>
                                        </>
                                    }
                                    {allowEditContract &&
                                        <Col xs={12} lg={6} sm={6}>
                                            <Form.Label>
                                                {t(lang, "edi.common.contract")}
                                                <span className={'text-danger'}>*</span>
                                            </Form.Label>
                                            <div className="d-flex">
                                                <Controller name={'contractInfo.number'}
                                                            rules={{
                                                                required: t(lang, "edi.common.forms.validations.is_required")
                                                            }}
                                                            render={({field}) => (
                                                                <SelectContract
                                                                    defaultContractNumber={contract.number}
                                                                    defaultContractDate={dayjs(contract.date).format('YYYY-MM-DD')}
                                                                    defaultValue={field.value}
                                                                    classNamePrefix="react-select rounded-end-0 border-end"
                                                                    placeholder={t(lang, "edi.common.select_button")}
                                                                    className={classNames('w-100', {
                                                                        'is-invalid': !!errors?.contractInfo?.number
                                                                    })}
                                                                    wrapperClassName={classNames({
                                                                        'is-invalid': !!errors?.contractInfo?.number
                                                                    })}
                                                                    onChange={contract => {
                                                                        onContractSelected(contract);
                                                                    }}
                                                                />
                                                            )}
                                                />
                                                <ButtonGroup>
                                                    {contract?.id &&
                                                        <IconButton className="rounded-start-0"
                                                                    onClick={editModalShow}
                                                                    icon="pencil"
                                                                    variant="outline-secondary"/>}
                                                    <IconButton className="rounded-start-0"
                                                                onClick={addModalShow}
                                                                icon="plus"
                                                                variant="outline-secondary"/>
                                                </ButtonGroup>
                                            </div>
                                            <Form.Control.Feedback
                                                type="invalid">{errors?.contractInfo?.number?.message}</Form.Control.Feedback>
                                        </Col>
                                    }
                                    <Col xs={12} lg={allowEditContract ? 6 : 12} sm={!allowEditContract ? 12 : 6}
                                         className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.common.shipping_address")}
                                        </Form.Label>
                                        <Form.Control name="loadingAddress"
                                                      type="text"
                                                      placeholder={t(lang, "edi.common.shipping_address.placeholder")}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.loadingAddress}
                                                      {...register('loadingAddress')}
                                        />
                                    </Col>
                                </Row>
                                <Row className="d-flex flex-lg-column flex-row col-12 col-lg-6">
                                    <Col xs={12} lg={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.return_invoice.new_return_invoice.invoice.number")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Form.Control name="invoiceInfo.number"
                                                      type="text"
                                                      placeholder={t(lang, "edi.return_invoice.new_return_invoice.invoice.number.placeholder")}
                                                      disabled
                                                      isInvalid={errors?.invoiceInfo?.number}
                                                      isValid={Object.keys(errors).length > 0 && !errors?.invoiceInfo?.number}
                                                      {...register('invoiceInfo.number', {
                                                          required: t(lang, "edi.common.forms.validations.is_required")
                                                      })}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.invoiceInfo?.number?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={12} sm={6} className="mb-2">
                                        <Form.Label>
                                            {t(lang, "edi.return_invoice.new_return_invoice.invoice.date")}
                                            <span className={'text-danger'}>*</span>
                                        </Form.Label>
                                        <Controller name={'invoiceInfo.date'}
                                                    rules={{
                                                        required: t(lang, "edi.common.forms.validations.is_required")
                                                    }}
                                                    render={({field}) => (
                                                        <DatePicker
                                                            placeholderText={t(lang, "edi.common.invoice.date.placeholder")}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            dateFormat={dateFormat}
                                                            disabled
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.invoiceInfo?.date,
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.invoiceInfo?.date,
                                                                'is-valid': Object.keys(errors).length > 0 && !errors?.invoiceInfo?.date
                                                            })}
                                                        />
                                                    )}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors?.invoiceInfo?.date?.message}</Form.Control.Feedback>
                                    </Col>
                                    <Col xs={12} lg={12} sm={12} className="d-grid my-2 d-lg-flex align-items-end">
                                        <SelectInvoiceModal/>
                                    </Col>
                                </Row>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className="mt-4">
                    <Card className="h-100">
                        <Card.Header>
                            <h5>{t(lang, "edi.common.customer")}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <ReturnInvoiceMember rootName={'customer'}/>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={6} className="mt-4">
                    <Card className="h-100">
                        <Card.Header>
                            <h5>{t(lang, "edi.common.executor")}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <ReturnInvoiceMember rootName={'executor'}/>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} className="mt-4">
                    <Card>
                        <Card.Header>
                            <h5>{t(lang, "edi.common.items")}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ReturnInvoiceItems productMapNames={productMapNames}/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} className="mt-4">
                    <Card>
                        <Card.Header>
                            <h5>{t(lang, "edi.common.signers")}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Row>
                                        <Col xs={12} className="mb-2">
                                            <Form.Label>{t(lang, "edi.common.director")}</Form.Label>
                                            <Controller name={"director"}
                                                        render={({field}) => (
                                                            <SelectDirector
                                                                onCreate={(director) => {
                                                                    dispatch(createSigner(director));
                                                                    field.onChange(director.label);
                                                                }}
                                                                onDelete={deleteSigner}
                                                            />
                                                        )}
                                            />
                                        </Col>
                                        <Col xs={12} className="mb-2">
                                            <Form.Label>{t(lang, "edi.common.accountant")}</Form.Label>
                                            <Controller name={"accountant"}
                                                        render={({field}) => (
                                                            <SelectAccountant
                                                                onCreate={(accountant) => {
                                                                    dispatch(createSigner(accountant));
                                                                    field.onChange(accountant.label);
                                                                }}
                                                                onDelete={deleteSigner}
                                                            />
                                                        )}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Label>{t(lang, "edi.common.releaser")}</Form.Label>
                                            <Controller name={"releaser"}
                                                        render={({field}) => (
                                                            <SelectReleaser
                                                                onCreate={(releaser) => {
                                                                    dispatch(createSigner(releaser));
                                                                    field.onChange(releaser.label);
                                                                }}
                                                                onDelete={deleteSigner}
                                                            />
                                                        )}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} sm={6}>
                                    <Row>
                                        <Col xs={12} className="mb-2">
                                            <Form.Label>{t(lang, "edi.common.shipper")}</Form.Label>
                                            <Form.Control
                                                placeholder={t(lang, "edi.common.shipper.placeholder")}
                                                type="text"
                                                isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.name}
                                                {...register('proxyInfo.name')}
                                            />
                                        </Col>
                                        <Col xs={12} className="mb-2">
                                            <Form.Label>{t(lang, "edi.common.attorney.number")}</Form.Label>
                                            <Form.Control
                                                placeholder={t(lang, "edi.common.attorney.number.placeholder")}
                                                type="text"
                                                isValid={Object.keys(errors).length > 0 && !errors?.proxyInfo?.number}
                                                {...register('proxyInfo.number')}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Label>{t(lang, "edi.common.attorney.date")}</Form.Label>
                                            <Controller name="proxyInfo.date"
                                                        render={
                                                            ({field}) => (
                                                                <DatePicker
                                                                    placeholderText={t(lang, "edi.common.attorney.date.placeholder")}
                                                                    selected={field.value}
                                                                    onChange={field.onChange}
                                                                    dateFormat="dd.MM.yyyy"
                                                                    className={classNames('form-control', {
                                                                        'is-valid': Object.keys(errors).length > 0
                                                                    })}
                                                                />
                                                            )
                                                        }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col className="text-end">
                                    <Button type="submit" disabled={loading}>
                                        {loading &&
                                            <Spinner className="align-middle me-2" animation="border" role="switch"
                                                     size="sm"/>}
                                        {t(lang, "edi.common.save_button")}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

ReturnInvoiceForm.propTypes = {
    loading: PropTypes.bool,
    existingNumber: PropTypes.string
};

ReturnInvoiceForm.defaultProps = {
    loading: false,
    existingNumber: ''
}

export default ReturnInvoiceForm;
