import React, {useEffect, useState} from 'react';
import PeriodReportSummary from "./PeriodReportSummary";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Tab, Tabs} from "react-bootstrap";
import ContractorsPeriodReportSummaryDataTable from "./ContractorsPeriodReportSummaryDataTable";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";

const Report = ({report}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [activeTab, setActiveTab] = useState("0");

    const history = useHistory()
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    let activeTabFromRoute = queryParameters.get("activeTab");

    useEffect(() => {
        if (activeTabFromRoute)
            setActiveTab(activeTabFromRoute)
    }, [activeTabFromRoute])

    return (
        <Tabs activeKey={activeTab}
              onSelect={(key) => {
                  history.push(`/warehouse/operation/report/?activeTab=${key}`)
                  setActiveTab(key)
              }}
        >
            <Tab eventKey="0"
                 title={t(lang, "warehouse.operation.general_report")}
            >
                <div className="mt-3">
                    <PeriodReportSummary report={report}/>
                </div>
            </Tab>
            <Tab eventKey="1"
                 title={t(lang, "warehouse.operation.contractors_report")}
            >
                <div className="mt-3">
                    <ContractorsPeriodReportSummaryDataTable contractorReports={report.contractors_period_report}/>
                </div>
            </Tab>
        </Tabs>
    )
};

export default Report;