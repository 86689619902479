import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ROAMING_ACT_ACCEPT_FAILED,
    ROAMING_ACT_ACCEPT_REQUESTED,
    ROAMING_ACT_ACCEPT_SUCCESS,
    ROAMING_ACT_CANCEL_FAILED,
    ROAMING_ACT_CANCEL_REQUESTED,
    ROAMING_ACT_CANCEL_SUCCESS,
    ROAMING_ACT_DECLINE_FAILED,
    ROAMING_ACT_DECLINE_REQUESTED,
    ROAMING_ACT_DECLINE_SUCCESS,
    ROAMING_ACT_DRAFT_FAILED,
    ROAMING_ACT_DRAFT_REQUESTED,
    ROAMING_ACT_DRAFT_SUCCESS,
    ROAMING_ACT_REGISTER_FAILED,
    ROAMING_ACT_REGISTER_REQUESTED,
    ROAMING_ACT_REGISTER_SUCCESS,
    ROAMING_ACT_SYNC_FAILED,
    ROAMING_ACT_SYNC_REQUESTED,
    ROAMING_ACT_SYNC_SUCCESS
} from "../../../eventbus/roaming/roamingActEvents";
import RoamingService from "../../../../services/roaming/roamingService";
import roamingService from "../../../../services/roaming/roamingService";
import {attachPkcs7WithTimestamp, createPkcs7WithTimestamp} from "../eimzo/eimzoReducer";
import {Translate} from "../main/mainReducer";

export const roamingActSlice = createSlice({
    name: "roamingAct",
    initialState: {
        filters: {
            registryId: null,
            status: null,
            number: '',
            dateStart: null,
            dateEnd: null,
            createdAtStart: null,
            createdAtEnd: null,
            contractNumber: '',
            contractDate: null,
            contractor: null
        },
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        columnVisibility: {
            "selectColumn": true,
            "#": true,
            "number": true,
            "date": true,
            "total": true,
            "status": true,
            "created_at": true,
            "contractor": true,
            "contract_number": true,
            "contract_date": true
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setColumnVisibility: (state, action) => {
            state.columnVisibility = action.payload;
        },
    }
});

export const updateFilters = (filters) => (dispatch) => dispatch(roamingActSlice.actions.setFilters(filters));
export const updatePagination = (pagination) => (dispatch) => dispatch(roamingActSlice.actions.setPagination(pagination));
export const updateColumnVisibility = (columnVisibility) => (dispatch) => dispatch(roamingActSlice.actions.setColumnVisibility(columnVisibility));
export const selectFilters = state => state.roamingAct.filters;
export const selectPagination = state => state.roamingAct.pagination;
export const selectColumnVisibility = state => state.roamingAct.columnVisibility;

const t = Translate;

export const ActFormSendAsync = async (certificate, lang, hashCode) => {
    try {
        EventBus.dispatch(ROAMING_ACT_REGISTER_REQUESTED, hashCode);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const response = await RoamingService.registerActFormSend({signature: signature, lang: lang});
        EventBus.dispatch(ROAMING_ACT_REGISTER_SUCCESS, response.data);
        return response;
    } catch (error) {
        let errorMessage;
        const errorResponse = error?.response?.data;
        if (errorResponse.content) {
            const parsedError = JSON.parse(errorResponse.content);
            errorMessage = {message: parsedError?.ErrorMessage}
        } else if (errorResponse?.not_allowed_inn) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.not_allowed_inn')}
        } else if (errorResponse?.content_parse_failed) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.content_parse_failed')}
        } else if (errorResponse.roaming_error_message) {
            errorMessage = {
                error: error,
                message: JSON.parse(errorResponse.roaming_error_message)?.ErrorMessage || errorResponse.roaming_error_message
            }
        } else {
            errorMessage = {message: t(lang, 'roaming.waybill.error.message.default_error')}
        }
        EventBus.dispatch(ROAMING_ACT_REGISTER_FAILED, errorMessage);
        throw errorMessage
    }
}


export const ActFormSendDraft = async (data) => {
    EventBus.dispatch(ROAMING_ACT_DRAFT_REQUESTED);

    return new Promise((resolve, reject) => {
        RoamingService.actFormSendDraft(data)
            .then(response => {
                EventBus.dispatch(ROAMING_ACT_DRAFT_SUCCESS, response.data)
                resolve(response.data)
            })
            .catch(errors => {
                EventBus.dispatch(ROAMING_ACT_DRAFT_FAILED, errors)
                reject(errors)
            })
    })
}

export const loadActDraftAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getActDraft(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}

export const loadActAsync = (id) => {
    return new Promise((resolve, reject) => {
        RoamingService.getAct(id)
            .then(response => resolve(response))
            .catch(errors => reject(errors))
    })
}


export const ActCustomerAcceptHashCodeAsync = async (certificate, lang, id) => {
    try {
        const {data: hashCode} = await roamingService.getActAcceptHashCode(id)

        EventBus.dispatch(ROAMING_ACT_ACCEPT_REQUESTED, id);
        const {pkcs7: signature} = await attachPkcs7WithTimestamp(hashCode, certificate.keyId);
        const response = await roamingService.ActCustomerAccept(id, signature);
        EventBus.dispatch(ROAMING_ACT_ACCEPT_SUCCESS, response.data);
        return response;
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_ACCEPT_FAILED, error);
        throw error;
    }
}

export const ActCustomerDeclineHashCodeAsync = async (certificate, lang, id, comment) => {
    try {
        const {data: hashCode} = await roamingService.getActDeclineHashCode(id, comment)

        EventBus.dispatch(ROAMING_ACT_DECLINE_REQUESTED, id);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const response = await roamingService.ActCustomerDecline(id, signature, comment);
        EventBus.dispatch(ROAMING_ACT_DECLINE_SUCCESS, response.data);
        return response;
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_DECLINE_FAILED, error);
        throw error;
    }
}

export const ActCancelHashCodeAsync = async (certificate, lang, id) => {
    try {
        const {data: hashCode} = await roamingService.getActCancelHashCode(id)
        EventBus.dispatch(ROAMING_ACT_CANCEL_REQUESTED, id);
        const {pkcs7: signature} = await createPkcs7WithTimestamp(hashCode, certificate.keyId);
        const response = await roamingService.ActCancel(id, signature);
        EventBus.dispatch(ROAMING_ACT_CANCEL_SUCCESS, response.data);
        return response;
    } catch (error) {
        EventBus.dispatch(ROAMING_ACT_CANCEL_FAILED, error);
        throw error;
    }
}

export const syncActAsync = (id) => {
    EventBus.dispatch(ROAMING_ACT_SYNC_REQUESTED, id);
    return new Promise((resolve, reject) => {
        roamingService.syncAct(id)
            .then((response) => {
                EventBus.dispatch(ROAMING_ACT_SYNC_SUCCESS, id);
                resolve(response.data)
            })
            .catch((error) => {
                let errorMessage = {message: error.response.data}
                EventBus.dispatch(ROAMING_ACT_SYNC_FAILED, errorMessage)
                reject(error)
            })
    })
}

export default roamingActSlice.reducer;