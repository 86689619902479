import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {debounce} from "underscore";
import {Card} from "react-bootstrap";
import DataTable from "../../../common/table/DataTable";
import DataTablePagination from "../../../common/table/DataTablePagination";
import DataTableLimiter from "../../../common/table/DataTableLimiter";
import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import OwnerOrganizationSalesDataTableHeader from "./OwnerOrganizationSalesDataTableHeader";
import referenceDataAggregatorService
    from "../../../../services/referenceDataAggregator/referenceDataAggregatorService";
import dayjs from "dayjs";
import ViewSaleModal from "./ViewSaleModal";
import DataTableColumnSettings from "../../../common/table/DataTableColumnSettings";
import numeral from "numeral";
import SoftBadge from "../../../common/SoftBadge";
import {
    ContractorPaymentTypes,
    GetPaymentTypeText,
    PaymentTypeBankTransferCode,
    PaymentTypeCashCode,
    PaymentTypeClickCode,
    PaymentTypeHumoCode,
    PaymentTypePaymeCode,
    PaymentTypeUzCardCode,
    PaymentTypeVisaCode
} from "../../../../enum/ContractorWrapper";

const OwnerOrganizationSalesDataTable = ({organizationBinding, isViewSalePaymentsEnabled}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);
    const [filters, setFilters] = useState({
        dateStart: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        dateEnd: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        hasShortageFromOrder: null,
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [columnVisibility, setColumnVisibility] = useState({
        "paymentTypeHumo": false,
        "paymentTypeBankTransfer": false,
        "paymentTypeClick": false,
        "paymentTypeVisa": false,
    });
    const [loading, setLoading] = useState(false);
    const [sales, setSales] = useState([]);
    const [count, setCount] = useState(0);

    const onFilterChange = (filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
        load(filterParams, paginationParams)
    };

    const onDebouncedFilterChange = useCallback(debounce((filter) => {
        const filterParams = {...filter};
        const paginationParams = {...pagination, pageIndex: 0};
        setFilters(filterParams);
        setPagination(paginationParams);
        load(filterParams, paginationParams)
    }, 500), [filters, pagination]);
    const onColumnVisibilityChange = (updater) => {
        setColumnVisibility(updater);
    };
    const onPaginationChange = (updater) => {
        const nextState = updater(pagination);
        const paginationParams = {pageSize: nextState.pageSize, pageIndex: nextState.pageIndex};
        setPagination(paginationParams);
        load(filters, paginationParams)
    };

    const load = async (filter, pagination) => {
        try {
            const filterParams = {};
            const paginationParams = {};

            // build filters
            {
                if (filter.dateStart)
                    filterParams["date_start"] = dayjs(filter.dateStart).format("YYYY-MM-DD HH:mm:ss")

                if (filter.dateEnd)
                    filterParams["date_end"] = dayjs(filter.dateEnd).format("YYYY-MM-DD HH:mm:ss")

                if (filter.hasShortageFromOrder !== null)
                    filterParams["has_shortage_from_order"] = filter.hasShortageFromOrder;
            }

            // build pagination
            {
                paginationParams["skip"] = pagination.pageIndex * pagination.pageSize;
                paginationParams["limit"] = pagination.pageSize;
            }

            setLoading(true);
            const promiseGroup = await Promise.all([
                referenceDataAggregatorService.getOrganizationBindingSales(organizationBinding.id, {
                    ...filterParams,
                    ...paginationParams
                }),
                referenceDataAggregatorService.getOrganizationBindingSalesCount(organizationBinding.id, {...filterParams})
            ]);
            setSales(promiseGroup[0].data);
            setCount(promiseGroup[1].data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSales([]);
            setCount(0);
            setLoading(false);
        }
    };

    const columns = useMemo(() => {
        const dataTableColumns = [
            {
                id: '#',
                accessorKey: '№',
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            №
                        </th>
                    );
                },
                cell: ({row}) => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >
                            {pagination.pageIndex * pagination.pageSize + row.index + 1}
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "40px",
                                maxWidth: "40px",
                                minWidth: "40px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'client',
                accessorKey: t(lang, 'partners.common.sales.client'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-dark fs--1"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, 'partners.common.sales.client')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >
                            <div className="d-flex flex-column">
                                <h5 className="fs--1 fw-semi-bold font-sans-serif text-black text-800">{original?.contractor?.name}</h5>
                                <strong className="fs--2">{dayjs(original.date).format("YYYY-MM-DD HH:mm:ss")}</strong>
                            </div>
                        </td>
                    );
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                minWidth: "200px"
                            }}
                        >

                        </td>
                    )
                },
            },
            {
                id: 'saleTotalSum',
                accessorKey: t(lang, 'warehouse-operation.sale.datatable.sale_total_price'),
                enableSorting: false,
                enableHiding: false,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, 'warehouse-operation.sale.datatable.sale_total_price')}
                        </th>
                    );
                },
                cell: ({row: {original}}) => {
                    const saleItemsPriceSummary = original.items.filter(i => !i.is_deleted).reduce((acc, item) => {
                        if (acc[item.net_price.currency.name])
                            acc[item.net_price.currency.name] += item.net_price.amount;
                        else
                            acc[item.net_price.currency.name] = item.net_price.amount;

                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {Object.keys(saleItemsPriceSummary).map((key) => (
                                <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                    <span>{numeral.formats[numberFormat].format(saleItemsPriceSummary[key])}</span>
                                    <strong className="ms-2 fw-bold text-info">{key}</strong>
                                </div>
                            ))}
                        </td>
                    );
                },
                footer: () => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const salesItemsPriceSummary = rows.reduce((acc, {original}) => {
                        const saleItems = original.items.filter(i => !i.is_deleted);
                        saleItems.forEach((saleItem) => {
                            if (acc[saleItem.net_price.currency.name])
                                acc[saleItem.net_price.currency.name] += saleItem.net_price.amount;
                            else
                                acc[saleItem.net_price.currency.name] = saleItem.net_price.amount;
                        })
                        return acc;
                    }, {})

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {Object.keys(salesItemsPriceSummary).map((key) => (
                                <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                    <span>{numeral.formats[numberFormat].format(salesItemsPriceSummary[key])}</span>
                                    <strong className="ms-2 fw-bold text-info">{key}</strong>
                                </div>
                            ))}
                        </td>
                    )
                },
            },
            {
                id: 'hasSaleShortage',
                accessorKey: t(lang, "warehouse.operation.item.common.filter.has_shortage_from_order"),
                enableSorting: false,
                enableHiding: true,
                header: ({column}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {t(lang, 'warehouse.operation.item.common.filter.has_shortage_from_order')}
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    if (original.order_info?.has_sale_shortage) {
                        const saleShortagesTotalSummary = original.order_info?.sale_shortage_items?.reduce((acc, item) => {
                            if (acc[item.net_price.currency.name])
                                acc[item.net_price.currency.name] += item.net_price.amount;
                            else
                                acc[item.net_price.currency.name] = item.net_price.amount;

                            return acc;
                        }, {});

                        return (
                            <td className="p-1 align-middle text-center text-dark"
                                style={{
                                    width: "200px",
                                    minWidth: "200px"
                                }}
                            >
                                {Object.keys(saleShortagesTotalSummary).filter(key => saleShortagesTotalSummary[key] > 0).map((key) => (
                                    <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral.formats[numberFormat].format(saleShortagesTotalSummary[key])}</span>
                                        <strong className="ms-2 fw-bold text-info">{key}</strong>
                                    </div>
                                ))}
                            </td>
                        )
                    }
                    return <div/>
                },
                footer: ({table}) => {
                    const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                    const saleShortagesTotalSummary = rows.reduce((acc, {original}) => {
                        const saleItems = original.order_info?.sale_shortage_items;
                        saleItems?.forEach((saleItem) => {
                            if (acc[saleItem.net_price.currency.name])
                                acc[saleItem.net_price.currency.name] += saleItem.net_price.amount;
                            else
                                acc[saleItem.net_price.currency.name] = saleItem.net_price.amount;
                        })
                        return acc;
                    }, {});

                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "200px",
                                minWidth: "200px"
                            }}
                        >
                            {Object.keys(saleShortagesTotalSummary).filter(key => saleShortagesTotalSummary[key] > 0).map((key) => (
                                <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                    <span>{numeral.formats[numberFormat].format(saleShortagesTotalSummary[key])}</span>
                                    <strong className="ms-2 fw-bold text-info">{key}</strong>
                                </div>
                            ))}
                        </td>
                    )
                }
            },

            {
                id: 'action',
                accessorKey: t(lang, "notification.table.actions"),
                enableSorting: false,
                enableHiding: false,
                header: ({table}) => {
                    return (
                        <th className="p-1 align-middle text-center text-dark fs--1"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >
                            <DataTableColumnSettings table={table}/>
                        </th>
                    )
                },
                cell: ({row: {original}}) => {
                    return (
                        <td className="p-1 align-middle text-center">
                            <ViewSaleModal binding={organizationBinding}
                                           saleId={original.id}
                            />
                        </td>
                    )
                },
                footer: () => {
                    return (
                        <td className="p-1 align-middle text-center text-dark"
                            style={{
                                width: "30px",
                                maxWidth: "30px",
                                minWidth: "30px"
                            }}
                        >

                        </td>
                    )
                },
            }
        ];

        if (isViewSalePaymentsEnabled) {
            dataTableColumns.splice(3, 0,
                {
                    id: 'saleTotalPayment',
                    accessorKey: t(lang, "warehouse-operation.sale.datatable.sale_payment_total_price"),
                    enableSorting: false,
                    enableHiding: true,
                    header: ({column}) => {
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "200px",
                                    minWidth: "200px"
                                }}
                            >
                                {t(lang, 'warehouse-operation.sale.datatable.sale_payment_total_price')}
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment) {
                            const paymentTotalSummary = original.payment?.cash_box_states?.reduce((acc, item) => {
                                if (acc[item.currency.name])
                                    acc[item.currency.name] += item.amount;
                                else
                                    acc[item.currency.name] = item.amount;
                                return acc;
                            }, {});

                            return (
                                <td className="p-1 align-middle text-center text-dark"
                                    style={{
                                        width: "200px",
                                        minWidth: "200px"
                                    }}
                                >
                                    {Object.keys(paymentTotalSummary).map((key) => (
                                        <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral.formats[numberFormat].format(paymentTotalSummary[key])}</span>
                                            <strong className="ms-2 fw-bold text-info">{key}</strong>
                                        </div>
                                    ))}
                                </td>
                            )
                        }

                        return (
                            <td className="p-1 align-middle text-center text-dark"
                                style={{
                                    width: "200px",
                                    minWidth: "200px"
                                }}
                            >

                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.map(({original}) => original).filter((x => x.payment));
                        const paymentsTotalSummary = payments.reduce((acc, item) => {
                            item.payment.cash_box_states.forEach((cashBoxState) => {
                                if (acc[cashBoxState.currency.name])
                                    acc[cashBoxState.currency.name] += cashBoxState.amount;
                                else
                                    acc[cashBoxState.currency.name] = cashBoxState.amount;
                            })
                            return acc;
                        }, {});

                        return (
                            <td className="p-1 align-middle text-center text-dark"
                                style={{
                                    width: "200px",
                                    minWidth: "200px"
                                }}
                            >
                                {Object.keys(paymentsTotalSummary).map((key) => (
                                    <div key={key} className='d-block text-end fs--1 fw-bold text-dark'>
                                        <span>{numeral.formats[numberFormat].format(paymentsTotalSummary[key])}</span>
                                        <strong className="ms-2 fw-bold text-info">{key}</strong>
                                    </div>
                                ))}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeCash',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeCashCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeCashCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeCashCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeCashCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeUzCard',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeUzCardCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeUzCardCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeUzCardCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeUzCardCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeHumo',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeHumoCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeHumoCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeHumoCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeHumoCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeBankTransfer',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeBankTransferCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeBankTransferCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeBankTransferCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeClick',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeClickCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeClickCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeClickCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeClickCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypePayMe',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypePaymeCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypePaymeCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypePaymeCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypePaymeCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
                {
                    id: 'paymentTypeVisa',
                    accessorKey: t(lang, GetPaymentTypeText(PaymentTypeVisaCode)),
                    enableHiding: true,
                    header: ({column}) => {
                        const paymentTypeOption = ContractorPaymentTypes.find((o) => o.value === PaymentTypeVisaCode);
                        return (
                            <th className="p-1 align-middle text-center text-dark fs--1"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                <SoftBadge style={{backgroundColor: paymentTypeOption.bg}}
                                           className="m-1 fs--2 text-dark"
                                >
                                    {t(lang, paymentTypeOption.label)}
                                </SoftBadge>
                            </th>
                        )
                    },
                    cell: ({row: {original}}) => {
                        if (original.payment === null) {
                            return (
                                <td className="p-1 align-middle"
                                    style={{
                                        width: "180px",
                                        minWidth: "180px"
                                    }}
                                >
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>
                                </td>
                            )
                        }

                        const payments = original.payment.cash_box_states.reduce((acc, cashBoxAmount) => {
                            if (acc[cashBoxAmount.payment_type]) {
                                if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                else
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            } else {
                                acc[cashBoxAmount.payment_type] = {};
                                acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                            }
                            return acc
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeVisaCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    },
                    footer: ({table}) => {
                        const rows = table.getSelectedRowModel().rows.length ? table.getSelectedRowModel().rows : table.getRowModel().rows;
                        const payments = rows.reduce((acc, {original: sale}) => {
                            if (sale.payment === null)
                                return acc;

                            sale.payment.cash_box_states.forEach((cashBoxAmount) => {
                                if (acc[cashBoxAmount.payment_type]) {
                                    if (acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name])
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] += cashBoxAmount.amount;
                                    else
                                        acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                } else {
                                    acc[cashBoxAmount.payment_type] = {};
                                    acc[cashBoxAmount.payment_type][cashBoxAmount.currency.name] = cashBoxAmount.amount;
                                }
                            })
                            return acc;
                        }, {});
                        const uzCardPayments = payments[`${PaymentTypeVisaCode}`];
                        return (
                            <td className="p-1 align-middle"
                                style={{
                                    width: "180px",
                                    minWidth: "180px"
                                }}
                            >
                                {uzCardPayments ? Object.keys(uzCardPayments).map((currencyName) => (
                                        <div key={currencyName} className='d-block text-end fs--1 fw-bold text-dark'>
                                            <span>{numeral['formats'][numberFormat].format(uzCardPayments[currencyName])}</span>
                                            <strong className="ms-2 fw-bold text-info">{currencyName}</strong>
                                        </div>
                                    )) :
                                    <div
                                        className="text-end fs--1 text-muted">{t(lang, 'task.board.common.no.data')}</div>}
                            </td>
                        )
                    }
                },
            )
        }

        return dataTableColumns;
    }, [pagination, lang, isViewSalePaymentsEnabled]);

    const data = useMemo(() => {
        return sales;
    }, [sales]);
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        onPaginationChange: onPaginationChange,
        onColumnVisibilityChange: onColumnVisibilityChange,
        autoResetPageIndex: false,
        manualFiltering: true,
        manualSorting: true,
        manualPagination: true,
        enableSorting: true,
        enableSortingRemoval: true,
        pageCount: Math.ceil(count / pagination.pageSize),
        meta: {
            getHeaderRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getHeaderRowClassNames: () => {
                return "bg-200";
            },
            getCellRowStyles: (row) => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            },
            getFooterRowStyles: () => {
                return {
                    borderWidth: "2px 0",
                    borderColor: "lightGrey"
                };
            }
        },
        state: {
            pagination: pagination,
            columnVisibility: columnVisibility
        }
    });

    useEffect(() => {
        load(filters, pagination)
    }, [organizationBinding]);

    return (
        <Card>
            <Card.Header>
                <OwnerOrganizationSalesDataTableHeader table={table}
                                                       loading={loading}
                                                       filters={filters}
                                                       onFilterChange={onFilterChange}
                                                       load={() => load(filters, pagination)}
                />
            </Card.Header>
            <Card.Body>
                <DataTable table={table}
                           loading={loading}
                           columns={columns}
                           tableProps={{
                               responsive: true,
                               bordered: true,
                               style: {
                                   borderWidth: "2px 0",
                                   borderColor: "lightGrey"
                               }
                           }}
                />
            </Card.Body>
            <Card.Footer>
                <DataTablePagination table={table}
                                     pagination={pagination}
                                     count={count}
                />
                <DataTableLimiter table={table}
                                  pagination={pagination}
                                  limitOptions={[10, 25, 50, 75, 100]}
                />
            </Card.Footer>
        </Card>
    );
};

export default OwnerOrganizationSalesDataTable;