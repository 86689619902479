import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";

const telegramOrderBotOrderSlice = createSlice({
    name: 'telegramOrderBotOrder',
    initialState: {
        orders: [],
        count: 0,
        filters: {
            dateStart: null,
            dateEnd: null,
            contractorId: null,
            isApproved: null,
            isSaleCreated: null,
            botId: null,
        },
        pagination: {
            pageIndex: 0,
            pageSize: 10
        },
        columnVisibility: {
            "selectColumn": true,
            "#": true,
            "user": true,
            "contractorName": true,
            "date": true,
            "contractorPhone": true,
            "total": true,
            "approved": true,
            "approved_at": true,
            "sale_info": true,
            "action": true,
        },
    },
    reducers: {
        setOrders: (state, action) => {
            state.orders = action.payload;
        },
        setCount: (state, action) => {
            state.count = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setColumnVisibility: (state, action) => {
            state.columnVisibility = action.payload;
        },
    },
});


export const updateOrders = (orders) => (dispatch) => dispatch(telegramOrderBotOrderSlice.actions.setOrders(orders));
export const updateOrdersCount = (count) => (dispatch) => dispatch(telegramOrderBotOrderSlice.actions.setCount(count));
export const updateFilters = (filters) => (dispatch) => dispatch(telegramOrderBotOrderSlice.actions.setFilters(filters));
export const updatePagination = (pagination) => (dispatch) => dispatch(telegramOrderBotOrderSlice.actions.setPagination(pagination));
export const updateColumnVisibility = (columnVisibility) => (dispatch) => dispatch(telegramOrderBotOrderSlice.actions.setColumnVisibility(columnVisibility));

export const selectTelegramOrderBotOrders = state => state.telegramOrderBotOrder.orders;
export const selectTelegramOrderBotOrdersCount = state => state.telegramOrderBotOrder.count;
export const selectTelegramOrderBotOrderFilters = state => state.telegramOrderBotOrder.filters;
export const selectTelegramOrderBotOrderPagination = state => state.telegramOrderBotOrder.pagination;
export const selectTelegramOrderBotOrderColumnVisibility = state => state.telegramOrderBotOrder.columnVisibility;

export default telegramOrderBotOrderSlice.reducer;