import {
    PermissionPeriodReportView,
    PermissionWMSOperationItemsReportView,
    PermissionWMSPurchaseView,
    PermissionWMSRefundView,
    PermissionWMSReturnView,
    PermissionWMSRevisionView,
    PermissionWMSSaleView,
    PermissionWMSTransferView,
    PermissionWMSWriteOffView,
    WarehouseOperationPermissions
} from "./WarehouseOperationPermission";
import {
    CrmPermissions,
    PermissionCRMContractorLabelView,
    PermissionCRMContractorOperationView,
    PermissionCRMContractorPaymentView,
    PermissionCRMContractorPayoutView,
    PermissionCRMContractorView,
    PermissionCRMTelegramOrderView
} from "./CrmPermission";
import {PermissionItemView, WarehouseItemPermissions} from "./WarehouseItemPermission";
import {
    EDIPermissions,
    PermissionContractFormationView,
    PermissionEdiAttorneyView,
    PermissionEdiInvoiceView,
    PermissionEdiOrderView,
    PermissionEdiReturnInvoiceView,
    PermissionEdiReturnOrderView,
    PermissionEdiReturnShipmentView,
    PermissionEdiShipmentView
} from "./EdiPermission";
import {
    PermissionRoamingActView,
    PermissionRoamingContractView,
    PermissionRoamingEmpowermentView,
    PermissionRoamingInvoiceView,
    PermissionRoamingShipmentView,
    PermissionRoamingVerificationActView,
    RoamingPermissions
} from "./RoamingPermission";
import {AnotherPermissions, PermissionBranchView} from "./RolePermission";
import {
    CashBoxPermissions,
    PermissionCashBoxCashInView,
    PermissionCashBoxCashOutView,
    PermissionCashBoxExpenseView,
    PermissionCashBoxOperationView,
    PermissionCashBoxTransferView,
    PermissionCashBoxView
} from "./CashBoxPermission";

const checkPermission = (permission) => (_, getState) => {

    const accountPermissions = getState().role?.permissions;

    for (let i = 0; i <= accountPermissions?.length; i++) {
        if (accountPermissions[i] === permission) {
            return true
        }
    }
    return false
}
export default checkPermission

export const defaultPermissions = {
    edi: EDIPermissions,
    roaming: RoamingPermissions,
    warehouse_items: WarehouseItemPermissions,
    warehouse_operations: WarehouseOperationPermissions,
    crm: CrmPermissions,
    cash_box: CashBoxPermissions,
    another: AnotherPermissions
};
