import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Col, Collapse, Form, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import {faSignature, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import IconButton from "../../../../common/IconButton";
import {
    changeFilterOptionsAsync,
    selectCountEmpowerments, selectFilterOptions
} from "../../../../../app/store/reducers/roaming/roamingEmpowermentReducer";
import EmpowermentDataTableFilter from "./EmpowermentDataTableFilter";
import {VIEWER_DRAFT} from "../../../../../enum/EmpowermentStatus";
import SoftBadge from "../../../../common/SoftBadge";
import checkPermission from "../../../../../enum/Permission/CheckPermission";
import {PermissionRoamingEmpowermentCreate} from "../../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../../enum/Permission/ToastPermission";
import dayjs from "dayjs";


const EmpowermentDataTableHeader = ({selectedRowIds, onClickBulkSign, page, viewer}) => {
    const dispatch = useDispatch();
    const currentCertificate = useSelector(selectCurrentCertificate);
    const empowermentCount = useSelector(selectCountEmpowerments)
    const filters = useSelector(selectFilterOptions);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            number: filters.number || null,
            customer_inn: filters.customer_inn || null,
            executor_inn: filters.executor_inn || null,
            status: filters.status || null,
            created_at_start: filters.created_at_start ? dayjs(filters?.created_at_start, "YYYY-MM-DD").toDate() : null,
            created_at_end: filters.created_at_end ? dayjs(filters?.created_at_end, "YYYY-MM-DD").toDate() : null,
            contract_number: filters.contract_number || null,
            contract_date: filters.contract_date ? dayjs(filters?.contract_date, "YYYY-MM-DD").toDate() : null,
        },
    });

    useEffect(() => {
        if (viewer === VIEWER_DRAFT) {
            dispatch(changeFilterOptionsAsync({ ...filters, status: null}));
        }
    }, [viewer]);

    const onSubmitFilter = (formData) => {
        dispatch(changeFilterOptionsAsync({
            ...filters,
            page: 1,
            number: formData?.number || null,
            customer_inn: formData.customer_inn || null,
            executor_inn: formData.executor_inn || null,
            status: formData.status || null,
            created_at_start: formData?.created_at_start ? dayjs(formData?.created_at_start).format("YYYY-MM-DD") : null,
            created_at_end: formData?.created_at_end ? dayjs(formData?.created_at_end).format("YYYY-MM-DD") : null,
            contract_number: formData?.contract_number || null,
            contract_date: formData?.contract_date ? dayjs(formData?.contract_date).format("YYYY-MM-DD") : null,
        }));
    }
    const onBulkSign = async () => {
        let selectEmpowerment = Object.keys(selectedRowIds).map((key) => {
            let value = selectedRowIds[key]
            if (value) {
                return page[key].original
            }
            return false
        })
        onClickBulkSign(selectEmpowerment)
        return selectEmpowerment
    }

    return (
        <Row className="flex-between-center">
            <Col sm={12}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                        <EmpowermentDataTableFilter viewer={viewer}/>
                    </Form>
                </FormProvider>
                <hr/>
            </Col>
            <Col xs={4} sm="auto" className="d-flex flex-column pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "edi.attorney.datatable.header.title")}</h5>
                <div className="mt-1">
                    {t(lang,"roaming.common.count")}:
                    <SoftBadge bg='success' className='ms-2'>
                        {empowermentCount}
                    </SoftBadge>
                </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length > 0 ? (
                    <div className="d-flex">
                        <IconButton
                            onClick={onBulkSign}
                            disabled={!currentCertificate}
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faSignature}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.sign")}
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            type="button"
                            icon={faTrash}
                            iconClassName="me-1"
                            className="ms-2"
                        >{t(lang, "edi.attorney.datatable.header.navigation.delete")}
                        </IconButton>
                    </div>
                ) : (
                    <div id="attorney-actions">
                            <IconButton
                                as={dispatch(checkPermission(PermissionRoamingEmpowermentCreate)) ? Link : 'div'}
                                onClick={()=>!dispatch(checkPermission(PermissionRoamingEmpowermentCreate)) && dispatch(toastPermission())}
                                to="../empowerment/send"
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1">
                                    {t(lang, "edi.attorney.datatable.header.navigation.create")}
                                </span>
                            </IconButton>
                        {
                            viewer === VIEWER_DRAFT
                            ?
                            ''
                            :
                            <>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    transform="shrink-3"
                                    icon="refresh"
                                    className="mx-2"
                                    onClick={methods.handleSubmit(onSubmitFilter)}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">
                                        {t(lang, "edi.orders.order.resume_order_button")}
                                    </span>
                                </IconButton>
                            </>
                        }
                    </div>
                )}
            </Col>
        </Row>
    );
};

EmpowermentDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default EmpowermentDataTableHeader;
