import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {dependencies} from "../../../../components/hippo/warehouse-operation/report/ContractorsPeriodReportSummaryDataTable";
export const STORAGE_CONTRACTORS_PERIOD_REPORT = 'contractors_period_report'

export const reportSlice = createSlice({
    name: "report",
    initialState: {
        filter: {
            date_start: dayjs(new Date()).format("YYYY-MM-DD"),
            date_end: dayjs(new Date()).format("YYYY-MM-DD"),
            contractor_id: null,
            employee_id : null,
            operation_account_id: null,
        },
        contractorPeriodSummaryDataTableSettings: {},
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        loadContractorPeriodSummaryDataTableSettings: (state) => {
            const defaultColumns = {
                index: true,
                name: true,
                sales_profit_by_purchase_price: true,
                sales_count: true,
                sales_net_price: true,
                payments_count: true,
                payments_net_price: true,
                payouts_count: true,
                payouts_net_price: true,
                refunds_count: true,
                refunds_net_price: true,
                purchases_count: true,
                purchases_net_price: true,
                returns_count: true,
                returns_net_price: true,
                shortage_count: true,
                shortage_price: true,
                telegram_order_count: true,
                telegram_order_price: true,
                before_debts: true,
                debts_net_price: true,
            }
            let storageColumn = JSON.parse(localStorage.getItem(STORAGE_CONTRACTORS_PERIOD_REPORT))
            if (!storageColumn) {
                storageColumn = defaultColumns
                localStorage.setItem(STORAGE_CONTRACTORS_PERIOD_REPORT, JSON.stringify(storageColumn))
            } else {
                storageColumn = {...defaultColumns, ...storageColumn}
            }
            state.contractorPeriodSummaryDataTableSettings = storageColumn
        },
        changeContractorPeriodSummaryDataTableSettings: (state, action) => {
            const updatedSettings = { ...state.contractorPeriodSummaryDataTableSettings, ...action.payload };
            Object.keys(dependencies).forEach((key) => {
                if (key in action.payload) {
                    const dependentKey = dependencies[key];
                    updatedSettings[dependentKey] = action.payload[key];
                    updatedSettings[key] = action.payload[key];
                }
            });

            state.contractorPeriodSummaryDataTableSettings = updatedSettings;
            localStorage.setItem(STORAGE_CONTRACTORS_PERIOD_REPORT, JSON.stringify(state.contractorPeriodSummaryDataTableSettings))
        }
    },
});
export const selectFilters = state => state.report.filter;
export const selectContractorPeriodSummaryDataTableSettings = state => state.report.contractorPeriodSummaryDataTableSettings;
export const {setFilter, loadContractorPeriodSummaryDataTableSettings, changeContractorPeriodSummaryDataTableSettings} = reportSlice.actions;
export default reportSlice.reducer;

