import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Form, FormControl, Row} from "react-bootstrap";
import {Controller, useFieldArray, useFormContext, useWatch} from "react-hook-form";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import ReactSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../../app/store/reducers/main/mainReducer";
import {
    getTransportsAsync,
    RoamingWaybillV2DeliveryTypeFromWarehouseToWarehouse,
    selectTransports, setTransports
} from "../../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import SelectTransport from "./SelectTransport";
import {Railway} from "../SendWaybillFormV2";
import FindCompanySearchable from "../../../../organization/FindCompanySearchable";



const DetailsFormV2 = ({ediShipment}) => {

    const {register, formState: {errors}, control, watch, setValue} = useFormContext();
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch();

    const {fields} = useFieldArray({name: 'other_car_owners'})

    const delivery_type = useWatch({name: "delivery_type", exact: true})
    const transportType = useWatch({name: "transport_type", exact: true})
    const freight_forwarder = useWatch({name: 'freight_forwarder.inn_or_pinfl', exact: true})
    const carrier = useWatch({name: 'carrier.inn_or_pinfl', exact: true})
    const other_car_owner = useWatch({name: 'other_car_owner', exact: true})

    const setOtherCarOwner = (value, index) => {
        if (!value) {
            dispatch(setTransports([]))
            setValue(`other_car_owners.${index}.inn_or_pinfl`, null)
            setValue(`other_car_owners.${index}.name`, null)
        } else {
            dispatch(getTransportsAsync(value.inn || value.person_num))
            setValue(`other_car_owners.${index}.inn_or_pinfl`, value.inn || value.person_num)
            setValue(`other_car_owners.${index}.name`, value?.name)
        }

    }

    const onChangeOtherCarOwner = (event) => {
        setValue('other_car_owner', event)
        if (!event) {
            dispatch(setTransports([]))
            fields.forEach((field, index) => {
                setValue(`other_car_owners.${index}.inn_or_pinfl`, null)
                setValue(`other_car_owners.${index}.name`, null)
            })
        }

    }

    return (
        <Fragment>
            <Card className={'position-relative'}>
                <Card.Body className={'position-relative'}>
                    <Row>
                        {+delivery_type === 2 && <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.contract_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('contract_info.number', {
                                        required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.contract_info?.number?.message}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.contract_date')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='contract_info.date'
                                    rules={{
                                        required: delivery_type === 2 ? t(lang, "edi.common.forms.validations.is_required") : false,
                                        max: {
                                            value: new Date(),
                                            message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')
                                        }
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.contract_info?.date?.message}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Fragment>}

                        {+transportType === Railway ? <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.train_carriage_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.train_carriage_number:placeholder')}
                                    {...register('railway.train_carriage_number', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.railway?.train_carriage_number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.train_direction')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.train_direction:placeholder')}
                                    {...register('railway.train_direction', {
                                        required: t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.railway?.train_direction?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Fragment> : <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>{t(lang, "Транспорт")}</Form.Label>
                                <Controller
                                    rules={{
                                        required: !ediShipment ? t(lang, "edi.common.forms.validations.is_required") : false
                                    }}
                                    name={'transport.registration_number'}
                                    render={({field}) => (
                                        <SelectTransport
                                            onChange={field.onChange}
                                            defaultTransportNumber={field.value}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.transport?.registration_number?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Fragment>}

                        {ediShipment && (carrier && (freight_forwarder !== carrier)) && <Fragment>
                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.payer_contract_number')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t(lang, 'roaming.waybill.send.details_form.contract_number.placeholder')}
                                    {...register('payer_contract_info.number', {
                                        required:t(lang, "edi.common.forms.validations.is_required")
                                    })}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.payer_contract_info?.number?.message}
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group as={Col} sm={12} md={3}>
                                <Form.Label>
                                    {t(lang, 'roaming.waybill.send.details_form.payer_contract_date')}
                                    <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name='payer_contract_info.date'
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                        max: {
                                            value: new Date(),
                                            message: t(lang, 'roaming.waybill.send.details_form.validations.shipment.date.more_than_current_date')
                                        }
                                    }}
                                    render={({field}) => (
                                        <DatePicker
                                            isClearable
                                            selected={field.value}
                                            dateFormat={dateFormat}
                                            onChange={(date) => field.onChange(date)}
                                            className={'form-control'}
                                            placeholderText={t(lang, 'roaming.waybill.send.details_form.contract_date.placeholder')}
                                        />
                                    )}
                                />
                                <Form.Control.Feedback className={'d-block'} type="invalid">
                                    {errors?.payer_contract_info?.date?.message}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Fragment>}

                        {
                            other_car_owner && fields.map((field, index) => (
                                <Form.Group as={Col} sm={12} md={3} key={field.id}>
                                    <Form.Label>
                                        {t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")}
                                    </Form.Label>
                                    <Controller
                                        name={`other_car_owners.${index}.inn_or_pinfl`}
                                        control={control}
                                        render={({field}) => (
                                            <FindCompanySearchable
                                                onChange={(value) => {
                                                    setOtherCarOwner(value, index)
                                                }}
                                                defaultInn={field.value}
                                                inputProps={{
                                                    placeholder: t(lang, "roaming.contracts.add.clients_info.inn_or_pinfl")
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            ))
                        }
                    </Row>

                    {
                        ediShipment &&
                        <Form.Group className={'position-absolute'} style={{top: '2px', right: '20%'}}>
                            <Form.Label form={'hide-additional-information'}>
                                <Form.Switch
                                    {...register(`is_hide_additional_information`, {
                                        shouldUnregister: true
                                    })}
                                    id={'hide-additional-information'}
                                    label={t(lang, 'roaming.waybill.send.hide.additional-information.title')}
                                />
                            </Form.Label>
                        </Form.Group>
                    }
                    <Form.Group className={'position-absolute'} style={{top: '2px', right: '10px'}}>
                        <Controller
                            control={control}
                            name={'other_car_owner'}
                            render={({field}) => (
                                <Form.Check
                                    id='other_car_owner'
                                    type={'checkbox'}
                                    label={t(lang, 'roaming.waybill.v2.send.other_car_owner')}
                                    {...register('other_car_owner')}
                                    defaultChecked={field.value}
                                    onChange={(e) => {
                                        onChangeOtherCarOwner(e.target.checked)
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default DetailsFormV2;
