import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import RegistrationFromStepOne from "./register/RegistrationFromStepOne";
import RegistrationFromStepTwo from "./register/RegistrationFromStepTwo";
import {useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {
    getRegistrationVerifyConfirmationTicketAsync, registerAsync
} from "../../../app/store/reducers/auth/authReducer";
import IconAlert from "../../common/IconAlert";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

const RegistrationWithoutInnForm = ({hasLabel}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [step, setStep] = useState(1);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState(null);
    const [confirmationTicket, setConfirmationTicket] = useState(null);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);


    const methods = useForm({
        defaultValues: {
            company: {
                name: '',
            },
            account: {
                name: '',
                username: '',
                password: '',
                confirmPassword: '',
                confirmCode: ''
            },
            referralAgentCode: '',
            terms: null
        }
    });

    const onSubmit = (formData) => {

        let username = formData.account?.username?.replace(/ /g, '')
        username = '998' + username;

        switch (step) {
            case 1 :
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setConfirmationTicket(ticket);
                        setStep(step + 1);
                    }, () => {
                        setSomethingWentWrong(true);
                    })
                    break;
            case 2 :
                const data = methods.getValues()
                registerAsync({
                    name: data.company?.name,
                    username: username,
                    password: data.account?.password,
                    fullName: data.account?.name,
                    confirmCode: data.account?.confirmCode,
                    confirmTicket: confirmationTicket,
                    referral_agent_code: data.referralAgentCode || null,
                })
                    .then(() => {
                        setSomethingWentWrong(false)
                    }, ({referralAgentNotFound, referralAgentCodeRequired}) => {
                        if (referralAgentCodeRequired) {
                            toast.error(t(lang, 'authentication.register.agent.code.required.error'))
                        }

                        if (referralAgentNotFound) {
                            toast.error(t(lang, 'authentication.register.agent.code.error'))
                        }

                        setSomethingWentWrong(true);
                    });
                    break;
        }
    }
    return (
        <FormProvider {...methods}>
            {somethingWentWrong && <IconAlert variant="danger" dismissible onClose={() => setSomethingWentWrong(false)}>
                <p className="mb-0">{t(lang, 'authorization.register.something_went_wrong')}</p>
            </IconAlert>}
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                {step === 1 && <RegistrationFromStepOne withoutInn hasLabel={hasLabel}/>}
                {step === 2 && <RegistrationFromStepTwo hasLabel={hasLabel} invalidConfirmCode={invalidConfirmCode}/>}
                <Form.Group className="mb-4">
                    <Button className="w-100" type="submit"> {t(lang, 'authorization.register.button.next')}</Button>
                </Form.Group>
            </Form>
            <p className="fs-0 text-center text-600 my-0">
                {t(lang, 'authorization.register.already_has_account')}? <Link
                to="../login">{t(lang, 'authorization.login.title')}</Link>
            </p>
        </FormProvider>
    );
};

export default RegistrationWithoutInnForm;
