export const PermissionItemView                    = 4000
export const PermissionItemCreate                  = 4001
export const PermissionItemUpdate                  = 4002
export const PermissionItemDelete                  = 4003

export const WarehouseItemPermissions = [
    PermissionItemView,
    PermissionItemCreate,
    PermissionItemUpdate,
    PermissionItemDelete,
]

export const WarehouseItemsDefaultPermissions = [
    {
        text: 'warehouse.dashboard.items.title',
        permissions: [
            {
                text: 'system.permission.items.page.view',
                value: PermissionItemView
            },
            {
                text: 'system.permission.item.create',
                value: PermissionItemCreate
            },
            {
                text: 'system.permission.item.update',
                value: PermissionItemUpdate
            },
            {
                text: 'system.permission.item.delete',
                value: PermissionItemDelete
            },
        ]
    },
]
