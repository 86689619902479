import React, {Fragment, useEffect, useState} from 'react';
import AdvanceTableWrapper from "../../../common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "../../../common/advance-table/AdvanceTable";
import {selectActiveOrganization, selectUsers,} from "../../../../app/store/reducers/auth/authReducer";
import {useDispatch, useSelector} from "react-redux";
import EventBus from "../../../../app/eventbus/EventBus";
import {ADD_USER_ORGANIZATION_SUCCESS} from "../../../../app/eventbus/authEvents";
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import DeleteUser from "./DeleteUser";
import ActionButton from "../../../common/ActionButton";
import BindRoleIntoAccountFormModal from "../role/forms/BindRoleIntoAccountFormModal";
import {loadAccountRolesAsync, selectAccountRoles,} from "../../../../app/store/reducers/role/roleReducer";
import {DELETE_ROLE_SUCCESS, EDIT_ACCOUNT_ROLE_SUCCESS, EDIT_ROLE_SUCCESS} from "../../../../app/eventbus/roleEvents";
import {PermissionSystemRoleBindAccount} from "../../../../enum/Permission/RolePermission";
import useCheckPermission from "../../../../hooks/useCheckPermission";

const UsersDatatable = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const activeOrganization = useSelector(selectActiveOrganization)
    const accountRoles = useSelector(selectAccountRoles)
    const users = useSelector(selectUsers);
    const [role, setRole] = useState(null)
    const [account, setAccount] = useState(null)
    const [showModal, setShowModal] = useState(false);

    // permissions
    const roleBindAccount = useCheckPermission(PermissionSystemRoleBindAccount)

    useEffect(() => {
        dispatch(loadAccountRolesAsync())
    }, [activeOrganization])


    useEffect(() => {
        const onUserAddedHandler = EventBus.on(ADD_USER_ORGANIZATION_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })
        const onUserEditedRoleHandler = EventBus.on(EDIT_ACCOUNT_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })
        const onRoleEditHandler = EventBus.on(EDIT_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })

        const onRoleDeleteHandler = EventBus.on(DELETE_ROLE_SUCCESS, () => {
            dispatch(loadAccountRolesAsync())
        })

        return () => {
            EventBus.remove(ADD_USER_ORGANIZATION_SUCCESS, onUserAddedHandler)
            EventBus.remove(EDIT_ACCOUNT_ROLE_SUCCESS, onUserEditedRoleHandler)
            EventBus.remove(EDIT_ROLE_SUCCESS, onRoleEditHandler)
            EventBus.remove(DELETE_ROLE_SUCCESS, onRoleDeleteHandler)
        }
    }, [])

    const filterRoleByUsername = (id) => {
        return accountRoles.find(acc => {
            return acc?.account_id === id
        })
    }
    const onBindRoleToAccount = (account) => {
        let accountRole = filterRoleByUsername(account.id);
        setAccount(account)
        if (accountRole)
            setRole({
                name: accountRole.role.name,
                permissions: accountRole.role.permissions,
                id: accountRole.role.id,
            })
        setShowModal(true)
    }
    const onHideBindRoleModal = () => {
        setShowModal(false)
        setAccount(null)
        setRole(null)
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            Cell: ({row}) => {
                return row.index + 1;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "users.datatable.full_name"),
            Cell: ({row: {original}}) => {
                return (
                    <strong>
                        {original?.name}
                    </strong>
                )
            },
        },
        {
            accessor: 'username',
            Header: t(lang, "edi.branch.datatable.header.row.code"),
            disableSortBy: true,
            Cell: rowData => {
                const {username} = rowData.row.original
                return (
                    <a href={'tel:' + username}>
                        {username}
                    </a>
                )
            },
            cellProps: {
                className: 'fw-medium'
            }
        },
        {
            accessor: 'role',
            Header: 'Role',
            Cell: rowData => {
                const {id} = rowData.row.original
                let accountRole = filterRoleByUsername(id);
                return (
                    <div>{accountRole?.role?.name}</div>
                )
            }
        },
        {
            accessor: 'none',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {roleBindAccount && <Fragment>
                            <ActionButton icon={'lock'} title="Роль" variant="action" className="p-0 me-2"
                                          onClick={() => onBindRoleToAccount(original)}/>
                            <DeleteUser username={original.username}/>
                        </Fragment>}
                    </>
                );
            }
        }
    ];


    return (
        <>
            <AdvanceTableWrapper
                columns={columns}
                data={users}
                sortable
                pagination
                perPage={1000}
            >
                <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                    }}
                />
            </AdvanceTableWrapper>
            {account &&
                <BindRoleIntoAccountFormModal defaultRole={role} account={account} show={showModal}
                                              onHide={onHideBindRoleModal}/>}
        </>
    )

}

export default UsersDatatable;
