import React, {Fragment, useEffect, useState} from 'react';
import reportService from "../../../../services/report/reportService";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import numeral from "numeral";
import {selectedCurrencyForCashBox, setCurrency} from "../../../../app/store/reducers/cashbox/CashboxReducer";
import {RUB, USD, UZS} from "../../../../enum/CurrencyCode";
import {Link} from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const WarehouseBusinessIndicator = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const currency = useSelector(selectCurrency)
    const numberFormat = useSelector(selectNumberFormat);
    const selectedCurrency = useSelector(selectedCurrencyForCashBox)

    const dispatch = useDispatch()

    const [businessIndicatorData, setBusinessIndicatorData] = useState(null)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!selectedCurrency)
            dispatch(setCurrency(currency.filter(currency => currency.is_active).find(currency => currency.code === UZS || currency.code === USD || currency.code === RUB)))
    }, [currency])

    useEffect(() => {
        loadIndicatorData()
    }, [activeOrganization]);

    const loadIndicatorData = () => {
        setLoading(true)
        reportService.getBusinessIndicatorReport()
            .then((response) => setBusinessIndicatorData(response.data))
            .catch((error) => setBusinessIndicatorData(null))
            .finally(() => setLoading(false))
    }

    const calculateTotal = (data, nested = false) => {
        if (!data) return 0;
        return data.reduce((acc, item) => {
            if (nested) {
                item.amounts.forEach((x) => {
                    acc += (x.amount * (currency.find(f => f.id === x.currency.id)?.rate / selectedCurrency?.rate) || 1);
                });
            } else {
                acc += (item.amount * (currency.find(f => f.id === item.currency.id)?.rate / selectedCurrency?.rate) || 1);
            }
            return acc;
        }, 0);
    };

    const handleLinkClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const totalCashBoxAmountsNetPrice = businessIndicatorData?.cash_box_amounts_net_price.reduce((acc, item) => {
        item.amounts.forEach(({amount, currency}) => {
            const {id, name} = currency;

            if (!acc[id]) {
                acc[id] = {currency_name: name, total_amount: 0};
            }

            acc[id].total_amount += amount;
        });

        return acc;
    }, {});

    const totalItemsPurchaseNetPriceWithCurrency = calculateTotal(businessIndicatorData?.items_purchase_net_price);
    const totalCashBoxAmountsNetPriceWithCurrency = calculateTotal(businessIndicatorData?.cash_box_amounts_net_price, true);
    const totalContractorsDebtNetPriceWithCurrency = calculateTotal(businessIndicatorData?.contractors_debt_net_price);
    const totalDeptToContractorsNetPrice = calculateTotal(businessIndicatorData?.debt_to_contractors_net_price);


    const chartData = [
        {
            label: 'warehouse.operation.business_indicator.items_purchase_net_price',
            value: totalItemsPurchaseNetPriceWithCurrency,
            color: '#4CAF50',
        },
        {
            label: 'warehouse.operation.business_indicator.contractors_debt_net_price',
            value: totalContractorsDebtNetPriceWithCurrency,
            color: '#2196F3',
        },
        {
            label: 'warehouse.operation.business_indicator.debt_to_contractors_net_price',
            value: totalDeptToContractorsNetPrice,
            color: '#7702f4',
        },
        {
            label: 'warehouse.operation.business_indicator.cash_box_amounts_net_price',
            value: totalCashBoxAmountsNetPriceWithCurrency,
            color: '#FF5722',
        },
    ];

    const totalAmounts = totalItemsPurchaseNetPriceWithCurrency + totalContractorsDebtNetPriceWithCurrency + totalCashBoxAmountsNetPriceWithCurrency;

    if (loading) return <h3 className="text-center">{t(lang, "warehouse.operation.period_report_loading")}</h3>

    return (
        <Fragment>
            <Card>
                <Card.Header className={'border border-bottom-2 d-flex justify-content-between align-items-center'}>
                    <h3 className={'m-0'}>{t(lang, 'warehouse.operation.business_indicator')}</h3>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant={'link'}
                            className="p-0 pb-2 "
                        >
                            <strong className={'text-info ms-2 fs-3'}>{selectedCurrency?.name}</strong>
                        </Dropdown.Toggle>
                        {currency.filter(i => i.is_active).length > 1 && <Dropdown.Menu
                            style={{minWidth: '5rem'}}
                            className="py-1">
                            {currency
                                .filter(i => i.is_active)
                                .filter(i => i.id !== selectedCurrency?.id)
                                .map(i => (
                                    <Dropdown.Item key={i.id}
                                                   onClick={() => dispatch(setCurrency(i))}
                                                   as="div"
                                                   className="px-3 py-2 cursor-pointer">
                                        <strong className={'fs-0'}>{i.name}</strong>
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>}
                    </Dropdown>
                </Card.Header>
            </Card>
            <Row className={'mt-3'}>
                <Col md={6} sm={12}>
                    <Row style={{rowGap: '10px'}}>
                        <Col md={12}>
                            <Card className={'position-relative'}>
                                <PositionCardColor color={'#4CAF50'}/>
                                <Card.Body className={'d-flex justify-content-between align-items-center'}>
                                    <Link to={'/warehouse/items'} onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('/#/warehouse/items');
                                    }}>
                                        <h4 className={'m-0 stretched-link'}>{t(lang, 'warehouse.operation.business_indicator.items_purchase_net_price')}</h4>
                                    </Link>
                                    <div className={'text-end'}>
                                        {
                                            businessIndicatorData?.items_purchase_net_price?.filter(x => x.amount > 0).map(item => (
                                                <h5 className={'m-0 text-black d-flex gap-2 justify-content-end align-items-end'} key={item.currency.name}>
                                                    {numeral.formats[numberFormat].format(item.amount)}
                                                    <span className={'text-info fs--1'}>{item.currency.name}</span>
                                                </h5>
                                            ))
                                        }
                                        <hr className={'my-1'}/>
                                        <h4 className={'m-0 d-flex gap-2 m-0 text-black d-flex gap-2 justify-content-end'}>
                                            {numeral.formats[numberFormat].format(totalItemsPurchaseNetPriceWithCurrency)}
                                            <span
                                                className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                        </h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card>
                                <PositionCardColor color={'#FF5722'}/>
                                <Card.Body className={'d-flex justify-content-between align-items-center'}>
                                    <Link to={'/cash-box/cash-boxes'} onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('/#/cash-box/cash-boxes');
                                    }}>
                                        <h4 className={'m-0'}>{t(lang, 'warehouse.operation.business_indicator.cash_box_amounts_net_price')}</h4>
                                    </Link>
                                    <div className={'text-end'}>
                                        {
                                            !!totalCashBoxAmountsNetPrice && Object.values(totalCashBoxAmountsNetPrice).map(({
                                                                                                                                 currency_name,
                                                                                                                                 total_amount
                                                                                                                             }) => (
                                                <h5 className={'m-0 text-black d-flex gap-2 justify-content-end align-items-end'} key={currency_name}>
                                                    {numeral.formats[numberFormat].format(total_amount)}
                                                    <span className={'text-info fs--1'}>{currency_name}</span>
                                                </h5>
                                            ))
                                        }
                                        <hr className={'my-1'}/>
                                        <h4 className={'m-0 d-flex gap-2 m-0 text-black d-flex gap-2 justify-content-end'}>
                                            {numeral.formats[numberFormat].format(totalCashBoxAmountsNetPriceWithCurrency)}
                                            <span
                                                className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                        </h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className={'position-relative'}>
                                <PositionCardColor color={'#2196F3'}/>
                                <Card.Body className={'d-flex justify-content-between align-items-center'}>
                                    <Link to={'/crm/contractor'} onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('/#/crm/contractor');
                                    }}>
                                        <h4 className={'m-0 stretched-link'}>{t(lang, 'warehouse.operation.business_indicator.contractors_debt_net_price')}</h4>
                                    </Link>
                                    <div className={'text-end'}>
                                        {
                                            businessIndicatorData?.contractors_debt_net_price?.filter(x => x.amount > 0).map(item => (
                                                <h5 className={'m-0 text-black d-flex gap-2 justify-content-end align-items-end'} key={item.currency.name}>
                                                    {numeral.formats[numberFormat].format(item.amount)}
                                                    <span className={'text-info fs--1'}>{item.currency.name}</span>
                                                </h5>
                                            ))
                                        }
                                        <hr className={'my-1'}/>
                                        <h4 className={'m-0 d-flex gap-2 m-0 text-black d-flex gap-2 justify-content-end'}>
                                            {numeral.formats[numberFormat].format(totalContractorsDebtNetPriceWithCurrency)}
                                            <span
                                                className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                        </h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className={'bg-soft-success border border-success'}>
                                <Card.Body className={'d-flex justify-content-between align-items-center'}>
                                    <div className={'w-50'}>
                                        <h4 className={'m-0'}>{t(lang, 'warehouse.operation.item.common.total_sum')}</h4>
                                        <p className={'fs--2 text-wrap'}>
                                            {t(lang, 'warehouse.operation.business_indicator.common.total.info',{
                                                item: t(lang, 'warehouse.operation.business_indicator.items_purchase_net_price'),
                                                cash_box: t(lang, 'warehouse.operation.business_indicator.cash_box_amounts_net_price'),
                                                debt: t(lang, 'warehouse.operation.business_indicator.contractors_debt_net_price')
                                            })}
                                        </p>
                                    </div>
                                    <h4 className={'m-0 d-flex gap-2 m-0 text-black d-flex gap-2 justify-content-end'}>
                                        {numeral.formats[numberFormat].format(totalAmounts)}
                                        <span
                                            className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className={'position-relative bg-soft-danger border border-danger'}>
                                <PositionCardColor color={'#7702f4'}/>
                                <Card.Body className={'d-flex justify-content-between align-items-center'}>
                                    <Link to={'/crm/contractor'} onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('/#/crm/contractor');
                                    }}>
                                        <h4 className={'m-0'}>{t(lang, 'warehouse.operation.business_indicator.debt_to_contractors_net_price')}</h4>
                                    </Link>
                                    <div className={'text-end'}>
                                        {
                                            businessIndicatorData?.debt_to_contractors_net_price?.filter(x => x.amount > 0).map(item => (
                                                <h5 className={'m-0 text-black d-flex gap-2 justify-content-end align-items-end'} key={item.currency.name}>
                                                    {numeral.formats[numberFormat].format(item.amount)}
                                                    <span className={'text-info fs--1'}>{item.currency.name}</span>
                                                </h5>
                                            ))
                                        }
                                        <hr className={'my-1 bg-danger'}/>
                                        <h4 className={'m-0 d-flex gap-2 m-0 text-black d-flex gap-2 justify-content-end'}>
                                            {numeral.formats[numberFormat].format(totalDeptToContractorsNetPrice)}
                                            <span
                                                className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                        </h4>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card className={'bg-soft-info border border-info'}>
                                <Card.Body>
                                    <h4 className={'m-0 d-flex justify-content-between fst-italic'}>
                                        {t(lang, 'warehouse.operation.item.common.total_sum')}:
                                        <span className={'m-0 d-flex gap-2 justify-content-end align-items-center position-relative fst-normal text-dark'}>
                                           {numeral.formats[numberFormat].format(totalAmounts)}
                                            <span className={'text-info fs--1 fw-bold'}>
                                                {selectedCurrency?.name || "UZS"}
                                            </span>
                                            <span className={'position-absolute bottom-0 d-block bg-dark'} style={{left: '-30px', width: '20px', height: '3px'}} />
                                       </span>
                                    </h4>
                                    <h4 className={'m-0 d-flex justify-content-between fst-italic'}>
                                    {t(lang, 'warehouse.operation.business_indicator.debt_to_contractors_net_price')}:
                                        <span className={'m-0 d-flex gap-2 justify-content-end align-items-center fst-normal text-dark'}>
                                           {numeral.formats[numberFormat].format(totalDeptToContractorsNetPrice)}
                                            <span
                                                className={'text-info fs--1 fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                       </span>
                                    </h4>
                                    <h4 className={'m-0 d-flex justify-content-between fst-italic'}>
                                        {t(lang, 'warehouse.operation.item.common.total_summa')}:
                                        <span className={'d-flex gap-2 fst-normal text-dark'} style={{borderTop: '2px solid #27bcfd'}}>
                                            {numeral.formats[numberFormat].format(totalAmounts - totalDeptToContractorsNetPrice)}
                                            <span
                                                className={'text-primary fw-bold'}>{selectedCurrency?.name || "UZS"}</span>
                                        </span>
                                    </h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} sm={12}>
                    <Card className={'h-100 position-relative d-none d-md-block'}>
                        <div className={'w-100 position-absolute top-50 start-50 translate-middle'}>
                            <MostChart data={chartData} currency={selectedCurrency?.name || "UZS"}/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

const MostChart = ({data, currency}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);

    const filteredData = data.filter(item => item.value !== 0);

    const totalConvertedAmount = filteredData.reduce(
        (sum, item) => sum + Math.abs(item.value),
        0
    );

    const chartData = {
        labels: filteredData.map(
            item =>
                `${numeral.formats[numberFormat].format(item.value)} ${currency} (${(
                    (Math.abs(item.value) / totalConvertedAmount) *
                    100
                ).toFixed(2)}%)`
        ),
        datasets: [
            {
                data: filteredData.map(item => Math.abs(item.value)),
                backgroundColor: filteredData.map(item => item.color || '#000'),
                borderColor: '#fff',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        weight: 'bold',
                        size: 14,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const dataIndex = tooltipItem.dataIndex;
                        const originalLabel = filteredData[dataIndex].label;
                        const originalValue = filteredData[dataIndex].value;
                        return `${t(lang, originalLabel)}: ${(originalValue)?.toFixed(2)}`;
                    },
                },
            },
            datalabels: {
                display: false,
                color: '#fff',
                font: {
                    weight: 'bold',
                    size: 18,
                },
                align: 'center',
                formatter: (value) => {
                    return `${((value / totalConvertedAmount) * 100).toFixed(2)}%`;
                },
            },
        },
        cutout: '55%',
        animation: {
            animateScale: true,
        },
    };

    return (
        <div
            style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {!!filteredData.length ? (
                <Doughnut data={chartData} options={options}/>
            ) : (
                <h3>{t(lang, 'task.board.common.no.data')}</h3>
            )}
        </div>
    );
};

const PositionCardColor = ({color}) => {

    return <div className={'position-absolute top-0'}
                style={{width: '30px', height: '20px', backgroundColor: color, left: '20px'}}/>
}

export default WarehouseBusinessIndicator;