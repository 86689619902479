import React from 'react'
import {useSelector} from 'react-redux';
import DatePicker from "react-datepicker";
import SelectStatus from "../../../SelectStatus";
import {Button, Col, Form, Row} from 'react-bootstrap'
import {Controller, useFormContext} from "react-hook-form";
import FindCompanySearchable from "../../../organization/FindCompanySearchable";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {EmpowermentWrapper, VIEWER_CUSTOMER, VIEWER_DRAFT, VIEWER_EXECUTOR, VIEWER_PERSON} from "../../../../../enum/EmpowermentStatus";

const EmpowermentDataTableFilter = ({viewer}) => {
    const dateFormat = useSelector(selectDateFormat);
    const lang = useSelector(selectLang);
    const {register} = useFormContext();
    const t = Translate;

    const showExecutorFilter = viewer === VIEWER_CUSTOMER || viewer === VIEWER_PERSON || viewer === VIEWER_DRAFT;
    const showCustomerFilter = viewer === VIEWER_EXECUTOR || viewer === VIEWER_PERSON;
    const showStatusFilter = viewer === VIEWER_CUSTOMER || viewer === VIEWER_EXECUTOR || viewer === VIEWER_PERSON;

    const empowermentWrapper = new EmpowermentWrapper();

    return (
        <Row className="mb-3 g-3">
            <Col xs={12}>
                <h4 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.empowerment.datatable.filter.title")}</h4>
            </Col>

            <Form.Group as={Col} md={6} lg={3}>
                <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.number")}</Form.Label>
                <Form.Control
                    type={'text'}
                    name={'number'}
                    {...register("number")}
                    placeholder={t(lang, "roaming.empowerment.datatable.filter.number.placeholder")}
                />
            </Form.Group>

            {showCustomerFilter &&
                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.customer")}</Form.Label>
                    <Controller
                        name={'customer_inn'}
                        render={({field}) => (
                            <FindCompanySearchable
                                inputProps={{
                                    placeholder: t(lang, "roaming.empowerment.datatable.filter.customer.placeholder"),
                                }}
                                onChange={customer => field.onChange(customer ? customer.person_num || customer.inn : null)}
                            />
                        )}
                        shouldUnregister
                    />
                </Form.Group>
            }

            {showExecutorFilter &&
                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.executor")}</Form.Label>
                    <Controller
                        shouldUnregister
                        name={'executor_inn'}
                        render={({field}) => (
                            <FindCompanySearchable
                                inputProps={{
                                    placeholder: t(lang, "roaming.empowerment.datatable.filter.executor.placeholder")
                                }}
                                onChange={executor => field.onChange(executor ? executor.person_num || executor.inn : null)}
                            />
                        )}
                    />
                </Form.Group>
            }

            {showStatusFilter && (
                <Form.Group as={Col} md={6} lg={3}>
                    <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.status")}</Form.Label>
                    <Controller
                        name={'status'}
                        render={({field}) => (
                            <SelectStatus
                                isClearable={true}
                                statuses={empowermentWrapper.GetStatuses(viewer)}
                                onChange={statusId => field.onChange(statusId || null)}
                                placeholder={t(lang, "roaming.empowerment.datatable.filter.status.placeholder")}
                            />
                        )}
                    />
                </Form.Group>
            )}

            <Form.Group as={Col} md={6} lg={3}>
                <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.created_at_start")}</Form.Label>
                <Controller
                    name="created_at_start"
                    render={({field}) => (
                        <DatePicker
                            isClearable={true}
                            selected={field.value}
                            dateFormat={dateFormat}
                            onChange={field.onChange}
                            className={'form-control'}
                            defaultValue={field.value}
                            placeholderText={t(lang, "roaming.empowerment.datatable.filter.created_at_start.placeholder")}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group as={Col} md={6} lg={3}>
                <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.created_at_end")}</Form.Label>
                <Controller
                    name="created_at_end"
                    render={({field}) => (
                        <DatePicker
                            isClearable={true}
                            selected={field.value}
                            dateFormat={dateFormat}
                            onChange={field.onChange}
                            defaultValue={field.value}
                            className={'form-control'}
                            placeholderText={t(lang, "roaming.empowerment.datatable.filter.created_at_end.placeholder")}
                        />
                    )}
                />
            </Form.Group>

            <Form.Group as={Col} md={6} lg={3}>
                <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.contract_number")}</Form.Label>
                <Form.Control
                    type={'text'}
                    name={'contract_number'}
                    {...register("contract_number")}
                    placeholder={t(lang, "roaming.empowerment.datatable.filter.contract_number.placeholder")}
                />
            </Form.Group>

            <Form.Group as={Col} md={6} lg={3}>
                <Form.Label>{t(lang, "roaming.empowerment.datatable.filter.contract_date")}</Form.Label>
                <Controller
                    name="contract_date"
                    render={({field}) => (
                        <DatePicker
                            isClearable={true}
                            selected={field.value}
                            dateFormat={dateFormat}
                            onChange={field.onChange}
                            className={'form-control'}
                            placeholderText={t(lang, "roaming.empowerment.datatable.filter.contract_date.placeholder")}
                        />
                    )}
                />
            </Form.Group>

            <Col xs={12} className="text-end">
                <Button variant="primary" type='submit'>{t(lang, "roaming.empowerment.datatable.filter.submit")}</Button>
            </Col>
        </Row>
    )
}

export default EmpowermentDataTableFilter;