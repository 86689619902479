import React, {cloneElement, useState} from 'react';
import {selectLang, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import ContractorPeriodReportSummary from "./ContractorPeriodReportSummary";
import {selectFilters} from "../../../../app/store/reducers/report/reportReducer";

const ContractorPeriodReportModal = ({contractorReport, showShortageColumn, children}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const filters = useSelector(selectFilters);

    return (
        <React.Fragment>
            {
                children ? cloneElement(children, {...children.props, onClick: () => setShow(true)}) :
                <div onClick={handleShow}
                     className="fs--1 fw-semi-bold font-sans-serif text-black text-decoration-underline cursor-pointer text-800"
                >
                    {contractorReport.contractor.name}
                </div>
            }


            {show &&
                <Modal show={true}
                       fullscreen={true}
                       centered={true}
                       onHide={handleClose}
                >
                <Modal.Header>
                        <Modal.Title>
                             {!showShortageColumn && t(lang, "warehouse.operation.contractor_report", {name: contractorReport.contractor.name})}
                             {showShortageColumn && t(lang, "warehouse.operation.shortage_report",
                                 {
                                        name: contractorReport.contractor.name,
                                        date_start: filters.date_start,
                                        date_end: filters.date_end,
                                 }
                             )}
                        </Modal.Title>
                        <FalconCloseButton onClick={handleClose}/>
                    </Modal.Header>
                    <Modal.Body>
                        <ContractorPeriodReportSummary report={contractorReport.contractor_period_report} showShortageColumn={showShortageColumn}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="lg"
                                variant="secondary"
                                onClick={handleClose}
                        >
                            {t(lang, "main.common.back")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default ContractorPeriodReportModal;