import React, {useEffect, useState, Fragment} from "react"
import {Badge, Card, Spinner} from "react-bootstrap";
import ContractorPaymentDataTableHeader from "./ContractorPaymentDataTableHeader";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import {useDispatch, useSelector} from "react-redux";
import {
    loadContractorPaymentAsync,
    selectContractorPayments,
    changeFilterOptionsAsync,
    selectFilterOptionsContractorPayments,
    selectCountContractorPayments,
    loadContractorPaymentCountAsync,
    selectLoading
} from "../../../../../app/store/reducers/contractor/contractorPaymentReducer";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import numeral from "numeral";
import {Link, useHistory} from "react-router-dom";
import SoftBadge from "../../../../common/SoftBadge";
import IconButton from "../../../../common/IconButton";
import DeleteContractorPaymentModal from "./DeleteContractorPaymentModal";
import EditContractorPayment from "./EditContractorPayment";
import {
    ContractorSourceReturn,
    ContractorSourceSale,
    FindContractorPaymentSource
} from "../../../../../enum/ContractorPaymentSources";
import useCheckPermission from "../../../../../hooks/useCheckPermission";
import {
    PermissionCRMContractorPaymentDelete,
    PermissionCRMContractorPaymentUpdate
} from "../../../../../enum/Permission/CrmPermission";

const ContractorPaymentDataTable = () => {
    const dispatch = useDispatch();
    const routerHistory = useHistory();
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization)
    const isLoading = useSelector(selectLoading);
    const contractorPayments = useSelector(selectContractorPayments);
    const count = useSelector(selectCountContractorPayments)
    const filters = useSelector(selectFilterOptionsContractorPayments);
    const numberFormat = useSelector(selectNumberFormat);
    const [editingContractorPayment, setEditingContractorPayment] = useState(null);
    const [deletingContractorPaymentId, setDeletingContractorPaymentId] = useState(null);
    const [deletingContractorSalePaymentId, setDeletingContractorSalePaymentId] = useState(null);

    // permissions
    const isPaymentEdit = useCheckPermission(PermissionCRMContractorPaymentUpdate);
    const isPaymentDelete = useCheckPermission(PermissionCRMContractorPaymentDelete);

    const separationCurrenciesAndTotalSum = (states) => {
        var groupByCurrencyId = {}

        states.forEach(state => {
            if (state?.currency?.id in groupByCurrencyId) {
                groupByCurrencyId[state?.currency?.id].totalSum += state?.amount
            } else {
                if (state?.currency?.id) {
                    groupByCurrencyId[state?.currency?.id] = {
                        id: state?.currency?.id,
                        name: state?.currency?.name,
                        totalSum: state?.amount
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    };

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`contractor-card/${original.contractor.id}`}>
                        <strong>{original.contractor.name}</strong>
                    </Link>
                )
            }
        },
        {
            accessor: 'date',
            Header: t(lang, "crm.contractor_payment.datatable.header.date"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'cash_box_states',
            Header: t(lang, "edi.contractor_payment.datatable.payment_amount"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            totalInfo: separationCurrenciesAndTotalSum(contractorPayments.flatMap(contractorPayment => contractorPayment.cash_box_states)).map((payment, index) => (
                <div className={'fs-0'} key={index}>
                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                        {numeral.formats[numberFormat].format(payment.totalSum)}
                        &nbsp;
                        {payment.name}
                    </SoftBadge>
                </div>
            )),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {separationCurrenciesAndTotalSum(original.cash_box_states).map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral.formats[numberFormat].format(payment.totalSum)}
                                        &nbsp;
                                        {payment.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'debt_states',
            Header: t(lang, "warehouse.operation.item.common.credit"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            totalInfo: separationCurrenciesAndTotalSum(contractorPayments.flatMap(contractorPayment => contractorPayment.debt_states)).map((payment, index) => (
                <div className={'fs-0'} key={index}>
                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                        {numeral.formats[numberFormat].format(payment.totalSum)}
                        &nbsp;
                        {payment.name}
                    </SoftBadge>
                </div>
            )),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.debt_states.map((payment, index) => {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill me-1'} bg={"primary"}>
                                        {numeral.formats[numberFormat].format(payment.amount)}
                                        &nbsp;
                                        {payment.currency.name}
                                    </SoftBadge>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'notes',
            Header: t(lang, "edi.contractor_payment.datatable.note"),
            headerProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            cellProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            }
        },
        {
            accessor: 'account',
            Header: 'Пользователь',
            headerProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            cellProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div>
                        <span>{original.account.name || original.account.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'source',
            id: "source",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: t(lang, "crm.data.table.source"),
            Cell: ({row: {original}}) => {
                if (original.payment_source === ContractorSourceSale) {
                    return (
                        <Link to={`/warehouse/operation/sales/view/${original.sale.id}`}>
                            <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                                   className='me-2'>{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                        </Link>
                    )
                } else if (original.payment_source === ContractorSourceReturn) {
                    return (
                        <Link to={`/warehouse/operation/return-purchases/view/${original.return.id}`}>
                            <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                                   className='me-2'>{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                        </Link>
                    )
                } else {
                    return (
                        <Link to={`/crm/contractor-payment/view/${original.id}`}>
                            <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                                   className='me-2'>{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                        </Link>
                    )
                }
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.contracts.datatable.header.row.created_at"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        {isPaymentEdit && <IconButton
                            className="mx-2 py-0"
                            onClick={() => {
                                if (row.original.payment_source === ContractorSourceSale) {
                                    routerHistory.push(`/warehouse/operation/sales/update/${row.original.sale.id}?payment_id=${row.original.id}`)
                                } else if (row.original.payment_source === ContractorSourceReturn) {
                                    routerHistory.push(`/warehouse/operation/return-purchases/update/${row.original.return.id}?payout_id=${row.original.id}`)
                                } else {
                                    setEditingContractorPayment(row.original)
                                }
                            }}
                            variant="falcon-warning"
                            size="sm"
                            icon="edit"
                            transform="shrink-3"
                        />}
                        {isPaymentDelete && <IconButton
                            className={'py-0'}
                            onClick={() => {
                                if (row.original.payment_source === ContractorSourceSale) {
                                    setDeletingContractorSalePaymentId(row.original.sale.id);
                                } else if (row.original.payment_source === ContractorSourceReturn) {
                                    routerHistory.push(`/warehouse/operation/return-purchases/update/${row.original.return.id}?payout_id=${row.original.id}`)
                                } else {
                                    setDeletingContractorPaymentId(row.original.id);
                                }
                            }}
                            variant="falcon-danger"
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                        />}

                    </>
                );
            }
        }
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }

    useEffect(() => {
        dispatch(loadContractorPaymentAsync({...filters}))
        dispatch(loadContractorPaymentCountAsync({...filters}))
    }, [filters, activeOrganization])

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={contractorPayments}
                pagination
                perPage={1000}
            >
                <Card className="mb-3">
                    <Card.Header>
                        <ContractorPaymentDataTableHeader table/>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            isLoading={isLoading}
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                    <Card.Footer>
                        <AdvanceTableCustomPagination
                            count={count}
                            limit={filters.limit}
                            page={filters.page}
                            onLimitChange={onLimitChange}
                            onPageChange={onPageChange}
                        />
                    </Card.Footer>
                </Card>

            </AdvanceTableWrapper>

            {(deletingContractorPaymentId || deletingContractorSalePaymentId) &&
                <DeleteContractorPaymentModal
                    show={!!deletingContractorPaymentId}
                    id={deletingContractorPaymentId}
                    saleId={deletingContractorSalePaymentId}
                    onClose={() => {
                        setDeletingContractorPaymentId(null)
                        setDeletingContractorSalePaymentId(null)
                    }}/>
            }

            {editingContractorPayment &&
                <EditContractorPayment payment={editingContractorPayment} onClose={() => {
                    setEditingContractorPayment(null)
                }}/>
            }
        </Fragment>
    )
}
export default ContractorPaymentDataTable
