import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Col, Nav, Row, Spinner} from 'react-bootstrap';
import IconButton from "../../../common/IconButton";
import {Link} from "react-router-dom";
import {selectLang, Translate} from '../../../../app/store/reducers/main/mainReducer';
import {useDispatch, useSelector} from 'react-redux';
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {PermissionRoamingVerificationActCreate} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import Divider from "../../../common/Divider";
import {faRefresh} from "@fortawesome/free-solid-svg-icons";
import VerificationActDataTableFilter from "./VerificationActDataTableFilter";
import {VIEW_DRAFT} from "../act/View/TypeView";
import {GetStatusText, GetStatusTexts, GetStatusVariant} from "../../../../enum/VerificationActStatus";

const VerificationActDataTableHeader = ({
                                            table,
                                            viewer,
                                            activeCertificate,
                                            filter,
                                            onFilterChange,
                                            onDebouncedFilterChange,
                                            onReloadData,
                                            loading
                                        }) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const count = table.getRowCount();
    const statuses = GetStatusTexts(viewer);
    const [activeKey, setActiveKey] = useState(filter.status || 0);
    const rows = table.getRowModel().rows.map(({original}) => original);
    const pageSelectedItems = table.getSelectedRowModel().rows.map(({original}) => original);
    const canFilterStatus = viewer !== VIEW_DRAFT;

    const onChangeStatus = (status) => {
        setActiveKey(status.id);
        onFilterChange({...filter, status: status.id === 0 ? null : status.id});
    };

    return (
        <Row className="gy-3">
            <Col xs={12}>
                <VerificationActDataTableFilter filters={filter}
                                                loading={loading}
                                                onFilterChange={onFilterChange}
                                                onDebouncedFilterChange={onDebouncedFilterChange}
                                                viewer={viewer}
                />
            </Col>
            <Divider/>
            <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className="d-flex flex-column">
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "roaming.verification_act.datatable.title")}</h5>
                        <small className="mb-1">{t(lang, 'roaming.invoices.datatable.invoices_count')}: <span
                            className="fw-semi-bold text-black mx-1 fs--1">{count}</span></small>
                    </div>
                    {pageSelectedItems.length === 0 &&
                        <div className="d-flex gap-2">
                            <div>
                                <IconButton size="sm"
                                            icon={faRefresh}
                                            variant="falcon-warning"
                                            onClick={onReloadData}
                                            disabled={loading}
                                >
                                    {t(lang, 'crm.telegram_orders.datatable.reload_telegram_orders')}
                                    {loading && <Spinner className='align-middle ms-2' animation='border' role='switch'
                                                         size='sm'/>}
                                </IconButton>
                            </div>
                            <div>
                                <IconButton size="sm"
                                            icon="plus"
                                            variant="falcon-default"
                                            transform="shrink-3"
                                            to="/roaming/verification-act/send"
                                            as={dispatch(checkPermission(PermissionRoamingVerificationActCreate)) ? Link : 'div'}
                                            onClick={() => !dispatch(checkPermission(PermissionRoamingVerificationActCreate)) && dispatch(toastPermission())}
                                >
                                    {t(lang, "roaming.verification_act.datatable.register_verification_act")}
                                </IconButton>
                            </div>
                        </div>
                    }
                </div>
            </Col>
            {/*<Col xs={12}>*/}
            {/*    <div className="d-flex align-items-center justify-content-start">*/}
            {/*        <h6 className={'mb-0'}>{t(lang, "roaming.invoices.datatable.invoices_total_sum")}: </h6>*/}
            {/*        <OverlayTrigger*/}
            {/*            overlay={<Tooltip>{t(lang, "roaming.invoices.datatable.invoices_total_sum")}</Tooltip>}*/}
            {/*            placement={'top'}>*/}
            {/*            <Alert className={'d-inline-block px-2 py-0 mb-0 ms-1 fs--1'} variant="dark">*/}
            {/*                <strong>{total}</strong>*/}
            {/*            </Alert>*/}
            {/*        </OverlayTrigger>*/}
            {/*    </div>*/}
            {/*</Col>*/}
            {canFilterStatus &&
                <Col xs={12} className="my-4">
                    <Nav justify
                         variant="tabs"
                         activeKey={activeKey}
                    >
                        {statuses.map((status) => (
                            <Nav.Item key={status.id}
                                      onClick={() => onChangeStatus(status)}
                            >
                                <Nav.Link eventKey={status.id}>
                                    <span
                                        className={`text-${GetStatusVariant(viewer, status.id)}`}>{t(lang, GetStatusText(viewer, status.id))}</span>
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </Col>
            }
        </Row>
    )
};

VerificationActDataTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default VerificationActDataTableHeader;
